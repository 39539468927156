import * as React from "react";
import "../Main.css";
const Eye = (props) => {

    const { active, issue, onClick, disabled, top, right, scale } = props;

    return (
        disabled
            ?
            <div
                className={`mainCon`}
                style={{
                    right: right,
                    top: top,
                    transform: `scale(${scale ?? 1})`
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    {...props}
                >
                    <circle
                        cx={12} cy={12} r={12}
                        fill="#B2B7BE"
                        opacity="0.16" />
                    <path
                        fill="#B2B7BE"
                        fillRule="evenodd"
                        d="M6.23 11.396a8.468 8.468 0 0 1 2.797-2.074A3.986 3.986 0 0 0 8 12c0 1.03.389 1.969 1.028 2.678a8.47 8.47 0 0 1-2.797-2.074.903.903 0 0 1 0-1.208Zm-.5-.441C6.868 9.669 9.027 8.03 11.94 8H12.021c2.955 0 5.144 1.657 6.291 2.955a1.57 1.57 0 0 1 0 2.09C17.166 14.343 14.977 16 12.022 16H11.939c-2.913-.03-5.072-1.669-6.208-2.955a1.57 1.57 0 0 1 0-2.09Zm6.278-2.288h-.06a3.333 3.333 0 1 0 .06 0ZM15.998 12c0 1.047-.401 2-1.06 2.713a8.455 8.455 0 0 0 2.876-2.11.903.903 0 0 0 0-1.207 8.455 8.455 0 0 0-2.876-2.109A3.985 3.985 0 0 1 15.998 12Zm-3.976 2a2 2 0 0 0 1.805-2.862.667.667 0 1 1-.943-.943A2 2 0 1 0 12.022 14Z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
            :
            <div
                onClick={onClick}
                className={`mainCon  
            ${active && (issue ? "activeRed" : "activeGreen")} 
            `}
                style={{
                    right: right,
                    top: top,
                    transform: `scale(${scale ?? 1})`
                }}

            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    {...props}
                >
                    <circle
                        cx={12} cy={12} r={12}
                        fill={
                            active
                                ? "white"
                                : issue
                                    ? "#F80D38"
                                    : "#0BB024"
                        }
                        opacity={
                            active ? "1" : "0.16"
                        } />
                    <path
                        fill={issue ? "#FF001D" : "#00B817"}
                        fillRule="evenodd"
                        d="M6.23 11.396a8.468 8.468 0 0 1 2.797-2.074A3.986 3.986 0 0 0 8 12c0 1.03.389 1.969 1.028 2.678a8.47 8.47 0 0 1-2.797-2.074.903.903 0 0 1 0-1.208Zm-.5-.441C6.868 9.669 9.027 8.03 11.94 8H12.021c2.955 0 5.144 1.657 6.291 2.955a1.57 1.57 0 0 1 0 2.09C17.166 14.343 14.977 16 12.022 16H11.939c-2.913-.03-5.072-1.669-6.208-2.955a1.57 1.57 0 0 1 0-2.09Zm6.278-2.288h-.06a3.333 3.333 0 1 0 .06 0ZM15.998 12c0 1.047-.401 2-1.06 2.713a8.455 8.455 0 0 0 2.876-2.11.903.903 0 0 0 0-1.207 8.455 8.455 0 0 0-2.876-2.109A3.985 3.985 0 0 1 15.998 12Zm-3.976 2a2 2 0 0 0 1.805-2.862.667.667 0 1 1-.943-.943A2 2 0 1 0 12.022 14Z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
    )
}
export default Eye
