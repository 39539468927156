import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import customise from "../../../assets/images/customise.svg";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import classes from "./PackageDrawer.module.css";
import Typography from "@mui/material/Typography";
import { RemoveRedEye } from "@mui/icons-material";

export default function PackageDrawer({
  planName,
  showPackageDrawer,
  setShowPackageDrawer,
  vitalSigns,
  setVitalSigns,
  hair,
  setHair,
  eye,
  setEye,
  ear,
  setEar,
  dental,
  setDental,
  throat,
  setThroat,
  lungs,
  setLungs,
  heart,
  setHeart,
  abdomen,
  setAbdomen,
  skin,
  setSkin,
  behavioral,
  setBehavioral,
  learning, 
  setLearning,
  handlePackageChange
}) {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleChange = (label, arr, setArr ) => {
    if(arr.includes(label)){
      setArr(arr?.filter(el => el !== label))
    }else{
      setArr([...arr, label])
    }
  }

  const checked = (label, arr) => {
    
    return arr?.includes(label)
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 600 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, true)}
      // onKeyDown={toggleDrawer(anchor, true)}
    >
      <div className={classes.container}>
        <div className={classes.customise_header}>
          <img src={customise} alt="customise" />
          <p>Customise Screening Package</p>
        </div>
        <div className={classes.customise_selected_package}>
          Selected Package
        </div>
        <div className={classes.package_name}>{planName}</div>
        <div className={classes.physical_checkboxes_container}>
          <div className={classes.checkbox_header}>Physical Screening</div>
          <div className={classes.checkbox_sub_header}>Vital Signs</div>
          <div className={classes.checkbox_inner_container}>
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: "row",
                columnGap: "16px",
                flexWrap: "wrap",
              }}
            >
              <FormControlLabel
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                  },
                }}
                control={
                  <Checkbox
                    checked={vitalSigns?.includes("Temperature")}
                    onChange={() => handleChange("Temperature", vitalSigns, setVitalSigns)}
                    sx={{ "& .MuiSvgIcon-root": 
                            { 
                              fontSize: 16,
                              fill : "#66707E"
                            } }}
                  />
                }
                label={
                  <Typography className={classes.formControlLabel}>
                    Temperature
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={vitalSigns?.includes("Heart Rate")}
                    onChange={() => handleChange("Heart Rate", vitalSigns, setVitalSigns)
                }
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Heart Rate
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox
                  checked={vitalSigns?.includes("Hemoglobin")}
                  onChange={() => handleChange("Hemoglobin", vitalSigns, setVitalSigns)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }} />}
                label={
                  <Typography className={classes.formControlLabel}>
                    Hemoglobin
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox
                  checked={vitalSigns?.includes("Blood Pressure")}
                  onChange={() => handleChange("Blood Pressure", vitalSigns, setVitalSigns)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Blood Pressure
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={vitalSigns?.includes("SpO2")}
                    onChange={() => handleChange("SpO2", vitalSigns, setVitalSigns)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    SpO2
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={vitalSigns?.includes("Height and Weight")}
                    onChange={() => handleChange("Height and Weight", vitalSigns, setVitalSigns)
                }
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Height and Weight
                  </Typography>
                }
              />
            </FormGroup>
          </div>

          <div
            className={`${classes.checkbox_sub_header} ${classes.checkbox_sub_header_extra_margin}`}
          >
            Hair
          </div>
          <div className={classes.checkbox_inner_container}>
            <FormGroup
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              <FormControlLabel
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                  },
                }}
                control={
                  <Checkbox
                    checked={hair?.includes("Color of Hair")}
                    onChange={() => handleChange("Color of Hair", hair, setHair)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Color of Hair
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={hair?.includes("Dandruff")} onChange={() => handleChange("Dandruff", hair, setHair)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Dandruff
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox  checked={hair?.includes("Pediculosis (louse/lice)")} onChange={() => handleChange("Pediculosis (louse/lice)", hair, setHair)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Pediculosis (louse/lice)
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={hair?.includes("Loss of Hair")} onChange={() => handleChange("Loss of Hair", hair, setHair)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Loss of Hair
                  </Typography>
                }
              />
            </FormGroup>
          </div>

          <div
            className={`${classes.checkbox_sub_header} ${classes.checkbox_sub_header_extra_margin}`}
          >
            Eye
          </div>
          <div className={classes.checkbox_inner_container}>
            <FormGroup
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              <FormControlLabel
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                  },
                }}
                control={<Checkbox checked={eye?.includes("Myopia")} onChange={() => handleChange("Myopia", eye, setEye)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Myopia
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={eye?.includes("Hyperopia")} onChange={() => handleChange("Hyperopia", eye, setEye)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Hyperopia
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={eye?.includes("Astigmatism")} onChange={() => handleChange("Astigmatism", eye, setEye)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Astigmatism
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={eye?.includes("Anisocoria")} onChange={() => handleChange("Anisocoria", eye, setEye)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Anisocoria
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={eye?.includes("Anisometropia")} onChange={() => handleChange("Anisometropia", eye, setEye)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Anisometropia
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={eye?.includes("Gaze Asymmetry")} onChange={() => handleChange("Gaze Asymmetry", eye, setEye)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Gaze Asymmetry
                  </Typography>
                }
              />
            </FormGroup>
          </div>

          <div
            className={`${classes.checkbox_sub_header} ${classes.checkbox_sub_header_extra_margin}`}
          >
            Ear
          </div>
          <div className={classes.checkbox_inner_container}>
            <FormGroup
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              <FormControlLabel
                control={<Checkbox checked={ear?.includes("Otitis Media")} onChange={() => handleChange("Otitis Media", ear, setEar)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Otitis Media
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={ear?.includes("Otitis Externa")}  onChange={() => handleChange("Otitis Externa", ear, setEar)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Otitis Externa
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={ear?.includes("Ear Wax")}  onChange={() => handleChange("Ear Wax", ear, setEar)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Ear Wax
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={ear?.includes("Foreign Body in Canal")}  onChange={() => handleChange("Foreign Body in Canal", ear, setEar)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Foreign Body in Canal
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={ear?.includes("Hearing")}  onChange={() => handleChange("Hearing", ear, setEar)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Hearing
                  </Typography>
                }
              />
            </FormGroup>
          </div>

          <div
            className={`${classes.checkbox_sub_header} ${classes.checkbox_sub_header_extra_margin}`}
          >
            Dental
          </div>
          <div className={classes.checkbox_inner_container}>
            <FormGroup
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              <FormControlLabel
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                  },
                }}
                control={<Checkbox checked={dental?.includes("DMFT")} onChange={() => handleChange("DMFT", dental, setDental)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    DMFT
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={dental?.includes("Gingivitis")} onChange={() => handleChange("Gingivitis", dental, setDental)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Gingivitis
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={dental?.includes("Orthodontic Problem")} onChange={() => handleChange("Orthodontic Problem", dental, setDental)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Orthodontic Problem
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={dental?.includes("Decoloration")} onChange={() => handleChange("Decoloration", dental, setDental)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Decoloration
                  </Typography>
                }
              />
            </FormGroup>
          </div>

          <div
            className={`${classes.checkbox_sub_header} ${classes.checkbox_sub_header_extra_margin}`}
          >
            Throat
          </div>
          <div className={classes.checkbox_inner_container}>
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: "row",
                columnGap: "16px",
                flexWrap: "wrap",
              }}
            >
              <FormControlLabel
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                  },
                }}
                control={<Checkbox checked={throat?.includes("Stomatitis")} onChange={() => handleChange("Stomatitis", throat, setThroat)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Stomatitis
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={throat?.includes("Glossitis")} onChange={() => handleChange("Glossitis", throat, setThroat)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Glossitis
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={throat?.includes("Pharyngitis")} onChange={() => handleChange("Pharyngitis", throat, setThroat)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Pharyngitis
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={throat?.includes("Tonsillitis")} onChange={() => handleChange("Tonsillitis", throat, setThroat)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Tonsillitis
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={throat?.includes("White Patches")} onChange={() => handleChange("White Patches", throat, setThroat)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    White Patches
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={throat?.includes("Throat Congestion")} onChange={() => handleChange("Throat Congestion", throat, setThroat)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Throat Congestion
                  </Typography>
                }
              />
            </FormGroup>
          </div>
          <div
            className={`${classes.checkbox_sub_header} ${classes.checkbox_sub_header_extra_margin}`}
          >
            Heart
          </div>
          <div className={classes.checkbox_inner_container}>
            <FormGroup
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              <FormControlLabel
                control={<Checkbox checked={heart?.includes("Abnormal Heart Sound")} onChange={() => handleChange("Abnormal Heart Sound", heart, setHeart)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Abnormal Heart Sound
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={heart?.includes("Arrhythmia")} onChange={() => handleChange("Arrhythmia", heart, setHeart)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Arrhythmia
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={heart?.includes("Heart Murmur")} onChange={() => handleChange("Heart Murmur", heart, setHeart)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Heart Murmur
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={heart?.includes("Rhythm Disorder")} onChange={() => handleChange("Rhythm Disorder", heart, setHeart)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Rhythm Disorder
                  </Typography>
                }
              />
            </FormGroup>
          </div>
          <div
            className={`${classes.checkbox_sub_header} ${classes.checkbox_sub_header_extra_margin}`}
          >
            Lungs
          </div>
          <div className={classes.checkbox_inner_container}>
            <FormGroup
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              <FormControlLabel
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                  },
                }}
                control={<Checkbox checked={lungs?.includes("Respiratory Sounds")} onChange={() => handleChange("Respiratory Sounds", lungs, setLungs)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Respiratory Sounds
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={lungs?.includes("Dyspnea")} onChange={() => handleChange("Dyspnea", lungs, setLungs)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Dyspnea
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={lungs?.includes("Wheezing")} onChange={() => handleChange("Wheezing", lungs, setLungs)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Wheezing
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={lungs?.includes("Rattling")} onChange={() => handleChange("Rattling", lungs, setLungs)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Rattling
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={lungs?.includes("Cough")} onChange={() => handleChange("Cough", lungs, setLungs)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Cough
                  </Typography>
                }
              />
            </FormGroup>
          </div>
          <div
            className={`${classes.checkbox_sub_header} ${classes.checkbox_sub_header_extra_margin}`}
          >
            Abdomen
          </div>
          <div className={classes.checkbox_inner_container}>
            <FormGroup
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              <FormControlLabel
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                  },
                }}
                control={<Checkbox checked={abdomen?.includes("Bowel Obstruction")} onChange={() => handleChange("Bowel Obstruction", abdomen, setAbdomen)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Bowel Obstruction
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={abdomen?.includes("Abdominal Bruit")} onChange={() => handleChange("Abdominal Bruit", abdomen, setAbdomen)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Abdominal Bruit
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={abdomen?.includes("Intestinal Sounds")} onChange={() => handleChange("Intestinal Sounds", abdomen, setAbdomen)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Intestinal Sounds
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={abdomen?.includes("Paralytic Ileus")} onChange={() => handleChange("Paralytic Ileus", abdomen, setAbdomen)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Paralytic Ileus
                  </Typography>
                }
              />
            </FormGroup>
          </div>
          <div
            className={`${classes.checkbox_sub_header} ${classes.checkbox_sub_header_extra_margin}`}
          >
            Skin
          </div>
          <div className={classes.checkbox_inner_container}>
            <FormGroup
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              <FormControlLabel
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                  },
                }}
                control={<Checkbox checked={skin?.includes("Scabies")} onChange={() => handleChange("Scabies", skin, setSkin)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Scabies
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={skin?.includes("Rashes")} onChange={() => handleChange("Rashes", skin, setSkin)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Rashes
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={skin?.includes("Blisters")} onChange={() => handleChange("Blisters", skin, setSkin)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Blisters
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={skin?.includes("Urticaria")} onChange={() => handleChange("Urticaria", skin, setSkin)}
                sx={{ "& .MuiSvgIcon-root": 
                { 
                  fontSize: 16,
                  fill : "#66707E"
                } }}/>}
                label={
                  <Typography className={classes.formControlLabel}>
                    Urticaria
                  </Typography>
                }
              />
            </FormGroup>
          </div>
        </div>
        <div className={classes.behavioral_screening_container}>
          <div className={classes.checkbox_header}>Behavioral Screening</div>
          <FormGroup
            sx={{
              display: "flex",
              flexDirection: "row",
              columnGap: "16px",
              flexWrap: "wrap",
            }}
          >
            <FormControlLabel
              control={<Checkbox checked={behavioral?.includes("ADHD")} onChange={() => handleChange("ADHD", behavioral, setBehavioral)}
              sx={{ "& .MuiSvgIcon-root": 
              { 
                fontSize: 16,
                fill : "#66707E"
              } }}/>}
              label={
                <Typography className={classes.formControlLabel}>
                  ADHD
                </Typography>
              }
            />
            <FormControlLabel
              control={<Checkbox checked={behavioral?.includes("Autism")} onChange={() => handleChange("Autism", behavioral, setBehavioral)}
              sx={{ "& .MuiSvgIcon-root": 
              { 
                fontSize: 16,
                fill : "#66707E"
              } }}/>}
              label={
                <Typography className={classes.formControlLabel}>
                  Autism
                </Typography>
              }
            />
            <FormControlLabel
              control={<Checkbox checked={behavioral?.includes("Anxiety")} onChange={() => handleChange("Anxiety", behavioral, setBehavioral)}
              sx={{ "& .MuiSvgIcon-root": 
              { 
                fontSize: 16,
                fill : "#66707E"
              } }}/>}
              label={
                <Typography className={classes.formControlLabel}>
                  Anxiety
                </Typography>
              }
            />
            <FormControlLabel
              control={<Checkbox checked={behavioral?.includes("Conduct Disorder")} onChange={() => handleChange("Conduct Disorder", behavioral, setBehavioral)}
              sx={{ "& .MuiSvgIcon-root": 
              { 
                fontSize: 16,
                fill : "#66707E"
              } }}/>}
              label={
                <Typography className={classes.formControlLabel}>
                  Conduct Disorder
                </Typography>
              }
            />
            <FormControlLabel
              control={<Checkbox checked={behavioral?.includes("Depression")} onChange={() => handleChange("Depression", behavioral, setBehavioral)}
              sx={{ "& .MuiSvgIcon-root": 
              { 
                fontSize: 16,
                fill : "#66707E"
              } }}/>}
              label={
                <Typography className={classes.formControlLabel}>
                  Depression
                </Typography>
              }
            />
            <FormControlLabel
              control={<Checkbox checked={behavioral?.includes("Development Milestones")} onChange={() => handleChange("Development Milestones", behavioral, setBehavioral)}
              sx={{ "& .MuiSvgIcon-root": 
              { 
                fontSize: 16,
                fill : "#66707E"
              } }}/>}
              label={
                <Typography className={classes.formControlLabel}>
                  Development Milestones
                </Typography>
              }
            />
            <FormControlLabel
              control={<Checkbox checked={behavioral?.includes("Oppositional Defiant Disorder")} onChange={() => handleChange("Oppositional Defiant Disorder", behavioral, setBehavioral)}
              sx={{ "& .MuiSvgIcon-root": 
              { 
                fontSize: 16,
                fill : "#66707E"
              } }}/>}
              label={
                <Typography className={classes.formControlLabel}>
                  Oppositional Defiant Disorder
                </Typography>
              }
            />
            <FormControlLabel
              control={<Checkbox checked={behavioral?.includes("Digital Dependency")} onChange={() => handleChange("Digital Dependency", behavioral, setBehavioral)}
              sx={{ "& .MuiSvgIcon-root": 
              { 
                fontSize: 16,
                fill : "#66707E"
              } }}/>}
              label={
                <Typography className={classes.formControlLabel}>
                  Digital Dependency
                </Typography>
              }
            />
          </FormGroup>
        </div>
        <div className={classes.learning_screening_container}>
          <div className={classes.checkbox_header}>Learning Screening</div>
          <FormGroup
            sx={{
              display: "flex",
              flexDirection: "row",
              columnGap: "16px",
              flexWrap: "wrap",
            }}
          >
            <FormControlLabel
              control={<Checkbox checked={learning?.includes("Dyslexia")} onChange={() => handleChange("Dyslexia", learning, setLearning)}
              sx={{ "& .MuiSvgIcon-root": 
              { 
                fontSize: 16,
                fill : "#66707E"
              } }}/>}
              label={
                <Typography className={classes.formControlLabel}>
                  Dyslexia
                </Typography>
              }
            />
            <FormControlLabel
              control={<Checkbox checked={learning?.includes("Dyscalculia")} onChange={() => handleChange("Dyscalculia", learning, setLearning)}
              sx={{ "& .MuiSvgIcon-root": 
              { 
                fontSize: 16,
                fill : "#66707E"
              } }}/>}
              label={
                <Typography className={classes.formControlLabel}>
                  Dyscalculia
                </Typography>
              }
            />
            <FormControlLabel
              control={<Checkbox checked={learning?.includes("Dysgraphia")} onChange={() => handleChange("Dysgraphia", learning, setLearning)}
              sx={{ "& .MuiSvgIcon-root": 
              { 
                fontSize: 16,
                fill : "#66707E"
              } }}/>}
              label={
                <Typography className={classes.formControlLabel}>
                  Dysgraphia
                </Typography>
              }
            />
          </FormGroup>
        </div>
      </div>
      <div className={classes.control_btn_container}>
        <button
          onClick={toggleDrawer(anchor, !anchor)}
          className={`${classes.control_btn} ${classes.cancel_btn}`}
        >
          Cancel
        </button>
        <button className={`${classes.control_btn} ${classes.update_btn}`} onClick={()=>{toggleDrawer(anchor, false); handlePackageChange()}}>
          Update Package
        </button>
      </div>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}><span></span></Button> */}
          <span className = {classes.package_drawer_btn} onClick={toggleDrawer(anchor, true)}>Edit Package</span>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            PaperProps={{
              sx: {
                borderTopLeftRadius : "24px",
                borderBottomLeftRadius : "24px"
              },
            }}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
