import { Avatar } from "@mui/material";
import React, { useState } from "react";
import classes from "./BlogRow.module.css";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { useNavigate, useLocation, Link } from "react-router-dom";
import RowPopup from "../common/TableComponents/RowPopup";
import moment from "moment";
import { capitalizeFirstLetter, truncateString } from "../../utils/utility";

export default function BlogRow({ data, index, handlePublishBlog }) {
    // const { id, schoolName, pincode, schoolCode, board, organization, eventDate, status, action, url, strength } = data;

    const [user, setUser] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();


    function getWordBeforeDash() {
        let newStr = "";
        for (let i = 1; i < location.pathname.length; i++) {
            if (location.pathname[i] === "-") {
                return newStr;
            }
            newStr += location.pathname[i];
        }
    }

    const getBlogInfo = (whereTo) => {
        if (getWordBeforeDash() === "writer") {
            location.state.breadCrumbs.push(data?.title);

            navigate(
                `/writer/${whereTo}/${data?.blogId
                }`,
                { state: { ...location.state, ...data } }
            );
        }
    };

    return (
        <>
            <div className={classes.studentRow_container}>
                {/* Name and Avatar Container */}
                {/* <div> */}
                {/* Avatar Container */}
                {/* {url ? (
            <div>
              <img src={url} alt="schoolLogo" width="32" height="32" />
            </div>
          ) : ( */}
                {/* <div>
            {!data?.url ? <Avatar {...stringAvatar(`${data?.schoolName} `)} /> : <img src={data?.url} style={{ borderRadius: 50 }} alt="schoolLogo" width="32" height="32" />}
          </div> */}
                {/* )} */}

                {/* Name Container */}
                {/* <div title={data?.ticketId}>
            <p>{data?.ticketId ? capitalizeFirstLetter(truncateString(data?.ticketId, 30)) : "Unavailable"}</p>
          </div> */}
                {/* </div> */}

                {/* Gender Container */}
                <div>
                    <p>{data?.title ? data?.title : "Unavailable"}</p>
                </div>

                <div>
                    <p>{data?.author ? data?.author : "Unavailable"}</p>
                </div>

                <div>
                    <p>{data?.category ? data?.category : "Unavailable"}</p>
                </div>

                {/* Class Container */}
                <div>
                    {data?.description ? data?.description.length > 30 ? <p dangerouslySetInnerHTML={{ __html: truncateString(data?.description, 30) }} /> : <p dangerouslySetInnerHTML={{ __html: data?.description }} /> : <p>Unavailable</p>}
                    {/* <p dangerouslySetInnerHTML={{_html:  ? data?.description.length > 30 ? truncateString(data?.description, 30) : data?.description : "Unavailable"}} /> */}
                    {/* </p> */}
                </div>

                <div>
                    {data?.status !== "PUBLISHED" ? (
                        <div className={classes.screened_btn}>DRAFT</div>
                    ) : (
                        <div className={classes.report_btn_active}>PUBLISHED</div>
                    )}
                </div>

                {/* )} */}

                <div>
                    <p>
                        {data?.createdAt
                            ? new Date(data?.createdAt).toLocaleTimeString("en-IN", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                            })
                            : "Unavailable"}
                    </p>
                </div>

                <div>
                    <p>
                        {data?.updatedAt
                            ? new Date(data?.updatedAt).toLocaleTimeString("en-IN", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                            })
                            : "Unavailable"}
                    </p>
                </div>

                {/* Action Container */}
                <div title="school info" style={{ cursor: 'pointer', position: "relative", justifySelf: "center" }}>
                    <div className={classes.infoHover}  >
                        <p>...</p>
                        <div className={classes.popOnHover}>
                            <RowPopup navLink={getBlogInfo} data={data} handlePublishBlog={handlePublishBlog} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
