import React, { useState, useEffect } from "react";
import classes from "./AddOrganization.module.css";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import ArrowRight from "@mui/icons-material/ArrowRight";
import schoollogo from "../../../assets/images/schoollogo.png";
import upload from "../../../assets/images/upload.svg";
import { Link, useParams, useNavigate } from "react-router-dom";
import InputDashboard from "../InputDashboard/InputDashboard";
import InputDropdown from "../InputDashboard/InputDropdown";
import InputDatePicker from "../InputDashboard/InputDatePicker";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomMultiSelectDropdown from "../InputDashboard/CustomMultiSelect";
import { findByAltText } from "@testing-library/react";
import DatePickerInput from "../InputDashboard/DatePickerInput";
import { Alert, Avatar, Snackbar } from "@mui/material";
import schoolReducer from "../../../redux/reducers/school.reducer";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter, compareDate } from "../../../utils/utility";
import { State, City } from "country-state-city";
import clinincReducer from "../../../redux/reducers/clininc.reducer";
import { API } from "aws-amplify";
import Cookies from "js-cookie";
import MultipleSelectChip from "../MultiSelectDropdown/MultiSelectDropdown";
import axios from "axios";
import typeformUpload from "../../../assets/images/typeformUpload.svg";
import eventReducer from "../../../redux/reducers/event.reducer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import { validateEmail } from "../../../utils/utility";
import { Buffer } from "buffer";
import BreadCrumbs from "../TableComponents/BreadCrumbs";
import notification from "../../../assets/audios/notificationSound.mp3"
import moment from "moment";

function AddOrganizationPage() {
  let notify = new Audio(notification);
  const token = Cookies.get("tokenId");
  const { entity } = useParams();
  const navigate = useNavigate();

  const firstLetterEntity = entity[0].toUpperCase();
  const createSchool = schoolReducer.actions.createSchoolRequest;
  const createEvent = eventReducer.actions.createEventRequest;
  const createClinic = clinincReducer.actions.createClinicRequest;
  const entityLetter = entity.substring(1);
  const finalLetter = firstLetterEntity + entityLetter;
  const dispatch = useDispatch();
  const [schoolName, setSchoolName] = useState("");
  const [organization, setOrganization] = useState("");
  const [established, setEstablished] = useState("");
  const [screeningPlan, setScreeningPlan] = useState("");
  const [screeningFrom, setScreeningFrom] = useState("");
  const [screeningTo, setScreeningTo] = useState("");
  const [schoolCode, setSchoolCode] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [board, setBoard] = useState("");
  const [orientation, setOrientation] = useState("");
  const [isClinic, setIsClinic] = useState()
  const [city, setCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [infirmary, setInfirmary] = useState("");
  const [teacher, setTeacher] = useState("");
  const [counsellor, setCounsellor] = useState("");
  const [student, setStudent] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminTitle, setAdminTitle] = useState("");
  const [adminFirstName, setAdminFirstName] = useState("");
  const [adminLastName, setAdminLastName] = useState("");
  const [adminPhone, setAdminPhone] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [staff, setStaff] = useState("");
  const [doctor, setDoctor] = useState(null);
  const [nurse, setNurse] = useState(null);
  const [planName, setPlanName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [logoRes, setLogoRes] = useState(false);
  const [receivedData, setReceivedData] = useState(null);
  const [pincodeError, setPincodeError] = useState(false);
  const [adminNumberError, setAdminNumberError] = useState(false)
  const [schoolCodeError, setSchoolCodeError] = useState(false)
  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [imageShow, setImageShow] = useState("")
  const [establishedError, setEstablishedError] = useState(false);
  const [screeningFromError, setScreeningFromError] = useState(false);
  const [screeningToError, setScreeningToError] = useState(false);

  // Package States
  const [showPackageDrawer, setShowPackageDrawer] = useState(false);
  const [vitalSigns, setVitalSigns] = useState([]);
  const [hair, setHair] = useState([])
  const [eye, setEye] = useState([])
  const [ear, setEar] = useState([])
  const [dental, setDental] = useState([])
  const [throat, setThroat] = useState([])
  const [heart, setHeart] = useState([])
  const [lungs, setLungs] = useState([])
  const [abdomen, setAbdomen] = useState([])
  const [skin, setSkin] = useState([])
  const [behavioral, setBehavioral] = useState([])
  const [learning, setLearning] = useState([])

  const errorClinic = useSelector((state) => state.clinicReducer.error);

  const errorSchool = useSelector((state) => state.schoolReducer.error);
  const loadingClinic = useSelector((state) => state.clinicReducer.loading);
  const loadingSchool = useSelector((state) => state.schoolReducer.loading);

  const createdSchoolId = useSelector(
    (state) => state.schoolReducer.created.schoolId
  );

  const isAuth = useSelector((state) => state.authReducer.loggedIn);

  useEffect(() => {
    if (pincode.length) {
      if (pincode.length === 6) {
        setPincodeError(false)
      }
      else {
        setPincodeError(true)
      }
    }
    else {
      setPincodeError(false)
    }
  }, [pincode]);

  const blob2img = async () => {
    // const x = await toBase64()
    if (logoRes) {
      var reader = new FileReader();
      reader.readAsDataURL(logoRes);
      reader.onloadend = function () {
        var base64data = reader.result;
        
        setImageShow(base64data)
      }
    }
    // setImageShow(x?.data)
  }

  useEffect(() => {
    const phoneRegEx = /^[6-9]{1}[0-9]{9}$/;
    if (adminPhone.length > 0) {
      setAdminNumberError(!phoneRegEx.test(adminPhone))
    }
  }, [adminPhone]);

  useEffect(() => {
    const phoneRegEx = /^[6-9]{1}[0-9]{9}$/;
    if (phoneNumber.length > 0) {
      setPhoneNumberError(!phoneRegEx.test(phoneNumber))
    }
  }, [phoneNumber]);

  useEffect(() => {
    const schoolCodeRegEx = /^([a-zA-Z0-9 -]+)$/;
    if (schoolCode.length > 0) {
      setSchoolCodeError(!schoolCodeRegEx.test(schoolCode))
    }
  }, [schoolCode]);

  useEffect(() => {
    const states = State.getStatesOfCountry("IN").map((state) => state.name);
    setStateOptions(states);
  }, []);

  useEffect(() => {
    if (selectedState) {
      const selectedStateObject = State.getStatesOfCountry("IN").filter(
        (x) => x.name === selectedState
      );
      setCityOptions(
        City.getCitiesOfState("IN", selectedStateObject[0].isoCode).map(
          (x) => x.name
        )
      );
    }
  }, [selectedState]);

  useEffect(() => {
    if (createdSchoolId) {
      const eventParams = {
        fromDate: screeningFrom,
        toDate: screeningTo,
        schoolId: createdSchoolId,
        status: "Confirmed",
        package: planName.split(" ").join("_").toUpperCase(),
      };
      dispatch(createEvent(eventParams));
      handleUploadFileOnServer();
    }
  }, [createdSchoolId]);

  useEffect(() => {
    if (planName === "Basic") {
      setVitalSigns(["Temperature", "Height and Weight", "SpO2", "Heart Rate"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setHair([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setEye(["Myopia", "Hyperopia"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setEar(["Otitis Media", "Otitis Externa", "Ear Wax", "Foreign Body in Canal"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setDental(["Gingivitis", "Orthodontic Problem", "Decoloration"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setThroat(["Throat Congestion"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setHeart([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setLungs([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setAbdomen([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setSkin([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setBehavioral(["ADHD", "Autism", "Anxiety", "Digital Dependency"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setLearning([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
    } else if (planName === "Advanced") {
      setVitalSigns(["Temperature", "Height and Weight", "SpO2", "Heart Rate", "Hemoglobin", "Blood Pressure"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setHair(["Color of Hair", "Dandruff", "Pediculosis (louse/lice)", "Loss of Hair"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setEye(["Myopia", "Hyperopia", "Astigmatism", "Anisocoria", "Anisometropia", "Gaze Asymmetry"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setEar(["Otitis Media", "Otitis Externa", "Ear Wax", "Foreign Body in Canal", "Hearing"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setDental(["DMFT", "Gingivitis", "Orthodontic Problem", "Decoloration"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setThroat(["Stomatitis", "Glossitis", "Pharyngitis", "Tonsillitis", "White Patches", "Throat Congestion"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setHeart(["Abnormal Heart Sound", "Arrhythmia", "Heart Murmur", "Rhythm Disorder"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setLungs(["Respiratory Sounds", "Dyspnea", "Wheezing", "Rattling", "Cough"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setAbdomen(["Bowel Obstruction", "Abdominal Bruit", "Intestinal Sounds", "Paralytic Ileus"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setSkin(["Scabies", "Rashes", "Blisters", "Urticaria"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setBehavioral(["ADHD", "Autism", "Anxiety", "Conduct Disorder", "Oppositional Defiant Disorder", "Digital Dependency", "Depression", "Development Milestones"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setLearning(["Dyslexia", "Dyscalculia", "Dysgraphia"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
    } else if (planName === "Certificate Plus") {
      setVitalSigns(["Temperature", "Height and Weight", "SpO2", "Heart Rate", "Hemoglobin", "Blood Pressure"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setHair(["Color of Hair", "Dandruff", "Pediculosis (louse/lice)", "Loss of Hair"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setEye(["Myopia", "Hyperopia"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setEar(["Otitis Media", "Otitis Externa", "Ear Wax", "Foreign Body in Canal"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setDental(["DMFT", "Gingivitis", "Orthodontic Problem", "Decoloration"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setThroat(["Throat Congestion"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setHeart([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setLungs(["Respiratory Sounds", "Cough"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setAbdomen([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setSkin(["Scabies", "Rashes", "Blisters", "Urticaria"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setBehavioral(["ADHD", "Autism", "Anxiety", "Digital Dependency"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      setLearning([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
    } else {
      setVitalSigns([])
      setHair([])
      setEye([])
      setEar([])
      setDental([])
      setThroat([])
      setHeart([])
      setLungs([])
      setAbdomen([])
      setSkin([])
      setBehavioral([])
      setLearning([])
    }
  }, [planName])

  // useEffect(() => {
  //   if(selectedFile){
  //     dispatch(createLogoSuccess(selectedFile))
  //   }
  // }, [selectedFile])

  const handleDeleteAmenities = (element) => {
    const filteredAmenities = amenities.filter((el) => el !== element);
    setAmenities(filteredAmenities);
  };

  const toBase64 = () => new Promise((resolve, reject) => {
    if (logoRes) {
      const readers = new FileReader();
      readers.readAsArrayBuffer(logoRes);
      readers.onload = () => {
        resolve(Buffer.from(readers.result).toJSON())

      };
      readers.onerror = error => reject(error);
    }

  });

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (entity === "school") {
      const earjoin = ear.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const eyejoin = eye.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const abdomenjoin = abdomen.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const vitalSignsjoin = vitalSigns.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const hairjoin = hair.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const dentaljoin = dental.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const throatjoin = throat.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const heartjoin = heart.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const lungsjoin = lungs.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const skinjoin = skin.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const behavoraljoin = behavioral.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const learningjoin = learning.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const joinedAmenities = amenities.join(",")
      let img;
      if (logoRes) {
        img = await toBase64();
      }
      const params = {
        address: address,
        state: selectedState,
        city: city,
        contact: {
          email: email,
          name: "Dummy Name",
          mobNumber: "123456789",
          officeNumber: phoneNumber,
        },
        staffAndAmenities: {
          teachers: teacher,
          // infirmary: infirmary === "Yes" ? true : false,
          amenitiesAvailable: joinedAmenities,
          counsellors: counsellor,
        },
        schoolAdmin: {
          name: adminTitle + " " + adminFirstName + " " + adminLastName,
          email: adminEmail,
          phone: adminPhone,
        },
        planName: planName.toUpperCase().split(" ").join("_"),
        name: "Dummy Name2",
        pincode: parseInt(pincode),
        schoolCode: schoolCode,
        schoolName: capitalizeFirstLetter(schoolName),
        strength: parseInt(student),
        url: "",
        board: board.split(" ").join("").toUpperCase(),
        organization: organization,
        annualOrientationMonth: orientation,
        establishedFrom: established,
        screeningPlan: screeningPlan,
        screeningFrom: screeningFrom,
        screeningTo: screeningTo,
        doctor,
        nurse,
        ear: earjoin,
        eye: eyejoin,
        abdomen: abdomenjoin,
        hair: hairjoin,
        skin: skinjoin,
        throat: throatjoin,
        heart: heartjoin,
        lungs: lungsjoin,
        vitalSigns: vitalSignsjoin,
        dental: dentaljoin,
        behavoral: behavoraljoin,
        learning: learningjoin,
        logo: JSON.stringify(img),
        isClinic: isClinic === "Yes" ? true : false
      };
      if (compareDate(screeningFrom, screeningTo)) {
        try {
          await dispatch(createSchool(params));
        } catch (error) {
        } finally {
          handleClick();
        }

        // await handleUploadFileOnServer()
        // 
      } else {
        alert("From date must be less than to date");
      }
    } else {
      const params = {
        address: address,
        city: city,
        clinicAdmin: {
          email: adminEmail,
          name: `${adminTitle} ${adminFirstName} ${adminLastName}`,
          phone: adminPhone,
        },
        name: schoolName,
        type: board === "Skids Owned" ? "SKIDS_Owned" : board,
        speciality: speciality,
        establishment: established,
        email: email,
        mobNumber: phoneNumber,
        officeNumber: phoneNumber,
        pincode: pincode,
        ameneties: amenities,
        doctor: teacher,
        staff: staff,
        nurse: student,
        state: selectedState,
      };
      await dispatch(createClinic(params));
      handleClick();
      if (!loadingClinic && !errorClinic) {
        setTimeout(() => navigate(-1), 5000)
      }
    }
  };

  const authHeaders = {
    method: "PUT",
    body: {
      selectedFile,
    },
    headers: {
      Authorization: token,
    },
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    notify.play();
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const handleUploadFileOnServer = async () => {
    try {
      if (isAuth && selectedFile && createdSchoolId) {
        try {
          if (isAuth && selectedFile) {
            return await API.put(
              "MyLogoAPIGateway",
              `/${createdSchoolId}.jpeg`,
              authHeaders
            );
          }
        } catch (error) {

        }
      }
    } catch (error) {

    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    const establishedDate = established;
    const currentDate = moment().format("YYYY-MM-DD");
    established > currentDate ? setEstablishedError(true) : setEstablishedError(false);
  }, [established]);

  useEffect(() => {
    if(screeningFrom)
      {const screeningFromDate = screeningFrom;
    const currentDate = moment().format("YYYY-MM-DD");
    
    screeningFrom < currentDate ? setScreeningFromError(true) : setScreeningFromError(false);}
    else{
      setScreeningFromError(false)
    }

  }, [screeningFrom]);

  useEffect(() => {
    const screeningFromDate = new Date(screeningFrom);
    const screeningToDate = new Date(screeningTo);
    screeningFromDate > screeningToDate ? setScreeningToError(true) : setScreeningToError(false);
  }, [screeningTo, screeningFrom]);



  useEffect(() => {
    blob2img()
  }, [logoRes])

  // const handleFileRead = async (event) => {
  //   const file = event.target.files[0];
  //   const base64 = await convertBase64(file);
  //   setSelectedFile(base64);
  // };

  // useEffect(() => {
  //   handleUploadFileOnServer()
  //   if(logoRes){
  //     fetchLogoFileFromServer()
  //   }
  // }, [selectedFile])
  // 

  return (
    <div className={classes.main_div}>
      <BreadCrumbs />
      <div className={classes.container}>
        <div className={classes.logo_text}>{finalLetter} Logo</div>
        <div className={classes.upload_logo}>
          {
            logoRes ?
              <img
                alt=""
                src={imageShow}
                className={classes.uploadLogo}
              /> :
              <div className={classes.no_upload_logo}>
                <ImageRoundedIcon sx={{ fill: "#e8e7e7", width: "56px", height: "56px" }} />
              </div>
          }
          <div className={classes.upload}>
            <label
              className={classes.uploadLabel}
              htmlFor="openUpload"
              style={{ color: "#3b3cea" }}
            >
              <img className={classes.uploadImage} alt="" src={upload}></img>{" "}
              Upload {entity} Logo
            </label>
            <input
              id="openUpload"
              className={classes.uploadPhoto}
              type="file"
              accept="image/jpeg,"
              onChange={(e) => setLogoRes(e.target.files[0])}
            />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={classes.input_school_layout}>
            <InputDashboard
              placeholder={`${finalLetter} name`}
              width={"47.7vw"}
              className={classes.input_layout_schoolname}
              change={(e) => {
                setSchoolName(e.target.value);
              }}
              margin={2}
              bgColor="#ffffff"
              value={schoolName}
            />
            <InputDropdown
              placeholder={`${entity === "school" ? "Board/Afffiliation" : "Type"
                }`}
              width={"23vw"}
              className={classes.input_layout_boardname}
              bgColor="#ffffff"
              value={board}
              margin={2}
              setValue={setBoard}
              defaultValues={
                entity === "school"
                  ? ["CBSE", "ICSE", "IB", "State Board"]
                  : ["Affiliated", "Skids Owned"]
              }
            />
          </div>
          {entity === "school" ? (
            <div>
              <div className={classes.input_layout}>
                <InputDropdown
                  placeholder={"Organization"}
                  width={"23vw"}
                  bgColor="#ffffff"
                  margin={2.4}
                  value={organization}
                  setValue={setOrganization}
                  defaultValues={["Aided", "Unaided", "Government"]}
                />
                <InputDropdown
                  placeholder={"Annual orientation month"}
                  width={"23vw"}
                  margin={2.4}
                  value={orientation}
                  setValue={setOrientation}
                  bgColor="#ffffff"
                  defaultValues={[
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ]}
                />

                <InputDropdown
                  placeholder={"Package"}
                  width={"23vw"}
                  bgColor="#ffffff"
                  margin={2.4}
                  value={planName}
                  setValue={setPlanName}
                  defaultValues={["Basic", "Certificate Plus", "Advanced"]}
                />
              </div>
              <div className={classes.input_layout}>
                <InputDropdown
                  placeholder={"Screening Plan"}
                  width={"23vw"}
                  bgColor="#ffffff"
                  margin={2.4}
                  value={screeningPlan}
                  setValue={setScreeningPlan}
                  defaultValues={["Annually", "Quarterly", "Monthly"]}
                />
                <div>
                  <DatePickerInput
                    onChange={(e) => {
                      setScreeningFrom(e.target.value);
                    }}
                    width={"23vw"}
                    placeholder={"Screening From"}
                    value={screeningFrom}
                  />
                  {screeningFromError && (
                    <div className={classes.validation_text}>
                      Please enter Valid Screening from Date*
                    </div>
                  )}
                </div>
                <div>
                  <DatePickerInput
                    onChange={(e) => {
                      setScreeningTo(e.target.value);
                    }}
                    width={"23vw"}
                    placeholder={"Screening To"}
                    value={screeningTo}
                  />
                  {screeningToError && (
                    <div className={classes.validation_text}>
                      Please enter Valid Screening to Date*
                    </div>
                  )}
                </div>

              </div>
              <div className={classes.input_layout}>
                <div>
                  <DatePickerInput
                    onChange={(e) => {
                      setEstablished(e.target.value);
                    }}
                    width={"23vw"}
                    placeholder={"Established from"}
                    value={established}
                  />
                  {establishedError && (
                    <div className={classes.validation_text}>
                      Please enter Valid Established Date*
                    </div>
                  )}
                </div>

                <InputDropdown
                  placeholder={"Is Clinic"}
                  width={"23vw"}
                  margin={2.4}
                  value={isClinic}
                  setValue={setIsClinic}
                  bgColor="#ffffff"
                  defaultValues={[
                    "Yes",
                    "No"
                  ]}
                />

              </div>
            </div>
          ) : (
            <></>
          )}

          {entity === "clinic" ? (
            <div>
              <div className={classes.input_layout}>
                <InputDropdown
                  placeholder={"Speciality"}
                  width={"23vw"}
                  bgColor="#ffffff"
                  margin={2.4}
                  value={speciality}
                  setValue={setSpeciality}
                  defaultValues={[
                    "Multi speciality",
                    "Super speciality",
                    "Advisory",
                  ]}
                />
                <div>
                  <DatePickerInput
                    onChange={(e) => {
                      setEstablished(e.target.value);
                    }}
                    width={"23vw"}
                    placeholder={"Established from"}
                    value={established}
                  />
                  {establishedError && (
                    <div className={classes.validation_text}>
                      Please enter Valid Established Date*
                    </div>
                  )}
                </div>
                {/* <BasicDatePicker /> */}
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className={classes.contact_info}>Contact information</div>
          <div className={classes.input_layout}>
            <div>
              <InputDashboard
                placeholder={"Email id"}
                width={"23vw"}
                change={(e) => {
                  setEmail(e.target.value);
                }}
                margin={2.4}
                bgColor="#ffffff"
                value={email}
              />
              {!validateEmail(email) && email.length ? (
                <div className={classes.validation_text}>
                  {" "}
                  Please fill valid email id*
                </div>
              ) : (
                <></>
              )}
            </div>
            <div><InputDashboard
              placeholder={"Phone number"}
              width={"23vw"}
              margin={2.4}
              change={(e) => {
                setPhoneNumber(e.target.value);
              }}
              bgColor="#ffffff"
              value={phoneNumber}
              type={"number"}
            />
              {phoneNumberError &&
                <div className={classes.validation_text}>
                  {" "}
                  Please fill valid 10 digit number*
                </div>
              }
            </div>
            <div>
              <InputDashboard
                placeholder={entity === "school" ? "School code" : "Clinic Code"}
                required={true}
                width={"23vw"}
                margin={2.4}
                className={classes.input_layout_schoolname}
                change={(e) => {
                  setSchoolCode(e.target.value);
                }}
                bgColor="#ffffff"
                value={schoolCode}
              />
              {schoolCodeError &&
                <div className={classes.validation_text}>
                  {" "}
                  Please enter only alphanumeric values*
                </div>
              }
            </div>
          </div>
          <InputDashboard
            placeholder={`${finalLetter} address`}
            width={"48vw"}
            margin={2.4}
            change={(e) => {
              setAddress(e.target.value);
            }}
            bgColor="#ffffff"
            value={address}
          />
          <div className={classes.input_layout}>
            <InputDropdown
              placeholder={"State"}
              width={"23vw"}
              bgColor="#ffffff"
              margin={2.4}
              value={selectedState}
              setValue={setSelectedState}
              defaultValues={stateOptions}
            />
            <InputDropdown
              placeholder={"City"}
              width={"23vw"}
              bgColor="#ffffff"
              value={city}
              margin={2.4}
              setValue={setCity}
              defaultValues={cityOptions}
            />
            <div>
              <InputDashboard
                placeholder={"Pincode"}
                width={"23vw"}
                margin={2.4}
                change={(e) => {
                  setPincode(e.target.value);
                }}
                bgColor="#ffffff"
                value={pincode}
                type={"number"}
              />
              {pincodeError &&
                <div className={classes.validation_text}>
                  {" "}
                  Please fill valid 6 digit Pincode*
                </div>
              }
            </div>
          </div>
          <div className={classes.contact_info}>Staff & Ameneties</div>
          <div className={classes.input_layout}>
            <InputDashboard
              placeholder={`${entity === "school" ? "Teachers" : "Doctors"}`}
              width={"23vw"}
              margin={2.4}
              change={(e) => {
                setTeacher(e.target.value);
              }}
              bgColor="#ffffff"
              value={teacher}
              type={"number"}
            />
            <InputDashboard
              placeholder={`${entity === "school" ? "Students" : "Nurses"}`}
              width={"23vw"}
              margin={2.4}
              change={(e) => {
                setStudent(e.target.value);
              }}
              bgColor="#ffffff"
              value={student}
              type={"number"}
            />
            {entity === "school" ? (
              <InputDashboard
                placeholder={"Counsellors"}
                width={"23vw"}
                bgColor="white"
                value={counsellor}
                margin={2.4}
                change={(e) => {
                  setCounsellor(e.target.value);
                }}
                type={"number"}
              />
            ) : (
              <InputDashboard
                placeholder={"Staff"}
                width={"23vw"}
                margin={2.4}
                change={(e) => {
                  setStaff(e.target.value);
                }}
                bgColor="#ffffff"
                value={staff}
                type={"number"}
              />
            )}
          </div>
          {entity === "clinic" ? (
            <div className={classes.input_layout}>
              <MultipleSelectChip
                entity={entity}
                amenities={amenities}
                setAmenities={setAmenities}
                options={["Ambulance", "Beds", "ECU", "ICU", "Operation Theatre"]}
                width={"23vw"}
                margin={2}
              />
            </div>
          ) : (
            <></>
          )}

          {entity === "school" ? (
            <div>
              <div className={classes.input_layout}>
                <InputDashboard
                  placeholder={"Doctor"}
                  width={"23vw"}
                  margin={2.4}
                  change={(e) => {
                    setDoctor(e.target.value);
                  }}
                  bgColor="#ffffff"
                  value={doctor}
                  type={"number"}
                />
                <InputDashboard
                  placeholder={"Nurse"}
                  width={"23vw"}
                  margin={2.4}
                  change={(e) => {
                    setNurse(e.target.value);
                  }}
                  bgColor="#ffffff"
                  value={nurse}
                  type={"number"}
                />
                {/* <BasicDatePicker /> */}
                <MultipleSelectChip
                  entity={entity}
                  amenities={amenities}
                  setAmenities={setAmenities}
                  options={[
                    "Medical Room",
                    "Infirmary Bed",
                    "First Aid Box",
                    "Sanitization",
                    "Thermometer",
                    "BP Machine",
                    "Ambulance",
                    "Oxygen Support"
                  ]}
                  width={"23vw"}
                  margin={2.4}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className={classes.entity_admin_header}>
            {finalLetter} Admin registration information
          </div>
          <div className={classes.input_layout}>
            <div>
              <InputDashboard
                placeholder={"Email id"}
                width={"23vw"}
                margin={2.4}
                change={(e) => {
                  setAdminEmail(e.target.value);
                }}
                bgColor="#ffffff"
                value={adminEmail}
              />
              {!validateEmail(adminEmail) && adminEmail.length ? (
                <div className={classes.validation_text}>
                  {" "}
                  Please fill valid email id*
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={classes.entity_admin_text}>
            New user login credentials will be sent to this email id
          </div>
          <div className={classes.contact_info}>Personal information</div>
          {/* <div className={classes.lettered_avatar_container}>
            <Avatar
              src={imageShow}
              sx={{
                height: 96,
                width: 96,
                fontSize: 72,
                fontWeight: "bold",
                  backgroundColor: "#4780f5",
              }}
            >
              {firstLetterEntity}
            </Avatar>
          </div> */}

          <div className={classes.input_admin_layout}>
            <InputDropdown
              placeholder={`Title`}
              width={"7vw"}
              bgColor="white"
              margin={2.4}
              value={adminTitle}
              setValue={setAdminTitle}
              defaultValues={["Mr.", "Mrs.", "Dr.", "Ms."]}
            />
            <InputDashboard
              placeholder={`First Name`}
              width={"23vw"}
              margin={2.4}
              change={(e) => {
                setAdminFirstName(e.target.value);
              }}
              bgColor="#ffffff"
              value={adminFirstName}
            />
            <InputDashboard
              placeholder={`Last Name`}
              width={"23vw"}
              margin={2.4}
              change={(e) => {
                setAdminLastName(e.target.value);
              }}
              bgColor="#ffffff"
              value={adminLastName}
            />
          </div>
          <div className={classes.contact_info}>Contact information</div>
          <div className={classes.input_layout}>
            <div>
              <InputDashboard
                placeholder={`Phone number`}
                width={"23vw"}
                margin={2.4}
                change={(e) => {

                  setAdminPhone(e.target.value);
                }}
                bgColor="#ffffff"
                value={adminPhone}
                type={"number"}
              />
              {adminNumberError &&
                <div className={classes.validation_text}>
                  {" "}
                  Please fill valid 10 digit number*
                </div>
              }
            </div>
          </div>
          {(entity === 'clinic' ? !loadingClinic : !loadingSchool) &&
            <Snackbar
              open={open}
              autoHideDuration={2400}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity={
                  (entity === "clinic" ? !errorClinic : !errorSchool)
                    ? "success"
                    : "error"
                }
                sx={{ width: "100%" }}
              >
                {(entity === "clinic" ? !errorClinic : !errorSchool)
                  ? `Hurrah !! New ${entity === "school" ? "School" : "Clinic"
                  } On-boarded`
                  : `Oh Oh !! Some issue in adding ${entity === "school" ? "School" : "Clinic"
                  }. Try Again`}
              </Alert>
            </Snackbar>
          }
          <div className={classes.buttons}>
            <button className={classes.button_with_border} onClick={() => navigate(-1)}>
              {/* {schoolName &&
                amenities &&
                board &&
                orientation &&
                city &&
                selectedState &&
                infirmary &&
                amenities.length > 0
                ? "CANCEL"
                : "SAVE"} */}
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              className={classes.button_filled}
              disabled={entity === 'school' ?
                (
                  validateEmail(email) && validateEmail(adminEmail)
                    && schoolName && board && organization && orientation && planName && screeningPlan
                    && screeningFrom && screeningTo && established && email && phoneNumber
                    && !screeningFromError && !screeningToError && !establishedError
                    && schoolCode && address && stateOptions && city && pincode && teacher &&
                    student && counsellor && doctor && nurse && amenities && adminEmail && adminTitle
                    && adminFirstName && adminLastName && adminPhone && !pincodeError && !adminNumberError && !phoneNumberError
                    ? false : true) :
                (
                  entity === 'clinic' ?
                    (
                      validateEmail(email) && validateEmail(adminEmail)
                        && schoolName && board && speciality
                        && established && email && phoneNumber && !establishedError
                        && schoolCode && address && stateOptions && city && pincode && teacher &&
                        student && staff && amenities && adminEmail && adminTitle
                        && adminFirstName && adminLastName && adminPhone && !pincodeError && !adminNumberError && !phoneNumberError
                        ? false : true) :
                    (false)
                )}
            >
              Add {finalLetter}
            </button>
            {/* const params = {
        address: address,
        city: city,
        clinicAdmin: {
          email: adminEmail,
          name: `${adminTitle} ${adminFirstName} ${adminLastName}`,
          phone: adminPhone,
        },
        name: schoolName,
        type: board === "Skids Owned" ? "SKIDS_Owned" : board,
        speciality: speciality,
        establishment: established,
        email: email,
        mobNumber: phoneNumber,
        officeNumber: phoneNumber,
        pincode: pincode,
        ameneties: amenities,
        doctor: teacher,
        staff: staff,
        nurse: student,
        state: selectedState,
      }; */}
          </div>
        </form>
      </div>
    </div>
  );
}

function AddSchool() {
  return <AddOrganizationPage />;
}

export default AddSchool;
