import React from "react";
import "./HealthReport.css";
import vitalsigns from "../../assets/images/vitalsignsnewlogo.svg";
import hairwhite from "../../assets/images/harinewlogo.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { checkReportCondition, findInterpetetion, findValidation } from "../../utils/utility";
import earwhite from "../../assets/images/ear.svg";
import throatwhite from "../../assets/images/throatwhite.svg";
import dentalwhite from "../../assets/images/dentalwhite.svg";
import lungswhite from "../../assets/images/lungswhite.svg";
import abdomenwhite from "../../assets/images/abdomenwhite.svg";
import skinwhite from "../../assets/images/fingerprintcopy.svg";
import heartwhite from "../../assets/images/heartwhite.svg";
import bhvlogo from "../../assets/images/bhvlogo.svg"
import doctorlogo from "../../assets/images/doctorlogo.svg"
import QRCode from "react-qr-code";

export const VitalsReportSection = (props) => {
  const { data } = props;

  const vitals = data?.vitalSignsAssessment;
  const vitalSign = data?.student?.healthPackage?.vitalSigns;
  const show = Object.keys(vitals).every((x) => vitals[x] === null);
  if (show) {
    return <></>;
  }
  const getBMI = () => {
    const w = data?.vitalSignsAssessment?.weight
    const h = data?.vitalSignsAssessment?.height / 100
    if (w && h && w !== "null" && h !== "null") {
      return (w / (h * h)).toFixed(1)
    }
    else {
      return ""
    }
    console.log(data)
  }

  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={vitalsigns} />
          </div>
          <div>Vital Signs</div>
        </div>

        {vitalSign?.includes("HEIGHT_AND_WEIGHT") && (data?.vitalSignsAssessment?.weight != "null" || data?.vitalSignsAssessment?.height != "null") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>BMI</div>
            </div>
            <div>{getBMI()} kg/m
              <sup className="physical_body_information_container_bottom_sup">
                2
              </sup></div>
            <div>
              <div>Normal: 18.5 kg/m<sup>2</sup> - 24.9 kg/m<sup>2</sup></div>
            </div>
            <div>kg/m<sup>2</sup></div>
            <div>{data?.vitalSignsAssessment?.BMIInterpretation?.length > 0 ?
              data?.vitalSignsAssessment?.BMIInterpretation : "Normal"}</div>
          </div>
        )}

        {vitalSign?.includes("TEMPERATURE") && data?.vitalSignsAssessment?.temperature != "null" && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Temperature</div>
            </div>
            <div>{((data?.vitalSignsAssessment?.temperature * (9 / 5) + 32).toFixed(2))}</div>
            <div>
              <div>Normal: 98.6&deg;F, Fever: &gt;99.5&deg;F</div>
            </div>
            <div>&deg;F</div>
            <div>{data?.vitalSignsAssessment?.temperatureInterpretation?.length > 0 ?
              data?.vitalSignsAssessment?.temperatureInterpretation : "Normal"}</div>
          </div>
        )}

        {vitalSign?.includes("HEART_RATE") && data?.vitalSignsAssessment?.heartRate != "null" && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Heart Rate</div>
            </div>
            <div>{data?.vitalSignsAssessment?.heartRate}</div>
            <div>
              <div>1-3 yrs - 90-150bpm</div>
              <div>3-5yr - 80-140 bpm</div>
              <div>5-12yrs - 70-120bpm</div>
              <div>12+yrs - 60-100bpm</div>
            </div>
            <div>BPM</div>
            <div>{data?.vitalSignsAssessment?.heartRateInterpretation?.length > 0 ?
              data?.vitalSignsAssessment?.heartRateInterpretation : "Normal"}</div>
          </div>
        )}

        {vitalSign?.includes("HEMOGLOBIN") && data?.vitalSignsAssessment?.hemoglobin != "null" && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Hemoglobin</div>
            </div>
            <div>{data?.vitalSignsAssessment?.hemoglobin}</div>
            <div>
              <div>6mnth- 5yr - 12g/dl</div>
              <div>5-12yrs - 13g/dl</div>
              <div>12-14yrs Girl-13g/dl Boy- 13.5 g/dl</div>
              <div>14-19yrs. Girl- 13 g/dl. Boy- 15g/dl</div>
            </div>
            <div>g/dl</div>
            <div>{data?.vitalSignsAssessment?.hemoglobinInterpretation?.length > 0 ?
              data?.vitalSignsAssessment?.hemoglobinInterpretation : "Normal"}</div>
          </div>
        )}

        {vitalSign?.includes("BLOOD_PRESSURE") && data?.vitalSignsAssessment?.bloodPressure != "null" && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Blood Pressure</div>
            </div>
            <div>{data?.vitalSignsAssessment?.bloodPressure}</div>
            <div>
              <div>Age 10+yrs: 109/72</div>
            </div>
            <div>mm/Hg</div>
            <div>{data?.vitalSignsAssessment?.bloodPressureInterpretation?.length > 0 ?
              data?.vitalSignsAssessment?.bloodPressureInterpretation : "Normal"}</div>
          </div>
        )}

        {vitalSign?.includes("SPO2") && data?.vitalSignsAssessment?.SpO2 != "null" && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>SPO2</div>
            </div>
            <div>{data?.vitalSignsAssessment?.SpO2}</div>
            <div>
              <div>Normal SpO2: 95-100%</div>
            </div>
            <div>%</div>
            <div>{data?.vitalSignsAssessment?.SpO2Interpretation?.length > 0 ? data?.vitalSignsAssessment?.SpO2Interpretation : "Normal"}</div>
          </div>
        )}
        <div class="screening_method">
          <p>METHOD : Pyroelectric sensor, Pulse oximeters</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
      </div>
    </>
  );
};

export const HairReportSection = (props) => {
  const { data } = props;
  const hair = data?.student?.healthPackage?.hair;
  const show = Object.keys(hair).every((x) => hair[x] === null || hair[x] === "");
  if (show) {
    return <></>;
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={hairwhite} />
          </div>
          <div>Hair</div>
        </div>

        {hair?.includes("COLOR_OF_HAIR") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Color of Hair</div>
            </div>
            <div>{data?.hairAssessment?.color}</div>
            <div>
              <div>Black, Grey, White, Red</div>
            </div>
            <div>-</div>
            <div>{data?.hairAssessment?.color !== "black" ? "Abnormal" : "Normal"}</div>
          </div>
        )}

        {hair?.includes("DANDRUFF") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Dandruff</div>
            </div>
            <div style={checkReportCondition(data?.hairAssessment?.dandruff) === "Present" ? { fontWeight: 600 } : {}}>{checkReportCondition(data?.hairAssessment?.dandruff)}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{data?.hairAssessment?.dandruffInterpretation?.length > 0 ?
              data?.hairAssessment?.dandruffInterpretation : (data?.hairAssessment?.dandruff ? "Abnormal" : "Normal")}</div>
          </div>
        )}

        {hair?.includes("PEDICULOSIS_(LOUSE/LICE)") !== null && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Pediculosis</div>
            </div>
            <div style={checkReportCondition(data?.hairAssessment?.pediculosis) === "Present" ? { fontWeight: 600 } : {}}>{checkReportCondition(data?.hairAssessment?.pediculosis)}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{data?.hairAssessment?.pediculosisInterpretation?.length > 0 ?
              data?.hairAssessment?.pediculosisInterpretation : (data?.hairAssessment?.pediculosis ? "Abnormal" : "Normal")}</div>
          </div>
        )}

        {hair?.includes("LOSS_OF_HAIR") !== null && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Loss of Hair</div>
            </div>
            <div style={checkReportCondition(data?.hairAssessment?.lossOfHair) === "Present" ? { fontWeight: 600 } : {}}>{checkReportCondition(data?.hairAssessment?.lossOfHair)}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{data?.hairAssessment?.lossOfHairInterpretation?.length > 0 ?
              data?.hairAssessment?.lossOfHairInterpretation : (data?.hairAssessment?.lossOfHair ? "Abnormal" : "Normal")}</div>
          </div>
        )}
        <div class="screening_method">
          <p>METHOD : Visual Screening</p>
        </div>
      </div>
    </>
  );
};

export const EyeReportSection = (props) => {
  const { data } = props;
  const eye = data?.student?.healthPackage?.eye;
  const show = Object.keys(eye).every((x) => eye[x] === null);
  if (show) {
    return <></>;
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <VisibilityIcon
              sx={{ fill: "#fff", width: "16.62px", height: "12.08px" }}
            />
          </div>
          <div>Eye</div>
        </div>

        {eye?.includes("MYOPIA") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Myopia</div>
            </div>
            <div style={findValidation(data?.eyeAssessment?.validation, "Myopia") === "Present" ? { fontWeight: 600 } : {}}>{findValidation(data?.eyeAssessment?.validation, "Myopia")}</div>
            <div>
              <div>Mild: 0D to -1.5D</div>
              <div>Moderate: -1.5D to -6.0D</div>
              <div>High: &gt;-6.0D</div>
              <div>Pathological: &gt;-8.0D</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.eyeAssessment?.validation, "Myopia")}</div>
          </div>
        )}

        {eye?.includes("HYPEROPIA") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Hypermyopia</div>
            </div>
            <div style={findValidation(data?.eyeAssessment?.validation, "Hyperopia") === "Present" ? { fontWeight: 600 } : {}}>{findValidation(data?.eyeAssessment?.validation, "Hyperopia")}</div>
            <div>
              <div>Low: +2.00D</div>
              <div>Moderate: +2.25D to +5.0D</div>
              <div>High: +5.25D or more</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.eyeAssessment?.validation, "Hyperopia")}</div>
          </div>
        )}

        {eye?.includes("ASTIGMATISM") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Astigmatism</div>
            </div>
            <div style={findValidation(data?.eyeAssessment?.validation, "Astigmatism") === "Present" ? { fontWeight: 600 } : {}}>{findValidation(data?.eyeAssessment?.validation, "Astigmatism")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.eyeAssessment?.validation, "Astigmatism")}</div>
          </div>
        )}

        {eye?.includes("GAZE_ASYMMETRY") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Gaze Asymmetry</div>
            </div>
            <div style={findValidation(data?.eyeAssessment?.validation, "Strabismus") === "Present" ? { fontWeight: 600 } : {}}>{findValidation(data?.eyeAssessment?.validation, "Strabismus")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.eyeAssessment?.validation, "Strabismus")}</div>
          </div>
        )}
        {eye?.includes("ANISOCORIA") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Anisocoria</div>
            </div>
            <div style={findValidation(data?.eyeAssessment?.validation, "Aniscoria") === "Present" ? { fontWeight: 600 } : {}}>{findValidation(data?.eyeAssessment?.validation, "Aniscoria")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.eyeAssessment?.validation, "Aniscoria")}</div>
          </div>
        )}
        {eye?.includes("ANISOMETROPIA") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Anisometropia</div>
            </div>
            <div style={findValidation(data?.eyeAssessment?.validation, "Anisometropia") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.eyeAssessment?.validation, "Anisometropia")}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.eyeAssessment?.validation, "Anisometropia")}</div>
          </div>
        )}
        <div class="screening_method">
          <p>
            METHOD : Imaging & Video Technology{" "}
            <span
              style={{
                marginLeft: "45px",
                color: "#66707E",
                fontWeight: "500",
                fontStyle: "italic",
                fontSize: "6px",
              }}
            >
              check termanology for method from hillrom website
            </span>
          </p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
            {data?.qrCode && (
              <QRCode value={data?.qrCode} size={30}/>
            )}
          </div>
          <div>
            <div>Scan here</div>
            <div>to look at your kids eye screening images</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const LeftEarReportSection = (props) => {
  const { data } = props;
  const ear = data?.student?.healthPackage?.ear;
  ;
  // const show = Object.keys(ear).every((x) => ear[x] === null);
  let show = false
  if (show) {
    return <></>;
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={earwhite} />
          </div>
          <div>Left Ear</div>
        </div>

        {ear?.includes("OTITIS_MEDIA") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Otitis Media</div>
            </div>
            <div style={findValidation(data?.earAssessment?.leftEarValidation, "Otisis Media") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.earAssessment?.leftEarValidation, "Otisis Media")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.earAssessment?.leftEarValidation, "Otisis Media")}</div>
          </div>
        )}

        {ear?.includes("OTITIS_EXTERNA") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Otitis Externa</div>
            </div>
            <div style={findValidation(data?.earAssessment?.leftEarValidation, "Otisis Externa") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.earAssessment?.leftEarValidation, "Otisis Externa")}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.earAssessment?.leftEarValidation, "Otisis Externa")}</div>
          </div>
        )}

        {ear?.includes("EAR_WAX") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Ear Wax</div>
            </div>
            <div style={findValidation(data?.earAssessment?.leftEarValidation, "Ear Wax") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.earAssessment?.leftEarValidation, "Ear Wax")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.earAssessment?.leftEarValidation, "Ear Wax")}</div>
          </div>
        )}

        {ear?.includes("FOREIGN_BODY_IN_CANAL") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div style={{ textAlign: "left" }}>Foreign Body in Ear Canal</div>
            </div>
            <div style={findValidation(data?.earAssessment?.leftEarValidation, "Foreign Body in Ear canal") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.earAssessment?.leftEarValidation, "Foreign Body in Ear canal")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.earAssessment?.leftEarValidation, "Foreign Body in Ear canal")}</div>
          </div>
        )}
        {/* {ear?.hearing !== null && <div class="screening_row">
                    <div>
                        <div></div>
                        <div>Hearing</div>
                    </div>
                    <div>{checkReportCondition(data?.earAssessment?.hearing)}</div>
                    <div>
                        <div>Normal Hearing: 0-20dB</div>
                        <div>Slight Loss: 16-25dB</div>
                        <div>Mild Loss: 26-40dB</div>
                        <div>Moderate Loss: 41-69dB</div>
                        <div>Severe Loss: 70-89dB</div>
                        <div>Profound Loss: &gt;90dB</div>
                    </div>
                    <div>dB</div>
                    <div>Normal</div>
                </div>} */}
        <div class="screening_method">
          <p>METHOD : Imaging & Video Technology</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
            {data?.qrCode && (
              <QRCode value={data?.qrCode} size={30}/>
            )}
          </div>
          <div>
            <div>Scan here</div>
            <div>to look at your kids ear screening images and videos</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const RightEarReportSection = (props) => {
  const { data } = props;
  const ear = data?.student?.healthPackage?.ear;
  // const show = Object.keys(ear).every((x) => ear[x] === null);
  let show = false
  if (show) {
    return <></>;
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={earwhite} />
          </div>
          <div>Right Ear</div>
        </div>

        {ear?.includes("OTITIS_MEDIA") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Otitis Media</div>
            </div>
            <div style={findValidation(data?.earAssessment?.rightEarValidation, "Otisis Media") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.earAssessment?.rightEarValidation, "Otisis Media")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.earAssessment?.rightEarValidation, "Otisis Media")}</div>
          </div>
        )}

        {ear?.includes("OTITIS_EXTERNA") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Otitis Externa</div>
            </div>
            <div style={findValidation(data?.earAssessment?.rightEarValidation, "Otisis Externa") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.earAssessment?.rightEarValidation, "Otisis Externa")}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.earAssessment?.rightEarValidation, "Otisis Externa")}</div>
          </div>
        )}

        {ear?.includes("EAR_WAX") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Ear Wax</div>
            </div>
            <div style={findValidation(data?.earAssessment?.rightEarValidation, "Ear Wax") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.earAssessment?.rightEarValidation, "Ear Wax")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.earAssessment?.rightEarValidation, "Ear Wax")}</div>
          </div>
        )}

        {ear?.includes("FOREIGN_BODY_IN_CANAL") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div style={{ textAlign: "left" }}>Foreign Body in Ear Canal</div>
            </div>
            <div style={findValidation(data?.earAssessment?.rightEarValidation, "Foreign Body in Ear canal") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.earAssessment?.rightEarValidation, "Foreign Body in Ear canal")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.earAssessment?.rightEarValidation, "Foreign Body in Ear canal")}</div>
          </div>
        )}
        {/* {ear?.hearing !== null && <div class="screening_row">
                  <div>
                      <div></div>
                      <div>Hearing</div>
                  </div>
                  <div>{checkReportCondition(data?.earAssessment?.hearing)}</div>
                  <div>
                      <div>Normal Hearing: 0-20dB</div>
                      <div>Slight Loss: 16-25dB</div>
                      <div>Mild Loss: 26-40dB</div>
                      <div>Moderate Loss: 41-69dB</div>
                      <div>Severe Loss: 70-89dB</div>
                      <div>Profound Loss: &gt;90dB</div>
                  </div>
                  <div>dB</div>
                  <div>Normal</div>
              </div>} */}
        <div class="screening_method">
          <p>METHOD : Imaging & Video Technology</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
        <div>
          {data?.qrCode && (
            <QRCode value={data?.qrCode} size={30}/>
          )}
        </div>
        <div>
          <div>Scan here</div>
          <div>to look at your kids ear screening images and videos</div>
        </div>
      </div> */}
      </div>
    </>
  );
};

export const MouthThroatReportSection = (props) => {
  const { data } = props;
  const throat = data?.student?.healthPackage?.throat;
  // const show = Object.keys(throat).every((x) => throat[x] === null);
  let show = false
  if (show) {
    return <></>;
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={throatwhite} />
          </div>
          <div>Mouth/Throat</div>
        </div>

        {throat?.includes("STOMATITIS") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Stomatitis</div>
            </div>
            <div style={findValidation(data?.throatAssessment?.validation, "Stomatitis") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.throatAssessment?.validation, "Stomatitis")}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.throatAssessment?.validation, "Stomatitis")}</div>
          </div>
        )}

        {throat?.includes("GLOSSITIS") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Glossitis</div>
            </div>
            <div style={findValidation(data?.throatAssessment?.validation, "Glossitis") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.throatAssessment?.validation, "Glossitis")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.throatAssessment?.validation, "Glossitis")}</div>
          </div>
        )}

        {throat?.includes("PHARYNGITIS") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Pharyngitis</div>
            </div>
            <div style={findValidation(data?.throatAssessment?.validation, "Pharyngitis") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.throatAssessment?.validation, "Pharyngitis")}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.throatAssessment?.validation, "Pharyngitis")}</div>
          </div>
        )}

        {throat?.includes("TONSILLITIS") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Tonsilitis</div>
            </div>
            <div style={findValidation(data?.throatAssessment?.validation, "Tonsilitis") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.throatAssessment?.validation, "Tonsilitis")}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.throatAssessment?.validation, "Tonsilitis")}</div>
          </div>
        )}
        {throat?.includes("WHITE_PATCHES") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>White Patches</div>
            </div>
            <div style={findValidation(data?.throatAssessment?.validation, "White Patches") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.throatAssessment?.validation, "White Patches")}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.throatAssessment?.validation, "White Patches")}</div>
          </div>
        )}
        {throat?.includes("THROAT_CONGESTION") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Throat Congestion</div>
            </div>
            <div style={findValidation(data?.throatAssessment?.validation, "Throat Congestion") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.throatAssessment?.validation, "Throat Congestion")}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.throatAssessment?.validation, "Throat Congestion")}</div>
          </div>
        )}
        <div class="screening_method">
          <p>METHOD : Imaging & Video Technology</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>to look at your kids throat screening images and videos</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const HeartReportSection = (props) => {
  const { data } = props;
  const heart = data?.student?.healthPackage?.heart;
  // const show = Object.keys(heart).every((x) => heart[x] === null);
  let show = false
  if (show) {
    return <></>;
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={heartwhite} />
          </div>
          <div>Heart</div>
        </div>
        {heart?.includes("HEART_MURMUR") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Heart Murmurs</div>
            </div>
            <div style={findValidation(data?.heartAssessment?.auscultationValidation, "Heart Murmur") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.heartAssessment?.auscultationValidation, "Heart Murmur")}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.heartAssessment?.auscultationValidation, "Heart Murmur")}</div>
          </div>
        )}
        {heart?.includes("ARRHYTHMIA") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Arrhythmia</div>
            </div>
            <div style={findValidation(data?.heartAssessment?.auscultationValidation, "Arrhythmia") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.heartAssessment?.auscultationValidation, "Arrhythmia")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.heartAssessment?.auscultationValidation, "Arrhythmia")}</div>
          </div>
        )}
        {heart?.includes("ABNORMAL_HEART_SOUND") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Abnormal Heart Sound</div>
            </div>
            <div style={findValidation(data?.heartAssessment?.auscultationValidation, "Abnormal Heart Sound") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.heartAssessment?.auscultationValidation, "Abnormal Heart Sound")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.heartAssessment?.auscultationValidation, "Abnormal Heart Sound")}</div>
          </div>
        )}
        {heart?.includes("RHYTHM_DISORDER") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Rhythm Disorder</div>
            </div>
            <div style={findValidation(data?.heartAssessment?.auscultationValidation, "Rhythm Disorder") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.heartAssessment?.auscultationValidation, "Rhythm Disorder")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.heartAssessment?.auscultationValidation, "Rhythm Disorder")}</div>
          </div>
        )}
        <div class="screening_method">
          <p>METHOD: Auscultation Technology through AI</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>to listen your kids heart auscultations</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const DentalReportSection = (props) => {
  const { data } = props;
  const dental = data?.dentalAssessment;
  // const show = Object.keys(dental).every((x) => dental[x] === null);
  const getDentalStatus = (x) => {
    if (data.student.package == "BASIC") {
      if (x === "D") {
        return data.dentalAssessment.cariesDecay
      }
      else if (x === "M") {
        return data.dentalAssessment.missedTooth
      }
      else if (x === "F") {
        return data.dentalAssessment.fillingPresent
      }
    }
    else {
      let a = data?.dentalAssessment?.tooth[0].split(",")
      for (let i = 0; i < a.length; i++) {
        if (a[i]?.split("_")[1] === x) {
          return true
        }
      }
      return false
    }
  }

  let show = false
  if (show) {
    return <></>;
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={dentalwhite} />
          </div>
          <div>Dental</div>
        </div>

        {dental?.tooth !== null && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Decayed</div>
            </div>
            <div style={data?.dentalAssessment?.tooth &&
              getDentalStatus("D")
              ? { fontWeight: 600 }
              : {}}>
              {data?.dentalAssessment?.tooth &&
                getDentalStatus("D")
                ? "Present"
                : "Absent"}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{getDentalStatus("D") ? "Decayed Teeth" : "Normal"}</div>
          </div>
        )}

        {dental?.tooth !== null && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Filled</div>
            </div>
            <div style={data?.dentalAssessment?.tooth &&
              getDentalStatus("F")
              ? { fontWeight: 600 }
              : {}}>
              {data?.dentalAssessment?.tooth &&
                getDentalStatus("F")
                ? "Present"
                : "Absent"}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{getDentalStatus("F") ? "Filled Teeth" : "Normal"}</div>
          </div>
        )}

        {dental?.tooth !== null && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Missing</div>
            </div>
            <div style={data?.dentalAssessment?.tooth &&
              getDentalStatus("M")
              ? { fontWeight: 600 }
              : {}}>
              {data?.dentalAssessment?.tooth &&
                getDentalStatus("M")
                ? "Present"
                : "Absent"}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{getDentalStatus("M") ? "Missing Teeth" : "Normal"}</div>
          </div>
        )}

        {dental?.gingivitis !== null && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Gingivitis</div>
            </div>
            <div style={checkReportCondition(data?.dentalAssessment?.gingivitis) === "Present" ? { fontWeight: 600 } : {}}>
              {checkReportCondition(data?.dentalAssessment?.gingivitis)}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{data?.dentalAssessment?.gingivitis ? "Gingivitis Present" : "Normal"}</div>
          </div>
        )}
        {dental?.orthodontic !== null && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Orthodontic Problem</div>
            </div>
            <div style={checkReportCondition(data?.dentalAssessment?.orthodontic) === "Present" ? { fontWeight: 600 } : {}}>
              {checkReportCondition(data?.dentalAssessment?.orthodontic)}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{data?.dentalAssessment?.orthodontic ? "Orthodontic Problem Present" : "Normal"}</div>
          </div>
        )}
        {dental?.decoloration !== null && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Decoloration</div>
            </div>
            <div style={checkReportCondition(data?.dentalAssessment?.decoloration) === "Present" ? { fontWeight: 600 } : {}}>
              {checkReportCondition(data?.dentalAssessment?.decoloration)}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{data?.dentalAssessment?.decoloration ? "Decoloration Present" : "Normal"}</div>
          </div>
        )}
        {dental?.poorOralHygiene !== null && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Poor Oral Hygiene</div>
            </div>
            <div style={checkReportCondition(data?.dentalAssessment?.poorOralHygiene) === "Present" ? { fontWeight: 600 } : {}}>
              {checkReportCondition(data?.dentalAssessment?.poorOralHygiene)}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{data?.dentalAssessment?.poorOralHygiene ? "Poor Oral Hygiene Present" : "Normal"}</div>
          </div>
        )}
        <div class="screening_method">
          <p>METHOD : Visual Screening</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>to look at your kids dental layout</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const LungsReportSection = (props) => {
  const { data } = props;
  const lungs = data?.student?.healthPackage?.lungs;

  let show = false
  if (show) {
    return <></>;
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={lungswhite} />
          </div>
          <div>Lungs</div>
        </div>

        {lungs?.includes("RESPIRATORY_SOUNDS") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Respiratory Sound</div>
            </div>
            <div style={findValidation(data?.lungsAssessment?.auscultationValidation, "Respiratory Sounds") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.lungsAssessment?.auscultationValidation, "Respiratory Sounds")}
            </div>
            <div>
              <div>Normal/Abnormal</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.lungsAssessment?.auscultationValidation, "Respiratory Sounds")}</div>
          </div>
        )}

        {lungs?.includes("DYSPNEA") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Dyspnea</div>
            </div>
            <div style={findValidation(data?.lungsAssessment?.auscultationValidation, "Dyspnea") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.lungsAssessment?.auscultationValidation, "Dyspnea")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.lungsAssessment?.auscultationValidation, "Dyspnea")}</div>
          </div>
        )}

        {lungs?.includes("WHEEZING") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Wheezing</div>
            </div>
            <div style={findValidation(data?.lungsAssessment?.auscultationValidation, "Wheezing") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.lungsAssessment?.auscultationValidation, "Wheezing")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.lungsAssessment?.auscultationValidation, "Wheezing")}</div>
          </div>
        )}

        {lungs?.includes("RATTLING") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Rattling</div>
            </div>
            <div style={findValidation(data?.lungsAssessment?.auscultationValidation, "Rattling") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.lungsAssessment?.auscultationValidation, "Rattling")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.lungsAssessment?.auscultationValidation, "Rattling")}</div>
          </div>
        )}
        {lungs?.includes("COUGH") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Cough</div>
            </div>
            <div style={data?.coughAssessment?.validation?.length > 0 ? { fontWeight: 600 } : {}}>
              {data?.coughAssessment?.validation?.length > 0 ? "Present" : "Absent"}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.coughAssessment?.validation, "Present")}</div>
          </div>
        )}
        <div class="screening_method">
          <p>METHOD : Auscultation Technology through AI</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>to listen your kids lungs auscultations</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const AbdomenReportSection = (props) => {
  const { data } = props;
  const abdomen = data?.student?.healthPackage?.abdomen;
  let show = false
  if (show) {
    return <></>;
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={abdomenwhite} />
          </div>
          <div>Abdomen</div>
        </div>
        {abdomen?.includes("BOWEL_OBSTRUCTION") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Bowel Obstruction</div>
            </div>
            <div style={findValidation(data?.abdomenAssessment?.validation, "Bowel Obstruction") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.abdomenAssessment?.validation, "Bowel Obstruction")}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.abdomenAssessment?.validation, "Bowel Obstruction")}</div>
          </div>
        )}
        {abdomen?.includes("ABDOMINAL_BRUIT") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Abdominal Bruit</div>
            </div>
            <div style={findValidation(data?.abdomenAssessment?.validation, "Abdominal Bruit") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.abdomenAssessment?.validation, "Abdominal Bruit")}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.abdomenAssessment?.validation, "Abdominal Bruit")}</div>
          </div>
        )}
        {abdomen?.includes("PARALYTIC_ILEUS") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Paralytic Ileus</div>
            </div>
            <div style={findValidation(data?.abdomenAssessment?.validation, "Paralytic Ileus") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.abdomenAssessment?.validation, "Paralytic Ileus")}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.abdomenAssessment?.validation, "Paralytic Ileus")}</div>
          </div>
        )}
        {abdomen?.includes("INTESTINAL_SOUNDS") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Intestinal Sound</div>
            </div>
            <div style={findValidation(data?.abdomenAssessment?.validation, "Intestinal Sounds") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.abdomenAssessment?.validation, "Intestinal Sounds")}
            </div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.abdomenAssessment?.validation, "Intestinal Sounds")}</div>
          </div>
        )}
        <div class="screening_method">
          <p>METHOD : Auscultation Technology through AI</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>to listen your kids abdomen auscultations</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const SkinReportSection = (props) => {
  const { data } = props;
  const skin = data?.student?.healthPackage?.skin;
  // const show = Object.keys(skin).every((x) => skin[x] === null);
  let show = false
  if (show) {
    return <></>;
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={skinwhite} />
          </div>
          <div>Skin</div>
        </div>

        {skin?.includes("SCABIES") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Scabies</div>
            </div>
            <div style={findValidation(data?.skinAssessment?.validation, "Scabies") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.skinAssessment?.validation, "Scabies")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.skinAssessment?.validation, "Scabies")}</div>
          </div>
        )}

        {skin?.includes("RASHES") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Rashes</div>
            </div>
            <div style={findValidation(data?.skinAssessment?.validation, "Rashes") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.skinAssessment?.validation, "Rashes")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.skinAssessment?.validation, "Rashes")}</div>
          </div>
        )}

        {skin?.includes("BLISTERS") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Blisters</div>
            </div>
            <div style={findValidation(data?.skinAssessment?.validation, "Blisters") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.skinAssessment?.validation, "Blisters")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.skinAssessment?.validation, "Blisters")}</div>
          </div>
        )}

        {skin?.includes("URTICARIA") && (
          <div class="screening_row">
            <div>
              <div></div>
              <div>Urticaria</div>
            </div>
            <div style={findValidation(data?.skinAssessment?.validation, "Urticaria") === "Present" ? { fontWeight: 600 } : {}}>
              {findValidation(data?.skinAssessment?.validation, "Urticaria")}</div>
            <div>
              <div>Present/Absent</div>
            </div>
            <div>-</div>
            <div>{findInterpetetion(data?.skinAssessment?.validation, "Urticaria")}</div>
          </div>
        )}
        <div class="screening_method">
          <p>METHOD : Auscultation Technology through AI</p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>          
          </div>
          <div>
            <div>Scan here</div>
            <div>to look at your kids skin screening images and videos</div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const BehavioralReportSection = (props) => {
  const { data } = props;
  const behave = data?.behavioralAssessment;
  const show = data?.ADHD?.score || data?.ANXIETY?.score || data?.AUTISM?.score || data?.CONDUCT_DISORDER?.score || data?.DEPRESSION?.score || data?.DEVELOPMENTAL_DELAY?.score || data?.INTERNET_ADDICTION?.score || data?.ODD?.score

  if (!show) {
    return <></>;
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={bhvlogo} />
          </div>
          <div>Behavioral Assessment</div>
        </div>

        {data?.INTERNET_ADDICTION?.outcome && <div class="screening_row">
          <div>
            <div></div>
            <div>Internet Addiction</div>
          </div>
          <div>
          </div>
          <div>
            <div>Risk indicated/ No risk indicated</div>
          </div>
          <div>-</div>
          <div>{data?.INTERNET_ADDICTION?.outcome}</div>
        </div>}

        {data?.ADHD?.outcome && <div class="screening_row">
          <div>
            <div></div>
            <div>ADHD</div>
          </div>
          <div></div>
          <div>
            <div>Predominantly inattentive/</div>
            <div>Predominantly hyperactive/</div>
            <div>impulsive/ No risk indicated</div>
          </div>
          <div>-</div>
          <div>{data?.ADHD?.outcome}</div>
        </div>}

        {data?.ANXIETY?.outcome && <div class="screening_row">
          <div>
            <div></div>
            <div>Anxiety</div>
          </div>
          <div></div>
          <div>
            <div>Risk indicated/ No risk indicated</div>
          </div>
          <div>-</div>
          <div>{data?.ANXIETY?.outcome?.map((x) => {
            return x
          })}</div>
        </div>}

        {data?.DEPRESSION?.outcome && <div class="screening_row">
          <div>
            <div></div>
            <div>Depression</div>
          </div>
          <div></div>
          <div>
            <div>Risk indicated/ No risk indicated</div>
          </div>
          <div>-</div>
          <div>{data?.DEPRESSION?.outcome}</div>
        </div>}

        {data?.CONDUCT_DISORDER?.outcome && <div class="screening_row">
          <div>
            <div></div>
            <div>Conduct Disorder</div>
          </div>
          <div>
          </div>
          <div>
            <div>Risk indicated/ No risk indicated</div>
          </div>
          <div>-</div>
          <div>{data?.CONDUCT_DISORDER?.outcome}</div>
        </div>}
        {data?.ODD?.outcome && <div class="screening_row">
          <div>
            <div></div>
            <div>Opp. Defiant Disorder</div>
          </div>
          <div>
          </div>
          <div>
            <div>Risk indicated/ No risk indicated</div>
          </div>
          <div>-</div>
          <div>{data?.ODD?.outcome}</div>
        </div>}
        {data?.AUTISM?.outcome && <div class="screening_row">
          <div>
            <div></div>
            <div>Autism</div>
          </div>
          <div></div>
          <div>
            <div>Risk indicated/ No risk indicated</div>
          </div>
          <div>-</div>
          <div>{data?.AUTISM?.outcome}</div>
        </div>}
        <div class="screening_method">
          <p>
            METHOD : Frameworks approved by AAP (American Academy of Pediatrics)
          </p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>
              to look at your kids behavioural assessment detailed reports
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const LearningReportSection = (props) => {
  const { data } = props;
  const learning = data?.DYSLEXIA_1?.outcome || data?.DYSCALCULIA_1?.outcome || data?.DYSGRAPHIA_1?.outcome
  if (!learning) {
    return <></>
  }
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={bhvlogo} />
          </div>
          <div>Learning Disabilities</div>
        </div>
        {data?.DYSLEXIA_1?.outcome && <div class="screening_row">
          <div>
            <div></div>
            <div>Dyslexia</div>
          </div>
          <div></div>
          <div>
            <div>Risk indicated/ No risk indicated</div>
          </div>
          <div>-</div>
          <div>{data?.DYSLEXIA_2?.outcome ?? data?.DYSLEXIA_1?.outcome}</div>
        </div>}
        {data?.DYSGRAPHIA_1?.outcome && <div class="screening_row">
          <div>
            <div></div>
            <div>Dysgraphia</div>
          </div>
          <div></div>
          <div>
            <div>Risk indicated/ No risk indicated</div>
          </div>
          <div>-</div>
          <div>{data?.DYSGRAPHIA_2?.outcome ?? data?.DYSGRAPHIA_1?.outcome}</div>
        </div>}
        {data?.DYSCALCULIA_1?.outcome && <div class="screening_row">
          <div>
            <div></div>
            <div>Dyscalculia</div>
          </div>
          <div>
          </div>
          <div>
            <div>Risk indicated/ No risk indicated</div>
          </div>
          <div>oF</div>
          <div>{data?.DYSCALCULIA_2?.outcome ?? data?.DYSCALCULIA_1?.outcome}</div>
        </div>}
        <div class="screening_method">
          <p>
            METHOD : Frameworks approved by AAP (American Academy of Pediatrics)
          </p>
          <p>ACCREDIATION: FDA Approved</p>
        </div>
        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>
              to look at your kids learning disabilities detailed reports
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const DoctorSection = (props) => {
  const { data } = props;
  return (
    <>
      <div class="screening_container">
        <div class="screening_container_header">
          <div>
            <img src={doctorlogo} />
          </div>
          <div>Our Doctors</div>
        </div>

        <div class="screening_row">
          <div>
            <div></div>
            <div>Dr. Tanuja Mishra</div>
          </div>
          <div>M.B.B.S, M.D</div>
        </div>

        <div class="screening_row">
          <div>
            <div></div>
            <div>Ms. Ashwathi Prabhu</div>
          </div>
          <div>Psychologist</div>
        </div>

        {/* <div className="qrcode_container">
          <div>
          <QRCode value={data?.qrCode} size={30}/>
          </div>
          <div>
            <div>Scan here</div>
            <div>
              to see the profile of doctor who validated data of your kid
              screening
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export const EndOfReport = () => {
  return (
    <>
      <div className="horizontal_line"></div>
      <div className="note_container">
        <p>Note</p>
        <p>1. Results to be clinically correlated.</p>
        <p>
          2. Post test counseling available between 9.00 am to 5:00 pm at SKIDS
          clinic
        </p>
      </div>
      <div className="information_container">
        <p>Important Instructions</p>
        <p>
          Test results released pertain to the screening done. All test results
          are dependent on the quality of the sample received by us. Clinic
          investigations are only a tool to facilitate in arriving at a
          diagnosis and should be clinically correlated by the Referring
          Physician. Report delivery may be delayed due to unforeseen
          circumstances. Test results may show interlaboratory variations. Test
          results are not valid for medico legal purposes.
        </p>
      </div>
      <div className="end_report_container">
        <p>**End Of Report**</p>
        <p>
          Please visit{" "}
          <a href="https://www.skids.health" target="_blank">
            www.skids.health
          </a>{" "}
          for more related Information
        </p>
      </div>
    </>
  );
};
