import React from "react";
import classes from "./DevelopmentMilestones.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import { getAge, stringAvatar, textFormat } from "../../../utils/utility";
import { Avatar } from "@mui/material";
import highrisk from "../../../assets/images/highRisk.svg";
import psychologist from "../../../assets/images/psychologist.svg";
import moment from "moment";
import clsx from "clsx";
import tufts from "../../../assets/images/tufts.svg";
import swyc from "../../../assets/images/swyc.svg";



export const DevelopmentMilestones = ({ data }) => {

  let questionBank = [];

  const response = JSON.parse(data?.DEVELOPMENTAL_DELAY)?.response
  const emoResponse = JSON.parse(data?.EMOTIONAL_AND_BEHAVIORAL)?.response

  if (Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) >= 1 &&
    Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) <= 3) {
    questionBank = [
      {
        qno: 1,
        question: "Makes sounds that let you know he or she is happy or upset",
        answer: textFormat(response?.[0])
      },
      {
        qno: 2,
        question: "Seems happy to see you",
        answer: textFormat(response?.[1])

      },
      {
        qno: 3,
        question: "Follows a moving toy with his or her eyes",
        answer: textFormat(response?.[2])
      },
      {
        qno: 4,
        question: "Turns head to find the person who is talking",
        answer: textFormat(response?.[3])
      },
      {
        qno: 5,
        question: "Holds head steady when being pulled up to a sitting position",
        answer: textFormat(response?.[4])
      },
      {
        qno: 6,
        question: "Brings hands together",
        answer: textFormat(response?.[5])
      },
      {
        qno: 7,
        question: `Laughs`,
        answer: textFormat(response?.[6])
      },
      {
        qno: 8,
        question: "Keeps head steady when held in a sitting position",
        answer: textFormat(response?.[7])
      },
      {
        qno: 9,
        question: `Makes sounds like "ga," "ma," or "ba"`,
        answer: textFormat(response?.[8])
      },
      {
        qno: 10,
        question: "Looks when you call his or her name",
        answer: textFormat(response?.[9])
      },

    ]
  } else if (Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) >= 4 &&
    Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) <= 5) {
    questionBank = [
      {
        qno: 1,
        question: "Holds head steady when being pulled up to a sitting position",
        answer: textFormat(response?.[0])
      },
      {
        qno: 2,
        question: "Brings hands together",
        answer: textFormat(response?.[1])

      },
      {
        qno: 3,
        question: "Laughs",
        answer: textFormat(response?.[2])
      },
      {
        qno: 4,
        question: "Keeps head steady when held in a sitting position",
        answer: textFormat(response?.[3])
      },
      {
        qno: 5,
        question: `Makes sounds like "ga," "ma," or "ba"`,
        answer: textFormat(response?.[4])
      },
      {
        qno: 6,
        question: "Looks when you call his or her name",
        answer: textFormat(response?.[5])
      },
      {
        qno: 7,
        question: `Rolls over`,
        answer: textFormat(response?.[6])
      },
      {
        qno: 8,
        question: "Passes a toy from one hand to the other",
        answer: textFormat(response?.[7])
      },
      {
        qno: 9,
        question: `Looks for you or another caregiver when upset`,
        answer: textFormat(response?.[8])
      },
      {
        qno: 10,
        question: "Holds two objects and bangs them together",
        answer: textFormat(response?.[9])
      },

    ]
  } else if (Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) >= 6 &&
    Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) <= 8) {
    questionBank = [
      {
        qno: 1,
        question: `Makes sounds like "ga," "ma," or "ba"`,
        answer: textFormat(response?.[0])
      },
      {
        qno: 2,
        question: "Looks when you call his or her name",
        answer: textFormat(response?.[1])
      },
      {
        qno: 3,
        question: "Rolls over",
        answer: textFormat(response?.[2])
      },
      {
        qno: 4,
        question: "Passes a toy from one hand to the other",
        answer: textFormat(response?.[3])
      },
      {
        qno: 5,
        question: "Looks for you or another caregiver when upset",
        answer: textFormat(response?.[4])
      },
      {
        qno: 6,
        question: "Holds two objects and bangs them together",
        answer: textFormat(response?.[5])
      },
      {
        qno: 7,
        question: "Holds up arms to be picked up",
        answer: textFormat(response?.[6])
      },
      {
        qno: 8,
        question: "Gets into a sitting position by him or herself",
        answer: textFormat(response?.[7])
      },
      {
        qno: 9,
        question: "Picks up food and eats it",
        answer: textFormat(response?.[8])
      },
      {
        qno: 10,
        question: "Pulls up to standing",
        answer: textFormat(response?.[9])
      },

    ]
  } else if (Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) >= 9 &&
    Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) <= 11) {
    questionBank = [
      {
        qno: 1,
        question: "Holds up arms to be picked up",
        answer: textFormat(response?.[0])
      },
      {
        qno: 2,
        question: "Gets into a sitting position by him or herself",
        answer: textFormat(response?.[1])
      },
      {
        qno: 3,
        question: "Picks up food and eats it",
        answer: textFormat(response?.[2])
      },
      {
        qno: 4,
        question: "Pulls up to standing",
        answer: textFormat(response?.[3])
      },
      {
        qno: 5,
        question: `Plays games like "peek" a "boo" or "pat" a "cake"`,
        answer: textFormat(response?.[4])
      },
      {
        qno: 6,
        question: `Calls you "mama" or "dada" or similar name`,
        answer: textFormat(response?.[5])
      },
      {
        qno: 7,
        question: `Looks around when you say things like "Where's your bottle?" or "Where's your blanket?"`,
        answer: textFormat(response?.[6])
      },
      {
        qno: 8,
        question: "Copies sounds that you make",
        answer: textFormat(response?.[7])
      },
      {
        qno: 9,
        question: "Walks across a room without help",
        answer: textFormat(response?.[8])
      },
      {
        qno: 10,
        question: `Follows directions- like "Come here" or "Give me the ball"`,
        answer: textFormat(response?.[9])
      },

    ]
  } else if (Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) >= 12 &&
    Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) <= 14) {
    questionBank = [
      {
        qno: 1,
        question: "Picks up food and eats it",
        answer: textFormat(response?.[0])
      },
      {
        qno: 2,
        question: "Pulls up to standing",
        answer: textFormat(response?.[1])
      },
      {
        qno: 3,
        question: `Plays games like "peek" a "boo" or "pat" a "cake"`,
        answer: textFormat(response?.[2])
      },
      {
        qno: 4,
        question: `Calls you "mama" or "dada" or similar name`,
        answer: textFormat(response?.[3])
      },
      {
        qno: 5,
        question: `Looks around when you say things like "Where's your bottle?" or "Where's your blanket?"`,
        answer: textFormat(response?.[4])
      },
      {
        qno: 6,
        question: "Copies sounds that you make",
        answer: textFormat(response?.[5])
      },
      {
        qno: 7,
        question: "Walks across a room without help",
        answer: textFormat(response?.[6])
      },
      {
        qno: 8,
        question: `Follows directions- like "Come here" or "Give me the ball"`,
        answer: textFormat(response?.[7])
      },
      {
        qno: 9,
        question: "Runs",
        answer: textFormat(response?.[8])
      },
      {
        qno: 10,
        question: "Walks up stairs with help",
        answer: textFormat(response?.[9])
      },
    ]
  } else if (Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) >= 15 &&
    Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) <= 17) {
    questionBank = [
      {
        qno: 1,
        question: `Calls you "mama" or "dada" or similar name`,
        answer: textFormat(response?.[0])
      },
      {
        qno: 2,
        question: `Looks around when you say things like "Where's your bottle?" or "Where's your blanket?"`,
        answer: textFormat(response?.[1])
      },
      {
        qno: 3,
        question: "Copies sounds that you make",
        answer: textFormat(response?.[2])
      },
      {
        qno: 4,
        question: "Walks across a room without help",
        answer: textFormat(response?.[3])
      },
      {
        qno: 5,
        question: `Follows direction-  like "Come here" or "Give me the ball"`,
        answer: textFormat(response?.[4])
      },
      {
        qno: 6,
        question: "Runs",
        answer: textFormat(response?.[5])
      },
      {
        qno: 7,
        question: "Walks up stairs with help",
        answer: textFormat(response?.[6])
      },
      {
        qno: 8,
        question: "Kicks a ball",
        answer: textFormat(response?.[7])
      },
      {
        qno: 9,
        question: `Names at least 5 familiar objects- like ball or milk`,
        answer: textFormat(response?.[8])
      },
      {
        qno: 10,
        question: "Names at least 5 body parts- like nose, hand, or tummy",
        answer: textFormat(response?.[9])
      },

    ]
  } else if (Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) >= 18 &&
    Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) <= 22) {

    questionBank = [
      {
        qno: 1,
        question: "Runs?",
        answer: textFormat(response?.[0])
      },
      {
        qno: 2,
        question: "Walks up stairs with help?",
        answer: textFormat(response?.[1])

      },
      {
        qno: 3,
        question: "Kicks a ball?",
        answer: textFormat(response?.[2])
      },
      {
        qno: 4,
        question: "Names at least familiar objects like ball or milk?",
        answer: textFormat(response?.[3])
      },
      {
        qno: 5,
        question: "Names at least body parts like nose, hand, or tummy?",
        answer: textFormat(response?.[4])
      },
      {
        qno: 6,
        question: "Climbs up a ladder at a playground?",
        answer: textFormat(response?.[5])
      },
      {
        qno: 7,
        question: `Uses words like "me" or "mine"?`,
        answer: textFormat(response?.[6])
      },
      {
        qno: 8,
        question: "Jumps off the ground with two feet?",
        answer: textFormat(response?.[7])
      },
      {
        qno: 9,
        question: `Puts 2 or more words together like "more water" or "go outside"?`,
        answer: textFormat(response?.[8])
      },
      {
        qno: 10,
        question: "Uses words to ask for help?",
        answer: textFormat(response?.[9])
      },

    ]
  } else if (Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) >= 23 &&
    Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) <= 28) {
    questionBank = [
      {
        qno: 1,
        question: "Names at least body parts like nose, hand, or tummy?",
        answer: textFormat(response?.[0])
      },
      {
        qno: 2,
        question: "Climbs up a ladder at a playground?",
        answer: textFormat(response?.[1])

      },
      {
        qno: 3,
        question: `Uses words like "me" or "mine"?`,
        answer: textFormat(response?.[2])
      },
      {
        qno: 4,
        question: "Jumps off the ground with two feet?",
        answer: textFormat(response?.[3])
      },
      {
        qno: 5,
        question: `Puts 2 or more words together like "more water" or "go outside"?`,
        answer: textFormat(response?.[4])
      },
      {
        qno: 6,
        question: "Uses words to ask for help?",
        answer: textFormat(response?.[5])
      },
      {
        qno: 7,
        question: `Names at least one color?`,
        answer: textFormat(response?.[6])
      },
      {
        qno: 8,
        question: `Tries to get you to watch by saying "Look at me"?`,
        answer: textFormat(response?.[7])
      },
      {
        qno: 9,
        question: `Says his or her first name when asked?`,
        answer: textFormat(response?.[8])
      },
      {
        qno: 10,
        question: "Draws lines?",
        answer: textFormat(response?.[9])
      },

    ]
  }

  else if (Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) >= 29 &&
    Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) <= 34) {
    questionBank = [
      {
        qno: 1,
        question: "Names at least one color?",
        answer: textFormat(response?.[0])
      },
      {
        qno: 2,
        question: `Tries to get you to watch by saying "Look at me"?`,
        answer: textFormat(response?.[1])

      },
      {
        qno: 3,
        question: `Draws lines?`,
        answer: textFormat(response?.[2])
      },
      {
        qno: 4,
        question: "Jumps off the ground with two feet?",
        answer: textFormat(response?.[3])
      },
      {
        qno: 5,
        question: `Talks so other people can understand him or her most of the time?`,
        answer: textFormat(response?.[4])
      },
      {
        qno: 6,
        question: "Washes and dries hands without help even if you turn on the water?",
        answer: textFormat(response?.[5])
      },
      {
        qno: 7,
        question: `Asks questions beginning with "why" or "how" like "Why no cookie?"`,
        answer: textFormat(response?.[6])
      },
      {
        qno: 8,
        question: `Explains the reasons for things, like needing a sweater when it's cold?`,
        answer: textFormat(response?.[7])
      },
      {
        qno: 9,
        question: `Compares things using words like "bigger" or "shorter"?`,
        answer: textFormat(response?.[8])
      },
      {
        qno: 10,
        question: `Answers questions like "What do you do when you are cold?" or "When you are sleepy?"`,
        answer: textFormat(response?.[9])
      },
    ]
  }

  else if (Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) >= 35 &&
    Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) <= 46) {
    questionBank = [
      {
        qno: 1,
        question: "Talks so other people can understand him or her most of the time?",
        answer: textFormat(response?.[0])
      },
      {
        qno: 2,
        question: `Washes and dries hands without help even if you turn on the water?`,
        answer: textFormat(response?.[1])

      },
      {
        qno: 3,
        question: `Asks questions beginning with "why" or "how" like "Why no cookie?"`,
        answer: textFormat(response?.[2])
      },
      {
        qno: 4,
        question: "Explains the reasons for things, like needing a sweater when it's cold?",
        answer: textFormat(response?.[3])
      },
      {
        qno: 5,
        question: `Compares things using words like "bigger" or "shorter"?`,
        answer: textFormat(response?.[4])
      },
      {
        qno: 6,
        question: `Answers questions like "What do you do when you are cold?" or "When you are sleepy?"`,
        answer: textFormat(response?.[5])
      },
      {
        qno: 7,
        question: `Tells you a story from a book or tv?`,
        answer: textFormat(response?.[6])
      },
      {
        qno: 8,
        question: `Draws simple shapes like a circle or a square?`,
        answer: textFormat(response?.[7])
      },
      {
        qno: 9,
        question: `Says words like "feet" for more than one foot and "men" for more than one man?`,
        answer: textFormat(response?.[8])
      },
      {
        qno: 10,
        question: `Uses words like "yesterday" and "tomorrow" correctly?`,
        answer: textFormat(response?.[9])
      },
    ]
  }

  else if (Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) >= 47 &&
    Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) <= 58) {
    questionBank = [
      {
        qno: 1,
        question: `Compares things using words like "bigger" or "shorter"?`,
        answer: textFormat(response?.[0])
      },
      {
        qno: 2,
        question: `Answers questions like "What do you do when you are cold?" or "When you are sleepy?"`,
        answer: textFormat(response?.[1])

      },
      {
        qno: 3,
        question: `Tells you a story from a book or tv?`,
        answer: textFormat(response?.[2])
      },
      {
        qno: 4,
        question: "Draws simple shapes like a circle or a square?",
        answer: textFormat(response?.[3])
      },
      {
        qno: 5,
        question: `Says words like "feet" for more than one foot and "men" for more than one man?`,
        answer: textFormat(response?.[4])
      },
      {
        qno: 6,
        question: `Uses words like "yesterday" and "tomorrow" correctly?"`,
        answer: textFormat(response?.[5])
      },
      {
        qno: 7,
        question: `Stays dry all night?`,
        answer: textFormat(response?.[6])
      },
      {
        qno: 8,
        question: `Follows simple rules when playing a board game or card game?`,
        answer: textFormat(response?.[7])
      },
      {
        qno: 9,
        question: `Paints his or her name?`,
        answer: textFormat(response?.[8])
      },
      {
        qno: 10,
        question: `Draws pictures you recognise?`,
        answer: textFormat(response?.[9])
      },
    ]
  }

  else if (Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) >= 59 &&
    Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) <= 65) {
    questionBank = [
      {
        qno: 1,
        question: `Tells you a story from a book or tv?`,
        answer: textFormat(response?.[0])
      },
      {
        qno: 2,
        question: `Draws simple shapes like a circle or a square?`,
        answer: textFormat(response?.[1])

      },
      {
        qno: 3,
        question: `Says words like "feet" for more than one foot and "men" for more than one man?`,
        answer: textFormat(response?.[2])
      },
      {
        qno: 4,
        question: `Uses words like "yesterday" and "tomorrow" correctly?`,
        answer: textFormat(response?.[3])
      },
      {
        qno: 5,
        question: `Stays dry all night?`,
        answer: textFormat(response?.[4])
      },
      {
        qno: 6,
        question: `Follows simple rules when playing a board game or card game?`,
        answer: textFormat(response?.[5])
      },
      {
        qno: 7,
        question: `Paints his or her name?`,
        answer: textFormat(response?.[6])
      },
      {
        qno: 8,
        question: `Draws pictures you recognise?`,
        answer: textFormat(response?.[7])
      },
      {
        qno: 9,
        question: `Stays in the lines when coloring?`,
        answer: textFormat(response?.[8])
      },
      {
        qno: 10,
        question: `Names the days of the week in the correct order?`,
        answer: textFormat(response?.[9])
      },
    ]
  }

  const emotionalQuestionBank = [
    {
      qno: 1,
      question: "Does your child Seem nervous or afraid?",
      answer: textFormat(emoResponse?.[0])
    },
    {
      qno: 2,
      question: "Does your child Seem sad or unhappy for prolonged periods without any underlying reason?",
      answer: textFormat(emoResponse?.[1])

    },
    {
      qno: 3,
      question: "Does your child get upset if things are not done in a certain way?",
      answer: textFormat(emoResponse?.[2])
    },
    {
      qno: 4,
      question: "Does your child Have a hard time with change?",
      answer: textFormat(emoResponse?.[3])
    },

    {
      qno: 5,
      question: "Does your child Have trouble playing with other children?",
      answer: textFormat(emoResponse?.[4])
    },

    {
      qno: 6,
      question: "Does your child Break things on purpose?",
      answer: textFormat(emoResponse?.[5])
    },


  ]

  return (
    <>
      <div className={clsx(classes.container, 'xyz')}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>
        <div className={classes.title_container}>
          Hey! This is my Development Milestones report.
        </div>

        <div className={classes.student_details_container}>
          <div className={classes.student_avatar_container}>
            <Avatar
              {...stringAvatar(`${data?.student?.name}`)}
              sx={{
                backgroundColor: "#EC0606",
                height: "39px",
                width: "39px",
                fontSize: "24px",
                fontWeight: "600",
                fontFamily: "Inter",
                margin: "0 auto",
              }}
            />
            <div>{data?.student?.name}</div>
            <div>
              <div>
                <p>{getAge(data?.student?.DOB)} yrs</p>
                <p>.</p>
                <p>{data?.student?.gender}</p>
                <p>.</p>
                {/* <p>{`${data?.kid?.class}-${data?.kid?.section}`}</p> */}
              </div>
            </div>

            <div className={classes.validation_container}>
              <div>
                <p>Validated by</p>
                <p>Ashwathi Prabhu</p>
              </div>

              <div>
                <p>Validation date & Time</p>
                <p>1st Mar, 2023 at 12:11 PM</p>
              </div>
            </div>
          </div>

          <div>
            <p>Score</p>
            <div>
              <p>{data?.output?.[0]?.outcome}</p>
              {data?.output?.[0]?.outcome?.includes("High") && <img src={highrisk} alt="highrisk" />}
            </div>

            <div className={classes.interpretation_container}>
              <div>
                <img src={psychologist} />
              </div>
              <div>
                <p>Psychologist Intrepretation</p>
                <p>
                  {JSON.parse(data[data?.package[0]])?.interpretation && `Development Milestones - ${JSON.parse(data[data?.package[0]])?.interpretation}`}
                </p>
                <p>
                  {Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) >= 18 &&
                    JSON.parse(data[data?.package[1]])?.interpretation && `Emotional and Behavioral - ${JSON.parse(data[data?.package[1]])?.interpretation}`}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.question_container}>
          <div>Assessment Questionnaire</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {
              console.log(el)
              return (
                <div className={classes.question_radio_box}>
                  <p>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Not yet"
                          checked={el.answer === "Not Yet"}
                        />
                        <p>Not yet</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Somewhat"
                          checked={el.answer === "Somewhat"}
                        />
                        <p>Somewhat</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Very Much"
                          checked={el.answer === "Very Much"}
                        />
                        <p>Very Much</p>
                      </div>

                    </form>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) >= 18 &&
          <div className={classes.question_container}>
            <div>Emotional and behavioral aspect Questionnaire</div>
            <div className={classes.question_select_container}>
              {emotionalQuestionBank.map((el) => {
                return (
                  <div className={classes.question_radio_box}>
                    <p>
                      {el.qno}. {el.question}
                    </p>
                    <div className={classes.radio_toolbar}>
                      <form>
                        <div className={classes.radio_btn_container}>
                          <input
                            type="radio"
                            id="html"
                            name="RadButton"
                            value="Not At All"
                            checked={el.answer === "Not At All"}
                          />
                          <p>Not At All</p>
                        </div>

                        <div className={classes.radio_btn_container}>
                          <input
                            type="radio"
                            id="html"
                            name="RadButton"
                            value="Somewhat"
                            checked={el.answer === "Somewhat"}
                          />
                          <p>Somewhat</p>
                        </div>

                        <div className={classes.radio_btn_container}>
                          <input
                            type="radio"
                            id="html"
                            name="RadButton"
                            value="Very Much"
                            checked={el.answer === "Very Much"}
                          />
                          <p>Very Much</p>
                        </div>

                      </form>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        }
        <div className={classes.footer_container}>
          <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
          <p>Page 1 of {Math.abs(moment(data?.student?.DOB).diff(moment(), "months")) >= 18 ? 2 : 1}</p>
        </div>
      </div>
    </>
  );
};
