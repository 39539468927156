import * as React from "react";
import "../Main.css";

const Dental = (props) => {

    const { active, issue, onClick, disabled, top, right, scale } = props;

    return (
        disabled
            ?
            <div
                onClick={onClick}
                className={`mainCon`}
                style={{
                    top: top,
                    right: right,
                    transform: `scale(${scale})`
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                >
                    <circle cx={12} cy={12} r={12}
                        fill="#B2B7BE"
                        opacity="0.16" />
                    <path
                        fill="#B2B7BE"
                        fillRule="evenodd"
                        d="M10.988 7.093c-1.446-.814-2.727-.431-3.792.862-1.027 1.247-.327 3.496.177 5.112.12.386.229.735.3 1.027.372 1.513.728 2.456 1.54 3.139.401.338.857-.243 1.337-.856.417-.531.852-1.086 1.288-1.088.428-.001.857.553 1.268 1.085.474.614.925 1.197 1.324.859.62-.523.901-.907 1.242-1.985.371-1.176.97-2.582 1.447-4.203.335-1.14.376-2.3-.59-3.421-1.38-1.6-3.293-.774-4.183-.245l1.206 1.034a.333.333 0 0 1-.434.506l-2.13-1.826Z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
            :
            <div
                className={`mainCon  ${active && (issue ? "activeRed" : "activeGreen")}`}
                onClick={onClick}
                style={{
                    top: top,
                    right: right,
                    transform: `scale(${scale})`
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                >
                    <circle cx={12} cy={12} r={12}
                        fill={
                            active
                                ? "white"
                                : issue
                                    ? "#F80D38"
                                    : "#0BB024"
                        }
                        opacity={
                            active ? "1" : "0.16"
                        } />
                    <path
                        fill={issue ? "#FF001D" : "#00B817"}
                        fillRule="evenodd"
                        d="M10.988 7.093c-1.446-.814-2.727-.431-3.792.862-1.027 1.247-.327 3.496.177 5.112.12.386.229.735.3 1.027.372 1.513.728 2.456 1.54 3.139.401.338.857-.243 1.337-.856.417-.531.852-1.086 1.288-1.088.428-.001.857.553 1.268 1.085.474.614.925 1.197 1.324.859.62-.523.901-.907 1.242-1.985.371-1.176.97-2.582 1.447-4.203.335-1.14.376-2.3-.59-3.421-1.38-1.6-3.293-.774-4.183-.245l1.206 1.034a.333.333 0 0 1-.434.506l-2.13-1.826Z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
    )
}
export default Dental
