import * as React from "react";
import "../Main.css";

const Throat = (props) => {
    const { active, issue, onClick, disabled, top, right, scale } = props;
    return (
        disabled
            ?
            <div
                className={`mainCon`}
                style={{
                    top: top,
                    right: right,
                    transform: `scale(${scale})`
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    {...props}
                >
                    <circle cx={12} cy={12} r={12}
                        fill="#B2B7BE"
                        opacity="0.16" />
                    <path
                        fill="#B2B7BE"
                        d="M8.358 18a.47.47 0 0 1-.338-.128.427.427 0 0 1-.133-.322v-2.13a5.56 5.56 0 0 1-1.392-1.822A5.06 5.06 0 0 1 6 11.4c0-1.5.55-2.775 1.65-3.825C8.752 6.525 10.089 6 11.66 6c1.31 0 2.471.367 3.483 1.103 1.011.735 1.669 1.692 1.973 2.872l.865 3.27a.393.393 0 0 1-.079.383.463.463 0 0 1-.377.172h-1.462v2.1c0 .25-.092.463-.276.638a.932.932 0 0 1-.668.262h-1.572v.75c0 .13-.045.238-.134.322a.47.47 0 0 1-.338.128h-2.138l.409-3.75h1.572a.47.47 0 0 0 .338-.127.427.427 0 0 0 .134-.323.427.427 0 0 0-.134-.323.47.47 0 0 0-.338-.127H11.44l.094-.825c.021-.19.105-.35.252-.48a.757.757 0 0 1 .519-.195h1.87a.47.47 0 0 0 .339-.127.427.427 0 0 0 .134-.323.427.427 0 0 0-.134-.322.47.47 0 0 0-.338-.128H12.32c-.45 0-.838.143-1.163.428a1.635 1.635 0 0 0-.566 1.057L9.994 18H8.358Z"
                    />
                </svg>
            </div>
            :
            <div
                className={`mainCon  ${active && (issue ? "activeRed" : "activeGreen")}`}
                onClick={onClick}
                style={{
                    top: top,
                    right: right,
                    transform: `scale(${scale})`
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    {...props}
                >
                    <circle cx={12} cy={12} r={12}
                        fill={
                            active
                                ? "white"
                                : issue
                                    ? "#F80D38"
                                    : "#0BB024"
                        }
                        opacity={
                            active ? "1" : "0.16"
                        } />
                    <path
                        fill={issue ? "#FF001D" : "#00B817"}
                        d="M8.358 18a.47.47 0 0 1-.338-.128.427.427 0 0 1-.133-.322v-2.13a5.56 5.56 0 0 1-1.392-1.822A5.06 5.06 0 0 1 6 11.4c0-1.5.55-2.775 1.65-3.825C8.752 6.525 10.089 6 11.66 6c1.31 0 2.471.367 3.483 1.103 1.011.735 1.669 1.692 1.973 2.872l.865 3.27a.393.393 0 0 1-.079.383.463.463 0 0 1-.377.172h-1.462v2.1c0 .25-.092.463-.276.638a.932.932 0 0 1-.668.262h-1.572v.75c0 .13-.045.238-.134.322a.47.47 0 0 1-.338.128h-2.138l.409-3.75h1.572a.47.47 0 0 0 .338-.127.427.427 0 0 0 .134-.323.427.427 0 0 0-.134-.323.47.47 0 0 0-.338-.127H11.44l.094-.825c.021-.19.105-.35.252-.48a.757.757 0 0 1 .519-.195h1.87a.47.47 0 0 0 .339-.127.427.427 0 0 0 .134-.323.427.427 0 0 0-.134-.322.47.47 0 0 0-.338-.128H12.32c-.45 0-.838.143-1.163.428a1.635 1.635 0 0 0-.566 1.057L9.994 18H8.358Z"
                    />
                </svg>
            </div>
    )
}
export default Throat
