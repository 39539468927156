import React from "react";
import classes from "./Dyslexia.module.css";
import skidslogo from "../../../assets/images/SkidsSuperManLogo.svg";
import { getAge, stringAvatar } from "../../../utils/utility";
import { stringToColor } from "../../../utils/utility";
import { Avatar } from "@mui/material";
import highrisk from "../../../assets/images/highRisk.svg";
import psychologist from "../../../assets/images/psychologist.svg";
import BhvReportHeader from "../Anxiety/BhvReportHeader";
import moment from "moment";

export const Dyslexia = ({ data }) => {
  const questionBank = [
    {
      qno: 1,
      question:
        "How often does your child mispronounce (or used to) only certain words (e.g., says amunul for animal, poothtaste for toothpaste)?",
      answer: data?.output?.[0]?.answers[0]
    },
    {
      qno: 2,
      question:
        "How often does your child have difficulty reading unfamiliar words or guess at them?",
      answer: data?.output?.[0]?.answers[1]
    },
    {
      qno: 3,
      question:
        "How often does your child pause, repeat or make mistakes when reading aloud?",
      answer: data?.output?.[0]?.answers[2]
    },
    {
      qno: 4,
      question:
        "How often does your child struggle to understand what he or she has read?",
      answer: data?.output?.[0]?.answers[3]
    },
    {
      qno: 5,
      question: "How often does your child avoid reading for pleasure?",
      answer: data?.output?.[0]?.answers[4]
    },
  ];

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header_container}>
          <img src={skidslogo} alt="skids_logo" />
          <p>Skids Health</p>
        </div>
        <div className={classes.title_container}>
          Hey! This is my Dyslexia Stage 1 assessment report.
        </div>

        <div className={classes.student_details_container}>
          <div className={classes.student_avatar_container}>
            <Avatar
              {...stringAvatar(`${data?.student?.name}`)}
              sx={{
                backgroundColor: "#EC0606",
                height: "39px",
                width: "39px",
                fontSize: "24px",
                fontWeight: "600",
                fontFamily: "Inter",
                margin: "0 auto",
              }}
            />
            <div>{data?.student?.name}</div>
            <div>
              <div>
                <p>{getAge(data?.student?.DOB)} yrs</p>
                <p>.</p>
                <p>{data?.student?.gender}</p>
                <p>.</p>
                <p>{`${data?.student?.class}-${data?.student?.section}`}</p>
              </div>
            </div>

            <div className={classes.validation_container}>
              <div>
                <p>Validated by</p>
                <p>Ashwathi Prabhu</p>
              </div>

              <div>
                <p>Validation date & Time</p>
                <p>{data?.output?.length === 2 ? moment(data?.output?.[1]?.responseAt).format("Do MMM, YYYY hh:mm A") : moment(data?.output?.[0]?.responseAt).format("Do MMM, YYYY hh:mm A")}</p>
              </div>
            </div>
          </div>

          <div>
            <p>Score</p>
            <div>
              <p>{data?.output?.length === 2 ? data?.output?.[1]?.outcome : data?.output?.[0]?.outcome}</p>
              {(data?.output?.length === 2 ? data?.output?.[0]?.outcome?.includes("High") : data?.output?.[1]?.outcome?.includes("High")) && <img src={highrisk} alt="highrisk" />}
            </div>

            <div className={classes.interpretation_container}>
              <div>
                <img src={psychologist} />
              </div>
              <div>
                <p>Psychologist Intrepretation</p>
                <p>
                  {data?.output?.length === 2 ? data?.output?.[1]?.interpretation : data?.output?.[0]?.interpretation}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.question_container}>
          <div>Assessment Questionnaire</div>
          <div className={classes.question_select_container}>
            {questionBank.map((el) => {
              return (
                <div className={classes.question_radio_box}>
                  <p style={{ width: "209px" }}>
                    {el.qno}. {el.question}
                  </p>
                  <div className={classes.radio_toolbar}>
                    <form>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Never"
                          checked={el.answer === "NEVER"}
                        />
                        <p>Never</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Rarely"
                          checked={el.answer === "RARELY"}
                        />
                        <p>Rarely</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Sometimes"
                          checked={el.answer === "SOMETIMES"}
                        />
                        <p>Sometimes</p>
                      </div>

                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Frequently"
                          checked={el.answer === "FREQUENTLY"}
                        />
                        <p>Frequently</p>
                      </div>
                      <div className={classes.radio_btn_container}>
                        <input
                          type="radio"
                          id="html"
                          name="RadButton"
                          value="Always"
                          checked={el.answer === "ALWAYS"}
                        />
                        <p>Always</p>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.copyright_details_container}>
          <div>
            The information contained in this publication should not be used as
            a substitute for the medical care and advice of your psychologist.
            There may be variations in treatment that your psychologist may
            recommend based on individual facts and circumstances.
          </div>
          <div>
            Copyright @2023 Skids Health<br />Proprietorship of Skids Health Pvt.
            Ltd.
          </div>
        </div>

        <div className={classes.footer_main}>
          <div className={classes.footer_container}>
            <p>www.skids.health | hello@skids.health | +91-73771-12777</p>
            <p>Page 1 of 2</p>
          </div>
        </div>
      </div>
    </>
  );
};
