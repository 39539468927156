import * as React from "react";
import "../Main.css";

const Heart = (props) => {

    const { active, issue, disabled, onClick, top, right, scale } = props;

    return (
        disabled
            ?
            <div className={`mainCon`}
                style={{
                    top: top,
                    right: right,
                    transform: `scale(${scale})`
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    {...props}
                >
                    <circle cx={12} cy={12} r={12}
                        fill="#B2B7BE"
                        opacity="0.16" />
                    <path
                        fill="#B2B7BE"
                        d="M7 10.309c0-1.908 1.27-3.678 3.01-3.678 1.208 0 2.104.794 2.657 1.92.553-1.126 1.449-1.92 2.656-1.92 1.74 0 3.01 1.77 3.01 3.678 0 4.085-5.666 6.989-5.666 6.989S8.85 15.46 7.48 12.445h3.38l.538-.955.628 2.196 1.47-1.89h1.836v-.666H13.17l-.863 1.11-.706-2.47-1.13 2.008H7.228c.07.228.156.45.254.667H6v-.667h1.227A4.948 4.948 0 0 1 7 10.309Z"
                    />
                </svg>
            </div>
            :
            <div className={`mainCon  ${active && (issue ? "activeRed" : "activeGreen")}`}
                onClick={onClick}
                style={{
                    top: top,
                    right: right,
                    transform: `scale(${scale})`
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    {...props}
                >
                    <circle cx={12} cy={12} r={12}
                        fill={
                            active
                                ? "white"
                                : issue
                                    ? "#F80D38"
                                    : "#0BB024"
                        }
                        opacity={
                            active ? "1" : "0.16"
                        }
                    />
                    <path
                        fill={issue ? "#FF001D" : "#00B817"}
                        d="M7 10.309c0-1.908 1.27-3.678 3.01-3.678 1.208 0 2.104.794 2.657 1.92.553-1.126 1.449-1.92 2.656-1.92 1.74 0 3.01 1.77 3.01 3.678 0 4.085-5.666 6.989-5.666 6.989S8.85 15.46 7.48 12.445h3.38l.538-.955.628 2.196 1.47-1.89h1.836v-.666H13.17l-.863 1.11-.706-2.47-1.13 2.008H7.228c.07.228.156.45.254.667H6v-.667h1.227A4.948 4.948 0 0 1 7 10.309Z"
                    />
                </svg>
            </div>
    )
}
export default Heart
