import React, { useState, useEffect } from "react";
import classes from "./EditOrganization.module.css";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import ArrowRight from "@mui/icons-material/ArrowRight";
import schoollogo from "../../../assets/images/schoollogo.png";
import upload from "../../../assets/images/upload.svg";
import { Link, useParams } from "react-router-dom";
import InputDashboard from "../InputDashboard/InputDashboard";
import InputDropdown from "../InputDashboard/InputDropdown";
import MultipleSelectChip from "../MultiSelectDropdown/MultiSelectDropdown";
import NotificationClose from "../../../assets/images/notification-close.svg";
import { useLocation, useNavigate } from "react-router-dom";
import CustomMultiSelectDropdown from "../InputDashboard/CustomMultiSelect";
import { findByAltText } from "@testing-library/react";
import DatePickerInput from "../InputDashboard/DatePickerInput";
import { Alert, Avatar, Box, FormControl, InputLabel, MenuItem, Modal, Select, Snackbar } from "@mui/material";
import schoolReducer from "../../../redux/reducers/school.reducer";
import { useDispatch, useSelector } from "react-redux";
import { compareDate } from "../../../utils/utility";
import { State, City } from "country-state-city";
import clinincReducer from "../../../redux/reducers/clininc.reducer";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import { validateEmail } from "../../../utils/utility";
import PackageDrawer from "../PackageDrawer/PackageDrawer";
import CloseModal from "../../../assets/images/closeModal.svg";
import { API } from "aws-amplify";
import Cookies from "js-cookie";
import { log } from "logrocket";
import { Buffer } from "buffer";

function EditOrganizationForm() {
  const token = Cookies.get("tokenId");
  const location = useLocation();
  const entity = location.pathname?.includes("school") ? "school" : "clinic";
  const firstLetterEntity = entity[0].toUpperCase();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "16px",
    height: "260px",
    width: "472px",
    boxShadow: 24,
    p: 2,
  };
  const btn_container = {
    border: "none",
    background: "none",
    padding: "none",
    border: "none",
    background: "none",
    fontWeight: "600",
    fontSize: "14px",
    cursor: "pointer",
    textAlign: "left",
  };

  const close_btn_container = {
    display: "flex",
    flexDirection: "row-reverse",
  };

  const close_btn = {
    background: "none",
    border: "none",
    outline: "none",
    textAlign: "left",
  };

  const modal_header = {
    margin: "24px 0 0 0",
    fontSize: "20px",
    fontWeight: "normal",
    textAlign: "center",
  };

  const modal_header_info = {
    margin: "20px 0 0 0",
    textAlign: "center",
    padding: "3px 20px",
    fontSize: "14px",
  };

  const action_btn_container = {
    margin: "24px 0 0 0",
    display: "flex",
    gap: "32px",
    justifyContent: "center",
  };

  const action_btn = {
    padding: "16px 0",
    border: "1px solid #1740A1",
    borderRadius: "8px",
    width: "176px",
    height: "56px",
    background: "none",
    fontWeight: "600",
    fontSize: "16px",
  };

  const black_btn = {
    padding: "16px 0",
    border: "1px",
    borderRadius: "8px",
    width: "176px",
    height: "56px",
    background: "none",
    fontWeight: "600",
    fontSize: "16px",
    border: "none !important",
    color: "#ffffff",
    backgroundColor: "#1740A1",
  };

  const updateSchool = schoolReducer.actions.updateSchoolRequest;
  const updateClinic = clinincReducer.actions.updateClinicRequest;
  const getSchoolDetails = schoolReducer.actions.getSchoolbyIdRequest;
  const updatePackage = schoolReducer.actions.updatePackageRequest;

  const schoolData = useSelector((state) => state.schoolReducer.data)

  const entityLetter = entity.substring(1);
  const finalLetter = firstLetterEntity + entityLetter;
  const dispatch = useDispatch();
  const [schoolName, setSchoolName] = useState("");
  const [organization, setOrganization] = useState("");
  const [established, setEstablished] = useState("");
  const [screeningPlan, setScreeningPlan] = useState("");
  const [screeningFrom, setScreeningFrom] = useState("");
  const [screeningTo, setScreeningTo] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [board, setBoard] = useState("");
  const [orientation, setOrientation] = useState("");
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [infirmary, setInfirmary] = useState("");
  const [teacher, setTeacher] = useState("");
  const [counsellor, setCounsellor] = useState("");
  const [student, setStudent] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminTitle, setAdminTitle] = useState("");
  const [adminFirstName, setAdminFirstName] = useState("");
  const [adminLastName, setAdminLastName] = useState("");
  const [adminPhone, setAdminPhone] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [schoolCode, setSchoolCode] = useState("");
  const [staff, setStaff] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [planName, setPlanName] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [orgId, setOrgId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [logoRes, setLogoRes] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [doctor, setDoctor] = useState("");
  const [nurse, setNurse] = useState("");
  const [tempPlanName, setTempPlanName] = useState(planName)
  const [adminNumberError, setAdminNumberError] = useState(false)
  const [imageShow, setImageShow] = useState("")

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Package States
  const [showPackageDrawer, setShowPackageDrawer] = useState(false);
  const [vitalSigns, setVitalSigns] = useState([]);
  const [hair, setHair] = useState([])
  const [eye, setEye] = useState([])
  const [ear, setEar] = useState([])
  const [dental, setDental] = useState([])
  const [throat, setThroat] = useState([])
  const [heart, setHeart] = useState([])
  const [lungs, setLungs] = useState([])
  const [abdomen, setAbdomen] = useState([])
  const [skin, setSkin] = useState([])
  const [behavioral, setBehavioral] = useState([])
  const [learning, setLearning] = useState([])
  //

  const navigate = useNavigate();
  const loadingClinic = useSelector((state) => state.clinicReducer.loading);
  const loadingSchool = useSelector((state) => state.schoolReducer.loading);
  const errorClinic = useSelector((state) => state.clinicReducer.error);
  const errorSchool = useSelector((state) => state.schoolReducer.error);

  useEffect(() => {
    dispatch(getSchoolDetails({ schoolId: location.state?.schoolId }))
  }, [])


  const authHeaders = {
    method: "PUT",
    body: {
      selectedFile,
    },
    headers: {
      Authorization: token,
    },
  };

  const getAuthHeaders = {
    method: "GET",
    headers: {
      Authorization: token,
    },
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };
  const handleClick = () => {
    setOpen(true);
  };

  function capitalize(str) {
    str = str?.toLowerCase()
    if (str?.search('_') !== -1) {
      str?.replace("_", " ")
    }
    return str && str[0].toUpperCase() + str.slice(1);
  }

  const toBase64 = () => new Promise((resolve, reject) => {
    const readers = new FileReader();
    readers.readAsArrayBuffer(logoRes);
    readers.onload = () => {
      resolve(Buffer.from(readers.result).toJSON())

    };
    readers.onerror = error => reject(error);
  });

  useEffect(() => {
    const states = State.getStatesOfCountry("IN").map((state) => state.name);
    const cities = City.getCitiesOfCountry("IN").map((state) => state.name);
    setState(states);
    setCity(cities);
  }, []);

  useEffect(() => {
    const phoneRegEx = /^[6-9]{1}[0-9]{9}$/;
    setAdminNumberError(!phoneRegEx.test(adminPhone))
  }, [adminPhone]);

  useEffect(() => {
    if (selectedState) {
      const selectedStateObject = State.getStatesOfCountry("IN").filter(
        (x) => x.name === selectedState
      );
      setCity(
        City.getCitiesOfState("IN", selectedStateObject[0]?.isoCode).map(
          (x) => x.name
        )
      );
    }
  }, [selectedState]);
  const handleDeleteAmenities = (element) => {
    const filteredAmenities = amenities.filter((el) => el !== element);
    setAmenities(filteredAmenities);
  };



  useEffect(() => {
    if (schoolData && entity === "school") {
      setSchoolName(schoolData?.schoolName);
      setAddress(schoolData?.address);
      setSelectedState(schoolData?.state);
      setSelectedCity(schoolData?.city);
      setOrientation(schoolData?.annualOrientationMonth);
      setBoard(schoolData?.board);
      setEmail(schoolData?.contact?.email);
      setPhoneNumber(schoolData?.contact?.officeNumber);
      setEstablished(schoolData?.establishedFrom);
      setOrganization(schoolData?.organization);
      setPincode(schoolData?.pincode);
      setPlanName(textFormat(schoolData?.package));
      setCounsellor(
        schoolData.staffAndAmenities?.counsellors?.counsellorsCount
      );
      setDoctor(schoolData.staffAndAmenities?.infirmary?.doctor);
      setNurse(schoolData.staffAndAmenities?.infirmary?.nurse);
      setAdminTitle(
        schoolData?.schoolAdmin?.name
          ? schoolData?.schoolAdmin?.name?.split(" ")[0]
          : ""
      );
      setAdminFirstName(
        schoolData.schoolAdmin?.name
          ? schoolData?.schoolAdmin?.name?.split(" ")[1]
          : ""
      );
      setAdminLastName(
        schoolData?.schoolAdmin?.name
          ? schoolData?.schoolAdmin?.name?.split(" ")[2]
          : ""
      );
      setAdminPhone(schoolData?.schoolAdmin?.phone);
      setAdminEmail(schoolData?.schoolAdmin?.email);
      setAdminTitle(schoolData?.schoolAdmin?.salutation)
      setScreeningPlan(schoolData?.screeningPlan);
      setAmenities(
        Array.isArray(schoolData?.staffAndAmenities?.amenitiesAvailable)
          ? schoolData?.staffAndAmenities?.amenitiesAvailable[0]?.split(",")
          : []
      );
      setInfirmary(
        schoolData?.staffAndAmenities?.infirmary === true ? "Yes" : "No"
      );
      setStudent(schoolData?.strength);
      setTeacher(schoolData?.staffAndAmenities?.teachers);
      setSchoolCode(schoolData?.schoolCode);
      setScreeningFrom(schoolData?.screeningFrom ?? "");
      setScreeningTo(schoolData?.screeningTo ?? "");
      setOrgId(schoolData?.schoolId ?? "");
      setLogoRes(schoolData?.url)
    } else {
      const values = location.state;
      const name = values?.clinicAdmin?.name.split(" ");
      let spec;
      if (values?.specialty === "Multispeciality") {
        spec = "Multi speciality";
      } else if (values?.specialty === "Superspeciality") {
        spec = "Super speciality";
      } else {
        spec = "Advisory";
      }
      setAddress(values?.address);
      setSelectedCity(values?.city);
      setSelectedState(values?.state)
      setAdminEmail(values?.clinicAdmin?.email);
      setAdminFirstName(Array.isArray(name) ? name[1] : "");
      setAdminLastName(Array.isArray(name) ? name[2] : "");
      setAdminTitle(Array.isArray(name) ? name[0] : "");
      setAdminPhone(values?.clinicAdmin?.phone);
      setSchoolName(values?.name);
      setBoard(textFormat(values?.type));
      setSpeciality(spec);
      setEstablished(values?.establishment);
      setEmail(values?.email);
      setPhoneNumber(values?.phone);
      setPincode(values?.pincode);
      setAmenities(
        Array.isArray(values?.ameneties) ? values?.ameneties[0]?.split(",") : []
      );
      setTeacher(values?.doctor);
      setStaff(values?.staff);
      setStudent(values?.nurse);
      setOrgId(location.state?.clinicCode ?? "");
    }
  }, [schoolData]);

  const handleCustomPackageChange = () => {
    const earjoin = ear.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const eyejoin = eye.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const abdomenjoin = abdomen.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const vitalSignsjoin = vitalSigns.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const hairjoin = hair.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const dentaljoin = dental.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const throatjoin = throat.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const heartjoin = heart.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const lungsjoin = lungs.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const skinjoin = skin.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const behavoraljoin = behavioral.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    const learningjoin = learning.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))

    const params = {
      tempPlanName: planName.toUpperCase().split(" ").join("_"),
      schoolId: location.state?.schoolId,
      schoolCode: location.state?.schoolCode,
      ear: earjoin,
      eye: eyejoin,
      abdomen: abdomenjoin,
      hair: hairjoin,
      skin: skinjoin,
      throat: throatjoin,
      heart: heartjoin,
      lungs: lungsjoin,
      vitalSigns: vitalSignsjoin,
      dental: dentaljoin,
      behavoral: behavoraljoin,
      learning: learningjoin,
    };

    console.log(params)

    dispatch(updatePackage(params))

  }

  const handlePackageChange = async () => {
    let vitalSigns
    let hair
    let eye
    let ear
    let dental
    let throat
    let heart
    let lungs
    let abdomen
    let skin
    let behavioral
    let learning
    if (tempPlanName === "Basic") {
      vitalSigns = (["Temperature", "Height and Weight", "SpO2", "Heart Rate"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      hair = ([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      eye = (["Myopia", "Hyperopia"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      ear = (["Otitis Media", "Otitis Externa", "Ear Wax", "Foreign Body in Canal"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      dental = (["Gingivitis", "Orthodontic Problem", "Decoloration"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      throat = (["Throat Congestion"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      heart = ([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      lungs = ([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      abdomen = ([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      skin = ([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      behavioral = (["ADHD", "Autism", "Anxiety", "Digital Dependency"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      learning = ([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
    } else if (tempPlanName === "Advanced") {
      vitalSigns = (["Temperature", "Height and Weight", "SpO2", "Heart Rate", "Hemoglobin", "Blood Pressure"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      hair = (["Color of Hair", "Dandruff", "Pediculosis (louse/lice)", "Loss of Hair"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      eye = (["Myopia", "Hyperopia", "Astigmatism", "Anisocoria", "Anisometropia", "Gaze Asymmetry"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      ear = (["Otitis Media", "Otitis Externa", "Ear Wax", "Foreign Body in Canal", "Hearing"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      dental = (["DMFT", "Gingivitis", "Orthodontic Problem", "Decoloration"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      throat = (["Stomatitis", "Glossitis", "Pharyngitis", "Tonsillitis", "White Patches", "Throat Congestion"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      heart = (["Abnormal Heart Sound", "Arrhythmia", "Heart Murmur", "Rhythm Disorder"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      lungs = (["Respiratory Sounds", "Dyspnea", "Wheezing", "Rattling", "Cough"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      abdomen = (["Bowel Obstruction", "Abdominal Bruit", "Intestinal Sounds", "Paralytic Ileus"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      skin = (["Scabies", "Rashes", "Blisters", "Urticaria"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      behavioral = (["ADHD", "Autism", "Anxiety", "Conduct Disorder", "Oppositional Defiant Disorder", "Digital Dependency", "Depression", "Development Milestones"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      learning = (["Dyslexia", "Dyscalculia", "Dysgraphia"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
    } else if (tempPlanName === "Certificate Plus") {
      vitalSigns = (["Temperature", "Height and Weight", "SpO2", "Heart Rate", "Hemoglobin", "Blood Pressure"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      hair = (["Color of Hair", "Dandruff", "Pediculosis (louse/lice)", "Loss of Hair"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      eye = (["Myopia", "Hyperopia"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      ear = (["Otitis Media", "Otitis Externa", "Ear Wax", "Foreign Body in Canal"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      dental = (["DMFT", "Gingivitis", "Orthodontic Problem", "Decoloration"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      throat = (["Throat Congestion"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      heart = ([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      lungs = (["Respiratory Sounds", "Cough"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      abdomen = ([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      skin = (["Scabies", "Rashes", "Blisters", "Urticaria"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      behavioral = (["ADHD", "Autism", "Anxiety", "Digital Dependency"].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
      learning = ([].map((x) => x?.toUpperCase()?.split(" ")?.join("_")))
    }
    else {
      const packageDetails = schoolData.screeningPackageDetails;

      vitalSigns = packageDetails?.vitalSigns.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      hair = packageDetails?.hair.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      eye = packageDetails?.eye.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      ear = packageDetails?.ear.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      dental = packageDetails?.dental.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      throat = packageDetails?.throat.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      heart = packageDetails?.heart.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      lungs = packageDetails?.lungs.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      abdomen = packageDetails?.abdomen.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      skin = packageDetails?.skin.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      behavioral = packageDetails?.behavioralScreening.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      learning = packageDetails?.learningScreening.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      ear = packageDetails?.ear?.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      eye = packageDetails?.eye?.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
    }

    const params = {
      tempPlanName: tempPlanName.toUpperCase().split(" ").join("_"),
      schoolId: location.state?.schoolId,
      schoolCode: location.state?.schoolCode,
      ear,
      eye,
      abdomen,
      hair,
      skin,
      throat,
      heart,
      lungs,
      vitalSigns,
      dental,
      behavoral: behavioral,
      learning,
    };

    if (compareDate(screeningFrom, screeningTo)) {

      await dispatch(updatePackage(params));
      // handleClick();
      if (!loadingSchool && !errorSchool) {
        // 
        // setTimeout(() => navigate(-1), 5000);
      }
    } else {
      alert("From date must be less than to date");
    }
    handleCloseModal()
  }

  useEffect(() => {
    blob2img()
  }, [logoRes])

  const textFormat = (text) => {
    
    if (text === "SPO2") {
      return 'SpO2'
    } else if (text === "HEIGHT_AND_WEIGHT") {
      return "Height and Weight"
    } else if (text === "COLOR_OF_HAIR") {
      return "Color of Hair"
    } else if (text === "Foreign_Body_In_Canal".toUpperCase()) {
      return "Foreign Body in Canal"
    } else if (text === "DMFT") {
      return "DMFT"
    } else if (text === "ADHD") {
      return "ADHD"
    } else if (text === "LOSS_OF_HAIR") {
      return "Loss of Hair"
    } else if (text === "GAZE_ASSYMETRY") {
      return "Gaze Assymetry"
    } else if (text === "OTITIS_MEDIA") {
      return "Otitis Media"
    }
    text = text?.split("_")
    let a = ""
    for (let i = 0; i < text?.length; i++) {
      const element = text[i];
      const x = element.slice(0, 1).toUpperCase() + element.slice(1)?.toLowerCase()
      if (i === 0) {
        a = a + x
      } else {
        a = a + " " + x;
      }
    }
    
    return a
  }

  useEffect(() => {
    const packageDetails = schoolData?.screeningPackageDetails;

    const earText = packageDetails?.ear?.map((x) => textFormat(x))
    const eyeText = packageDetails?.eye?.map((x) => textFormat(x))
    const abdomenText = packageDetails?.abdomen?.map((x) => textFormat(x))
    const vitalSignsText = packageDetails?.vitalSigns?.map((x) => textFormat(x))
    const hairText = packageDetails?.hair?.map((x) => textFormat(x))
    const dentalText = packageDetails?.dental?.map((x) => textFormat(x))
    const throatText = packageDetails?.throat?.map((x) => textFormat(x))
    const heartText = packageDetails?.heart?.map((x) => textFormat(x))
    const lungsText = packageDetails?.lungs?.map((x) => textFormat(x))
    const skinText = packageDetails?.skin?.map((x) => textFormat(x))
    const behavoralText = packageDetails?.behavioralScreening?.map((x) => textFormat(x))
    const learningText = packageDetails?.learningScreening?.map((x) => textFormat(x))
    setVitalSigns(vitalSignsText ?? [])
    setHair(hairText ?? [])
    setEye(eyeText ?? [])
    setEar(earText ?? [])
    setDental(dentalText ?? [])
    setThroat(throatText ?? [])
    setHeart(heartText ?? [])
    setLungs(lungsText ?? [])
    setAbdomen(abdomenText ?? [])
    setSkin(skinText ?? [])
    setBehavioral(behavoralText ?? [])
    setLearning(learningText ?? [])
  }, [schoolData],)

  useEffect(() => {
    if (orgId) {
      fetchOrganizationLogo();
    }
  }, [orgId]);

  useEffect(() => {
    handleUploadFileOnServer();
  }, [logoRes]);

  const fetchOrganizationLogo = async () => {
    return await API.get(
      "MyLogoAPIGateway",
      `/${orgId}.jpeg`,
      getAuthHeaders
    ).then((res) => {
      // 
      setSelectedFile(res.selectedFile);
    }).catch(err => console.log(err));
  };
  // 
  // 
  const handleUploadFileOnServer = async () => {
    try {
      if (orgId) {
        return await API.put("MyLogoAPIGateway", `/${orgId}.jpeg`, authHeaders);
      }
    } catch (error) {

    }
  };

  const handleUpdate = async () => {
    if (entity === "school") {
      const earjoin = ear.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const eyejoin = eye.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const abdomenjoin = abdomen.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const vitalSignsjoin = vitalSigns.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const hairjoin = hair.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const dentaljoin = dental.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const throatjoin = throat.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const heartjoin = heart.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const lungsjoin = lungs.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const skinjoin = skin.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const behavoraljoin = behavioral.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      const learningjoin = learning.map((x) => x?.toUpperCase()?.split(" ")?.join("_"))
      let img
      
      if (logoRes && !(typeof logoRes === "string")) {
        img = await toBase64();
      }
      
      const params = {
        address: address,
        state: selectedState,
        city: selectedCity,
        contact: {
          email: email,
          name: "Dummy Name",
          mobNumber: "123456789",
          officeNumber: phoneNumber,
        },
        planName: planName.toUpperCase().split(" ").join("_"),
        schoolAdmin: {
          name: adminTitle + " " + adminFirstName + " " + adminLastName,
          email: adminEmail,
          phone: adminPhone,
        },
        staffAndAmenities: {
          teachers: teacher,
          infirmary: { doctor: doctor, nurse: nurse },
          amenitiesAvailable: amenities.join(","),
          counsellors: { counsellorsCount: counsellor },
        },
        name: "Dummy Name2",
        pincode: parseInt(pincode),
        schoolName: schoolName,
        strength: parseInt(student),
        url: "",
        board: board,
        organization: organization,
        annualOrientationMonth: orientation,
        establishedFrom: established,
        screeningPlan: screeningPlan,
        screeningFrom: screeningFrom,
        screeningTo: screeningTo,
        schoolCode: schoolCode,
        schoolId: location.state?.schoolId,
        ear: earjoin,
        eye: eyejoin,
        abdomen: abdomenjoin,
        hair: hairjoin,
        skin: skinjoin,
        throat: throatjoin,
        heart: heartjoin,
        lungs: lungsjoin,
        vitalSigns: vitalSignsjoin,
        dental: dentaljoin,
        behavoral: behavoraljoin,
        learning: learningjoin,
        logo: JSON.stringify(img)
      };

      if (compareDate(screeningFrom, screeningTo)) {

        dispatch(updateSchool(params));
        handleClick();
        // setLogoRes(true);
        if (!loadingSchool && !errorSchool) {

          // setTimeout(() => navigate(-1), 5000);
        }
      } else {
        alert("From date must be less than to date");
      }
    } else {
      const params = {
        clinicCode: location.state?.clinicCode,
        address: address,
        city: selectedCity,
        clinicAdmin: {
          email: adminEmail,
          name: `${adminTitle} ${adminFirstName} ${adminLastName}`,
          phone: adminPhone,
        },
        name: schoolName,
        type: board,
        speciality: speciality,
        establishment: established,
        email: email,
        mobNumber: phoneNumber,
        officeNumber: phoneNumber,
        pincode: pincode,
        ameneties: amenities,
        doctor: teacher,
        staff: staff,
        nurse: student,
      };

      dispatch(updateClinic(params));
      handleClick();
      // setLogoRes(true);
      if (!loadingClinic && !errorClinic) {
        setTimeout(() => navigate(-1), 5000);
      }
    }
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    setSelectedFile(base64);
  };

  const blob2img = async () => {
    
    if (logoRes && typeof logoRes === "object") {
      var reader = new FileReader();
      reader.readAsDataURL(logoRes);
      reader.onloadend = function () {
        var base64data = reader.result;
        
        setImageShow(base64data)
      }
    }
    else {
      setImageShow(logoRes)
    }
    // setImageShow(x?.data)
  }

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={modal_header}>Change School Plan</div>
          <div style={modal_header_info}>
            {`Are you sure you want to change screening plan? By choosing yes, you confirm to change the screening plan.`}
          </div>

          <div style={action_btn_container}>
            <button style={action_btn} onClick={handleCloseModal}>
              No
            </button>
            <button onClick={handlePackageChange} style={black_btn}>Yes</button>
          </div>
        </Box>
      </Modal>
      <div className={classes.container}>
        <div className={classes.upload_logo}>
          {!logoRes ? (
            <div className={classes.no_upload_logo}>
              <ImageRoundedIcon
                sx={{ fill: "#E8E7E7", width: "56px", height: "56px" }}
              />
            </div>
          ) : (
            <img alt="" src={imageShow} className={classes.uploadLogo} />
          )}
          {/* <img
            alt=""
            src={selectedFile ? selectedFile : schoollogo}
            className={classes.uploadLogo}
          ></img> */}
          {/* <div className={classes.upload}>
            <img alt="" src={upload}></img>
            <div className={classes.upload_text}>Upload {finalLetter} Logo</div>
          </div> */}
          <div className={classes.upload}>
            <label
              className={classes.uploadLabel}
              htmlFor="openUpload"
              style={{ color: "#3b3cea" }}
            >
              {" "}
              <img alt="" src={upload}></img> Upload Photo
            </label>
            <input
              id="openUpload"
              className={classes.uploadPhoto}
              type="file"
              accept="image/jpeg,"
              onChange={(e) => setLogoRes(e.target.files[0])}
            />
          </div>
        </div>
        <div className={classes.input_school_layout}>
          <InputDashboard
            placeholder={`${finalLetter} name`}
            width={"48vw"}
            className={classes.input_layout_schoolname}
            change={(e) => {

              setSchoolName(e.target.value);
            }}
            bgColor="#ffffff"
            value={schoolName}
          />
          <InputDropdown
            placeholder={`${entity === "school" ? "Board/Affiliation" : "Type"
              }`}
            width={"23vw"}
            className={classes.input_layout_boardname}
            bgColor="#ffffff"
            value={board}
            setValue={setBoard}
            defaultValues={
              entity === "school"
                ? ["CBSE", "ICSE", "IB", "STATEBOARD"]
                : ["Affiliated", "Skids Owned"]
            }
          />
        </div>
        {entity === "school" ? (
          <div>
            <div className={classes.input_layout}>
              <InputDropdown
                placeholder={"Organization"}
                width={"23vw"}
                bgColor="#ffffff"
                value={organization}
                setValue={setOrganization}
                defaultValues={["Aided", "Unaided", "Government"]}
                margin={2.4}
              />
              <InputDropdown
                placeholder={"Annual orientation month"}
                width={"23vw"}
                value={orientation}
                setValue={setOrientation}
                bgColor="#ffffff"
                margin={2.4}
                defaultValues={[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ]}
              />
              {/* <InputDropdown
                placeholder={"Package"}
                width={"23vw"}
                bgColor="#ffffff"
                margin={2.4}
                value={planName}
                setValue={setPlanName}
                defaultValues={["Basic", "Comprehensive", "Certificate Plus"]}
              /> */}
              <FormControl
                variant="outlined"
                sx={{
                  width: "23vw",
                  height: "6.5vh",
                  mt: 2.4,
                }}
                required
              >
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{
                    backgroundColor: "#FFF",
                    color: "#9d9d9d !important",
                    fontFamily: "Inter",
                    fontSize: "14px"
                  }}
                  size="small"
                >
                  {"Package"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  onChange={(e) => {
                    handleOpenModal(e.target.value);
                    setTempPlanName(e.target.value);
                  }}
                  size="small"
                  value={planName}
                  sx={{
                    backgroundColor: "#FFF",
                    "&:hover": {
                      backgroundColor: "#FFF",
                      color: '#9d9d9d'
                    },
                    "&:active": {
                      backgroundColor: "#FFF",
                      color: '#9d9d9d'
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      borderRadius: "8px",
                      color: '#9d9d9d !important'
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      color: '#9d9d9d !important'
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E8E7E7",
                      color: '#9d9d9d !important'
                    },
                    "& label.Mui-focused": {
                      color: "#9d9d9d !important"
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        color: "#9d9d9d !important"
                      }
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "#6F7E90 !important",
                    },
                  }}
                >
                  {["Basic", "Advanced", "Certificate Plus", "Custom"]?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className={classes.input_layout}>
              <InputDropdown
                placeholder={"Screening Plan"}
                width={"23vw"}
                bgColor="#ffffff"
                selected={screeningPlan}
                value={screeningPlan}
                margin={2.4}
                setValue={setScreeningPlan}
                defaultValues={["Annually", "Quarterly", "Monthly"]}
              />
              <DatePickerInput
                onChange={(e) => {
                  setScreeningFrom(e.target.value);
                }}
                width={"23vw"}
                placeholder={"Screening From"}
                value={screeningFrom}
                disabled={true}
              />
              <DatePickerInput
                onChange={(e) => {
                  setScreeningTo(e.target.value);
                }}
                width={"23vw"}
                placeholder={"Screening To"}
                value={screeningTo}
                disabled={true}
              />
            </div>
            <div className={classes.input_layout}>
              <DatePickerInput
                onChange={(e) => {
                  setEstablished(e.target.value);
                }}
                width={"23vw"}
                placeholder={"Established from"}
                value={established}
              />
              {/* <div className={classes.package_drawer}>
                <span onClick={() => setShowPackageDrawer(!showPackageDrawer)}>
                  Edit Package
                </span>
              </div> */}
              {planName === "Custom" && <div className={classes.package_drawer}>
                <PackageDrawer
                  planName={planName}
                  showPackageDrawer={showPackageDrawer}
                  setShowPackageDrawer={setShowPackageDrawer}
                  vitalSigns={vitalSigns}
                  setVitalSigns={setVitalSigns}
                  hair={hair}
                  setHair={setHair}
                  eye={eye}
                  setEye={setEye}
                  ear={ear}
                  setEar={setEar}
                  dental={dental}
                  setDental={setDental}
                  throat={throat}
                  setThroat={setThroat}
                  heart={heart}
                  setHeart={setHeart}
                  abdomen={abdomen}
                  setAbdomen={setAbdomen}
                  lungs={lungs}
                  setLungs={setLungs}
                  skin={skin}
                  setSkin={setSkin}
                  behavioral={behavioral}
                  setBehavioral={setBehavioral}
                  learning={learning}
                  setLearning={setLearning}
                  handlePackageChange={handleCustomPackageChange}
                />
              </div>}
            </div>
          </div>
        ) : (
          <></>
        )}

        {entity === "clinic" ? (
          <div>
            <div className={classes.input_layout}>
              <InputDropdown
                margin={2.4}
                placeholder={"Speciality"}
                width={"23vw"}
                bgColor="#ffffff"
                value={speciality}
                setValue={setSpeciality}
                defaultValues={[
                  "Multi speciality",
                  "Super speciality",
                  "Advisory",
                ]}
              />
              <DatePickerInput
                onChange={(e) => {
                  setEstablished(e.target.value);
                }}
                width={"23vw"}
                placeholder={"Established from"}
                value={established}
              />
              {/* <BasicDatePicker /> */}
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className={classes.contact_info}>Contact information</div>
        <div className={classes.input_layout}>
          <InputDashboard
            placeholder={"Email id"}
            width={"23vw"}
            change={(e) => {

              setEmail(e.target.value);
            }}
            bgColor="#ffffff"
            value={email}
            margin={2.4}
          />
          <InputDashboard
            margin={2.4}
            placeholder={"Phone number"}
            width={"23vw"}
            change={(e) => {

              setPhoneNumber(e.target.value);
            }}
            bgColor="#ffffff"
            value={phoneNumber}
          />
        </div>
        <InputDashboard
          margin={2.4}
          placeholder={`${finalLetter} address`}
          width={"48vw"}
          change={(e) => {

            setAddress(e.target.value);
          }}
          bgColor="#ffffff"
          value={address}
        />
        <div className={classes.input_layout}>
          <InputDashboard
            margin={2.4}
            placeholder={"Pincode"}
            width={"23vw"}
            change={(e) => {

              setPincode(e.target.value);
            }}
            bgColor="#ffffff"
            value={pincode}
          />
          <InputDropdown
            placeholder={"State"}
            width={"23vw"}
            margin={2.4}
            bgColor="#ffffff"
            value={selectedState}
            setValue={setSelectedState}
            defaultValues={state}
          />
          <InputDropdown
            placeholder={"City"}
            margin={2.4}
            width={"23vw"}
            bgColor="#ffffff"
            value={selectedCity}
            setValue={setSelectedCity}
            defaultValues={city}
          />
        </div>
        <div className={classes.contact_info}>Staff & Ameneties</div>
        <div className={classes.input_layout}>
          <InputDashboard
            margin={2.4}
            placeholder={`${entity === "school" ? "Teachers" : "Doctors"}`}
            width={"23vw"}
            change={(e) => {
              setTeacher(e.target.value);
            }}
            bgColor="#ffffff"
            value={teacher}
          />
          <InputDashboard
            margin={2.4}
            placeholder={`${entity === "school" ? "Students" : "Nurses"}`}
            width={"23vw"}
            change={(e) => {
              setStudent(e.target.value);
            }}
            bgColor="#ffffff"
            value={student}
          />
          {entity === "school" ? (
            <InputDashboard
              placeholder={"Counsellors"}
              width={"23vw"}
              bgColor="white"
              value={counsellor}
              margin={2.4}
              change={(e) => {
                setCounsellor(e.target.value);
              }}
            />
          ) : (
            <InputDashboard
              margin={2.4}
              placeholder={"Staff"}
              width={"23vw"}
              change={(e) => {
                setStaff(e.target.value);
              }}
              bgColor="#ffffff"
              value={staff}
            />
          )}
        </div>
        {entity === "clinic" ? (
          <div className={classes.input_layout}>
            <MultipleSelectChip
              entity={entity}
              amenities={amenities}
              setAmenities={setAmenities}
              options={["Ambulance", "Beds", "ECU", "ICU", "Operation Theatre"]}
              width={"23vw"}
              margin={2.4}
            />
          </div>
        ) : (
          <></>
        )}

        {entity === "school" ? (
          <div>
            <div className={classes.input_layout}>
              <InputDashboard
                placeholder={"Doctor"}
                width={"23vw"}
                margin={2.4}
                change={(e) => {
                  setDoctor(e.target.value);
                }}
                bgColor="#ffffff"
                value={doctor}
              />
              <InputDashboard
                placeholder={"Nurse"}
                width={"23vw"}
                margin={2.4}
                change={(e) => {
                  setNurse(e.target.value);
                }}
                bgColor="#ffffff"
                value={nurse}
              />
              {/* <BasicDatePicker /> */}
              <MultipleSelectChip
                entity={entity}
                amenities={amenities}
                setAmenities={setAmenities}
                options={[
                  "Medical Room",
                  "Infirmary Bed",
                  "First Aid Box",
                  "Sanitization",
                  "Thermometer",
                  "BP Machine",
                  "Ambulance",
                  "Oxygen Support",
                ]}
                width={"23vw"}
                margin={2.4}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className={classes.entity_admin_header}>
          {finalLetter} Admin registration information
        </div>
        <div className={classes.input_layout}>
          <InputDashboard
            margin={2.4}
            placeholder={"Email id"}
            width={"23vw"}
            change={(e) => {

              setAdminEmail(e.target.value);
            }}
            bgColor="#ffffff"
            value={adminEmail}
            disabled={true}
          />
        </div>
        <div className={classes.entity_admin_text}>
          New user login credentials will be sent to this email id
        </div>
        <div className={classes.contact_info}>Personal information</div>
        {/* <div className={classes.lettered_avatar_container}>
          <Avatar
            sx={{
              height: 96,
              width: 96,
              fontSize: 72,
              fontWeight: "bold",
              backgroundColor: "#4780f5",
            }}
          >
            {firstLetterEntity}
          </Avatar>
        </div> */}

        <div className={classes.input_admin_layout}>
          <InputDropdown
            margin={2.4}
            placeholder={`Title`}
            width={"7vw"}
            bgColor="#ffffff"
            value={adminTitle}
            setValue={setAdminTitle}
            defaultValues={["Mr.", "Mrs."]}
            disabled={true}
          />
          <InputDashboard
            margin={2.4}
            placeholder={`First Name`}
            width={"23vw"}
            change={(e) => {

              setAdminFirstName(e.target.value);
            }}
            bgColor="#ffffff"
            value={adminFirstName}
            disabled={true}
          />
          <InputDashboard
            margin={2.4}
            placeholder={`Last Name`}
            width={"23vw"}
            change={(e) => {

              setAdminLastName(e.target.value);
            }}
            bgColor="#ffffff"
            value={adminLastName}
            disabled={true}
          />
        </div>
        <div className={classes.contact_info}>Contact information</div>
        <div className={classes.input_layout}>
          <div><InputDashboard
            margin={2.4}
            placeholder={`Phone number`}
            width={"23vw"}
            change={(e) => {

              setAdminPhone(e.target.value);
            }}
            bgColor="#ffffff"
            value={adminPhone}
            disabled={true}
          />
            {adminNumberError &&
              <div className={classes.validation_text}>
                {" "}
                Please fill valid 10 digit number*
              </div>
            }
          </div>
        </div>
        {(entity === "clinic" ? !loadingClinic : !loadingSchool) && (
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={
                (entity === "clinic" ? !errorClinic : !errorSchool)
                  ? "success"
                  : "error"
              }
              sx={{ width: "100%" }}
            >
              {(entity === "clinic" ? !errorClinic : !errorSchool)
                ? `${entity === "school" ? "School" : "Clinic"
                } Successfully Updated!`
                : `Error while Updating ${entity === "school" ? "School" : "Clinic"
                }`}
            </Alert>
          </Snackbar>
        )}
        <div className={classes.buttons}>
          <button className={classes.button_with_border} onClick={() => navigate(-1)}>
            {/* {schoolName &&
              amenities &&
              board &&
              orientation &&
              city &&
              state &&
              infirmary &&
              amenities.length > 0
              ? "Cancel"
              : "Save"} */}
            Cancel
          </button>
          <button
            onClick={handleUpdate}
            className={classes.button_filled}
            disabled={screeningFrom && screeningTo ? false : true}
          >
            Update {finalLetter}
          </button>
        </div>
      </div>
    </div>
  );
}

function EditOrganization() {
  return <EditOrganizationForm />;
}

export default EditOrganization;
