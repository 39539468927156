import { API, graphqlOperation } from 'aws-amplify';
import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';



function getOpsDashboard() {
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `query MyQuery($date: AWSDate = "${moment().format("YYYY-MM-DD")}") {
            getOpsDashboard(date: $date) {
              screeningCount
              school {
                address
                name
                board
                schoolName
                annualOrientationMonth
                city
                contact {
                  email
                  mobNumber
                  name
                  officeNumber
                }
                  email
                  name
                  phone
                }
                schoolCode
                schoolId
                screeningFrom
                screeningPlan
                screeningTo
                staffAndAmenities {
                  amenitiesAvailable
                  counsellors
                  infirmary
                  teachers
                }
                state
                url
                updatedAt
                strength
              }
              eventMap {
                class
                section
                createdAt
                date
                eventId
                schoolId
                updatedAt
              }
            }
          }`,
    authToken: token
  })
}


function getScreeningById(payload) {
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `query MyQuery {
      getScreeningFiles(screeningId: "${payload.payload}") {
        screeningId
        dentalDoctor
        defectImagesUrl
        dentalValidatedOn
        behavioralDoctor
        behavioralValidatedOn
        physicalDoctor
        physicalValidatedOn
        eventId
        mappingId
        status
        package
        behavioralId
        bhvScreening
screening {
          defects {
            comment
            onType
            url
          }
        }
        screeningPackageDetails {
          abdomen
          behavioralScreening
          dental
          ear
          eye
          hair
          heart
          learningScreening
          lungs
          skin
          throat
          vitalSigns
        }
        ent {
          entThroatVideo
          entLeftEarVideo
          entRightEarVideo
          entDentalVideo_1
          entDentalVideo_2
          entHeartAudio
          entLungsAudio_1
          entLungsAudio_2
        }
        higo {
          abdominal
          cough
          leftEar
          rightEar
          heart
          lungs
          skin
          throat
          rightEarVideo
          leftEarVideo
          throatVideo
          skinVideo
          heartWav
        }
        amplivox{
          leftEarImage,
          rightEarImage,
          leftEarGraph,
          rightEarGraph
      }
        earAssessment {
          leftEarInterpretation
          leftEarValidation {
            issue
            interpretation
          }
          rightEarInterpretation
          rightEarValidation {
            issue
            interpretation
          }
        }
        entAssessment {
          heartRate
          heartReportUrl
          lungsReportUrl_1
          lungsReportUrl_2
          vitals {
            BMI
            height
            weight
            bodyShapeIndex
            faceAge
            heartRate
            heartRateVariability
            mindStressIndex
            respirationRate
            waistToHeightRatio
            wellnessScore
          }
        }
        coughAssessment {
          validation {
            issue
            interpretation
          }
          validationInterpretation
        }
        dentalAssessment {
          DMFTIndex
          DMFTIndexInterpretation
          decoloration
          decolorationInterpretation
          gingivitis
          gingivitisInterpretation
          orthodontic
          orthodonticInterpretation
          tooth
        }
        eyeAssessment {
          validation {
            issue
            interpretation
          }
          validationInterpretation
        }
        hairAssessment {
          color
          colorInterpretation
          dandruff
          dandruffInterpretation
          lossOfHair
          lossOfHairInterpretation
          pediculosis
          pediculosisInterpretation
        }
        hearingAssessment {
          hearingReport
          reportUrl
          validation {
            issue
            interpretation
          }
          validationInterpretation
        }
        heartAssessment {
          auscultationInterpretation
          auscultationValidation {
            issue
            interpretation
          }
          estimationInterpretation
          estimationValidation {
            issue
            interpretation
          }
          heartReportUrl
        }
        lungsAssessment {
          auscultationInterpretation
          auscultationValidation {
            issue
            interpretation
          }
          lungReportUrl_1
          lungReportUrl_2
        }
        skinAssessment {
          validation {
            issue
            interpretation
          }
          validationInterpretation
        }
        student {
          age
          class
          gender
          name
          screeningDate
          section
        }
        throatAssessment {
          validation {
            issue
            interpretation
          }
          validationInterpretation
        }
        abdomenAssessment{
          validation {
            issue
            interpretation
          }
          validationInterpretation
        }
        vitalSignsAssessment {
          BMI
          BMIInterpretation
          SpO2
          SpO2Interpretation
          bloodPressure
          bloodPressureInterpretation
          heartRate
          heartRateInterpretation
          height
          hemoglobin
          hemoglobinInterpretation
          respiratoryRate
          temperature
          temperatureInterpretation
          weight
        }
        welchAllyn {
          otherVisionDetails
          potentialConditionImage
          visionScreeningImage
        }
      }
    }
  `,
    authToken: token
  })
}

function getBehavioralScreeningById(payload) {
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `query MyQuery {
      getBhvScreening(behavioralId : "${payload.payload}") {
        patientId
        screeningId
        AUTISM
        PERFORMANCE
        CONDUCT_DISORDER
        ODD
        ADHD
        EMOTIONAL_AND_BEHAVIORAL
        DEVELOPMENTAL_DELAY
        ANXIETY
        INTERNET_ADDICTION
        DEPRESSION
        Package
        screeningPackageDetails {
          abdomen
          behavioralScreening
          dental
          ear
          eye
          hair
          heart
          learningScreening
          lungs
          skin
          throat
          vitalSigns
        }
        partOf
        updatedAt
        createdAt
      }
    }`,
    authToken: token
  })
}

function getOpsScreening(payload) {
  // 
  const token = Cookies.get('tokenId');
  return API.graphql({
    query: `query MyQuery {
            getScreening(screeningId: "${payload?.screeningId}") {
              screeningId
              studentId
              schoolId
              eventId
              mappingId
              package
              firstName
              mappingId
              screeningDate
              auditTrail
              status
              studentId
              createdAt
              updatedAt
              reExam
              screeningPackageDetails {
                abdomen
                behavioralScreening
                dental
                ear
                eye
                hair
                heart
                learningScreening
                lungs
                skin
                throat
                vitalSigns
              }
              defects {
                comment
                url
                onType
              }
              vitalSignsAssessment {
                weight
                temperature
                respiratoryRate
                hemoglobin
                height
                heartRate
                bloodPressure
                SpO2
                BMI
              }
              hairAssessment {
                color
                dandruff
                lossOfHair
                pediculosis
              }
              hearingAssessment {
                reportUrl
              }
              dentalAssessment {  
                DMFTIndex
                decoloration
                gingivitis
                orthodontic
                fillingPresent
                fillingPresentInterpretation
                missedTooth
                missedToothInterpretation
                poorOralHygiene
                poorOralHygieneInterpretation
                cariesDecay
                cariesDecayInterpretation
                tooth
              }
              screeningStatus {
                abdomin {
                  auscultation
                }
                cough {
                  exam
                }
                dental {
                  decoloration
                  gingivitis
                  orthodontic
                  tooth
                  resultAwait
                }
                ear {
                  leftEarVideo
                  rightEarVideo
                }
                hearing{
                  hearingAssessment
                  resultAwait
                }
                eye {
                  normalVision
                }
                hair  {
                  color
                  dandruff
                  lossOfHair
                  pediculosis
                }
                lungs {
                  auscultation
                }
                heart {
                  estimation
                }
                skin {
                  video
                }
                throat {
                  video
                }
                vitalSigns {
                  SpO2
                  bloodPressure
                  heartRate
                  height
                  heightAwait
                  hemoglobin
                  temperature
                  weight
                  weightAwait
                }
              }
            }
        }`,
    authToken: token
  })
}


function updateScreeningPub(payload) {

  let testData = payload?.test;
  const token = Cookies.get('tokenId');
  console.log(payload)
  return API.graphql(graphqlOperation(
    `mutation MyMutation(
      $tooths : [String] = "${testData?.dentalAssessment?.tooth}", 
      $defects : [DefectInput],
      $auditTrail : AWSJSON
      ){
            updateScreening(
                eventId: "${payload.eventId}", 
                screeningId: "${payload.screeningId}",
                status: "${payload?.status}",
                defects: $defects
                auditTrail : $auditTrail
                screeningStatus: {
                    cough: {exam: ${testData?.screeningStatus?.cough?.exam}}, 
                    abdomin: {auscultation: ${testData?.screeningStatus?.abdomin.auscultation}}, 
                    dental: {
                        decoloration: ${testData?.screeningStatus?.dental?.decoloration}, 
                        gingivitis: ${testData?.screeningStatus?.dental?.gingivitis}, 
                        orthodontic: ${testData?.screeningStatus?.dental?.orthodontic}, 
                        tooth: ${testData?.screeningStatus?.dental?.tooth}
                        resultAwait : ${testData?.screeningStatus?.dental?.resultAwait}
                    }, 
                    ear: {
                        rightEarVideo: ${testData?.screeningStatus?.ear?.rightEarVideo}, 
                        leftEarVideo: ${testData?.screeningStatus?.ear?.leftEarVideo}
                    }, 
                    eye: {normalVision: ${testData?.screeningStatus?.eye?.normalVision}}, 
                    hair: {
                        color: ${testData?.screeningStatus?.hair?.color}, 
                        lossOfHair: ${testData?.screeningStatus?.hair?.lossOfHair}, 
                        pediculosis: ${testData?.screeningStatus?.hair?.pediculosis}, 
                        dandruff: ${testData?.screeningStatus?.hair?.dandruff}
                    }, 
                    hearing: {
                      hearingAssessment: ${testData?.screeningStatus?.hearing?.hearingAssessment},
                      resultAwait  : ${testData?.screeningStatus?.hearing?.resultAwait}
                    }, 
                    heart: {estimation: ${testData?.screeningStatus?.heart?.estimation}}, 
                    lungs: {auscultation: ${testData?.screeningStatus?.lungs?.auscultation}}, 
                    skin: {video: ${testData?.screeningStatus?.skin?.video}}, 
                    throat: {video: ${testData?.screeningStatus?.throat?.video}}, 
                    vitalSigns: {
                        SpO2: ${testData?.screeningStatus?.vitalSigns?.SpO2}, 
                        bloodPressure: ${testData?.screeningStatus?.vitalSigns?.bloodPressure}, 
                        heartRate: ${testData?.screeningStatus?.vitalSigns?.heartRate}, 
                        height: ${testData?.screeningStatus?.vitalSigns?.height}, 
                        hemoglobin: ${testData?.screeningStatus?.vitalSigns?.hemoglobin}, 
                        weight: ${testData?.screeningStatus?.vitalSigns?.weight}, 
                        temperature: ${testData?.screeningStatus?.vitalSigns?.temperature}
                        heightAwait: ${testData?.screeningStatus?.vitalSigns?.heightAwait},
                        weightAwait: ${testData?.screeningStatus?.vitalSigns?.weightAwait}
                    }
                }, 
                dentalAssessment: {
                    tooth: $tooths, 
                    orthodontic: ${testData?.dentalAssessment?.orthodontic}, 
                    gingivitis: ${testData?.dentalAssessment?.gingivitis}, 
                    decoloration: ${testData?.dentalAssessment?.decoloration},
                    fillingPresent : ${testData?.dentalAssessment?.fillingPresent},
                    missedTooth : ${testData?.dentalAssessment?.missedTooth},
                    cariesDecay : ${testData?.dentalAssessment?.cariesDecay},
                    poorOralHygiene : ${testData?.dentalAssessment?.poorOralHygiene}, 
                    DMFTIndex: 10
                },
                hairAssessment: {
                    color: "${testData?.hairAssessment?.color}", 
                    dandruff: ${testData?.hairAssessment?.dandruff}, 
                    lossOfHair: ${testData?.hairAssessment?.lossOfHair}, 
                    pediculosis: ${testData?.hairAssessment?.pediculosis}
                }
                hearingAssessment: {
                  reportUrl: "${testData?.hearingAssessment?.reportUrl}"
                }
                vitalSignsAssessment: {
                  weight: "${testData?.vitalSignsAssessment?.weight}",
                  temperature: "${testData?.vitalSignsAssessment?.temperature}",
                  respiratoryRate: "${testData?.vitalSignsAssessment?.respiratoryRate}",
                  bloodPressure: "${testData?.vitalSignsAssessment?.bloodPressure}",
                  height: "${testData?.vitalSignsAssessment?.height}",
                  BMI: "${testData?.vitalSignsAssessment?.BMI}",
                  hemoglobin: "${testData?.vitalSignsAssessment?.hemoglobin}",
                  heartRate: "${testData?.vitalSignsAssessment?.heartRate}",
                  SpO2: "${testData?.vitalSignsAssessment?.SpO2}",
                }
                ) {
              screeningId
              eventId
              mappingId
              package
              firstName
              mappingId
              screeningDate
              auditTrail
              status
              studentId
              createdAt
              updatedAt
              screeningPackageDetails {
                abdomen
                behavioralScreening
                dental
                ear
                eye
                hair
                heart
                learningScreening
                lungs
                skin
                throat
                vitalSigns
              }
              defects {
                comment
                onType
                url
              }
              vitalSignsAssessment {
                weight
                temperature
                respiratoryRate
                hemoglobin
                height
                heartRate
                bloodPressure
                SpO2
                BMI
              }
              hairAssessment {
                color
                dandruff
                lossOfHair
                pediculosis
              }
              hearingAssessment {
                reportUrl
              }
              dentalAssessment {  
                DMFTIndex
                decoloration
                gingivitis
                orthodontic
                fillingPresent
                fillingPresentInterpretation
                missedTooth
                missedToothInterpretation
                poorOralHygiene
                poorOralHygieneInterpretation
                cariesDecay
                cariesDecayInterpretation
                tooth
              }
              screeningStatus {
                abdomin {
                  auscultation
                }
                cough {
                  exam
                }
                dental {
                  decoloration
                  gingivitis
                  orthodontic
                  tooth
                  resultAwait
                }
                ear {
                  leftEarVideo
                  rightEarVideo
                }
                hearing{
                  hearingAssessment
                  resultAwait
                }
                eye {
                  normalVision
                }
                hair  {
                  color
                  dandruff
                  lossOfHair
                  pediculosis
                }
                lungs {
                  auscultation
                }
                heart {
                  estimation
                }
                skin {
                  video
                }
                throat {
                  video
                }
                vitalSigns {
                  SpO2
                  bloodPressure
                  heartRate
                  height
                  heightAwait
                  hemoglobin
                  temperature
                  weight
                  weightAwait
                }
              }
            }
          }`,
    {
      defects: payload.defect,
      auditTrail: JSON.stringify(payload.auditTrail)
    },
    token
  ))
}

function updateScreeningSub(payload) {
  return `subscription MySubscription {
    subscribeToUpdateScreening(eventId: "${payload.eventId}", screeningId: "${payload.screeningId}") {
      screeningId
      eventId
      mappingId
      package
      firstName
      mappingId
      screeningDate
      auditTrail
      status
      studentId
      createdAt
      updatedAt
      screeningPackageDetails {
        abdomen
        behavioralScreening
        dental
        ear
        eye
        hair
        heart
        learningScreening
        lungs
        skin
        throat
        vitalSigns
      }
      defects {
        comment
        onType
        url
      }
      vitalSignsAssessment {
        weight
        temperature
        respiratoryRate
        hemoglobin
        height
        heartRate
        bloodPressure
        SpO2
        BMI
      }
      hairAssessment {
        color
        dandruff
        lossOfHair
        pediculosis
      }
      hearingAssessment {
        reportUrl
      }
      dentalAssessment {  
        DMFTIndex
        decoloration
        gingivitis
        orthodontic
        fillingPresent
        fillingPresentInterpretation
        missedTooth
        missedToothInterpretation
        poorOralHygiene
        poorOralHygieneInterpretation
        cariesDecay
        cariesDecayInterpretation
        tooth
      }
      screeningStatus {
        abdomin {
          auscultation
        }
        cough {
          exam
        }
        dental {
          decoloration
          gingivitis
          orthodontic
          tooth
          resultAwait
        }
        ear {
          leftEarVideo
          rightEarVideo
        }
        hearing{
          hearingAssessment
          resultAwait
        }
        eye {
          normalVision
        }
        hair  {
          color
          dandruff
          lossOfHair
          pediculosis
        }
        lungs {
          auscultation
        }
        heart {
          estimation
        }
        skin {
          video
        }
        throat {
          video
        }
        vitalSigns {
          SpO2
          bloodPressure
          heartRate
          height
          heightAwait
          hemoglobin
          temperature
          weight
          weightAwait
        }
      }
    }
} `;
}

function getScreeningByStudentId(payload) {
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `query MyQuery {
            getScreeningByStudentId(studentId: "${payload.payload}") {
              screeningId
              eventId
            }
          }`,
    authToken: token
  }
  )
}

function getManagerStudentsScreeningOps(payload) {
  const token = Cookies.get('tokenId')

  return API.graphql({
    query: `query MyQuery {
      getScreeningOps(class: "${payload.payload.class ?? ""}", fromDate: "${payload.payload.fromDate}", schoolId: "${payload.payload.schoolId}", section: "${payload.payload.section ?? ""}", toDate: "${payload.payload.toDate}") {
        studentName
        class
        section
        gender
        DOB
        uuid
        qrCode
        status
      }
    }`,
    authToken: token
  })
}

function getReport(payload) {
  const token = Cookies.get('tokenId')

  return API.graphql({
    query: `query MyQuery {
      getReport(screeningId: "${payload.payload}") {
        statusCode
        data
        message
      }
    }`,
    authToken: token
  })
}

function getBhvReport(payload) {
  const token = Cookies.get('tokenId');

  return API.graphql(graphqlOperation(`
  query MyQuery(
    $types: [BhvTypesEnum],
    $screeningId: String!
    ) {
    getBhvReport(screeningId: $screeningId, types: $types) {
      data
      message
      statusCode
    }
  }
  `, { types: payload.payload.types, screeningId: payload.payload.screeningId }, token));
}


function postMasimoPhoto(payload) {
  const token = Cookies.get('tokenId');

  return API.graphql({
    query: `mutation MyMutation {
      massimoScreeningUpload(file: "${payload.payload.buffer}", screeningId: "${payload.payload.screeningId}") {
        message
        status
      }
    }`,
    authToken: token
  })
}

function toggleDevice(payload) {
  const token = Cookies.get('tokenId');

  return API.graphql({
    query: `mutation MyMutation {
      toggleDevice(
        screeningId: "${payload.payload.screeningId}"
        type : ${payload.payload.type}
        )
    }`,
    authToken: token
  })
}


function getOperator() {
  const token = Cookies.get('tokenId');

  return API.graphql({
    query: `query MyQuery {
      getHigoOperators {
        id
        name
      }
    }`,
    authToken: token
  })
}

function integrateHigo(payload) {
  const token = Cookies.get('tokenId');

  return API.graphql({
    query: `mutation MyMutation{
      addHigoPatient( 
        schoolId: "${payload.payload.schoolId}" , 
        class: "${payload.payload.class}", 
        section: "${payload.payload.section}", 
        operatorId: "${payload.payload.operator}")}`,
    authToken: token
  })
}


function welchallynIntegrate(payload) {
  const token = Cookies.get('tokenId');

  return API.graphql({
    query: `query MyQuery {
          getScreeningCsv(
            class: "${payload.payload.class}", 
            schoolId: "${payload.payload.schoolId}", 
            section: "${payload.payload.section}"
            ) {
              data
              message
              status
            }
          }`,
    authToken: token
  })
}

function updateSuperStatus(payload) {
  const token = Cookies.get('tokenId');

  return API.graphql({
    query: `mutation MyMutation {
            updateScreening(screeningId: "${payload?.screeningId}", eventId: "${payload?.eventId}", status: "${payload?.status}"){
              screeningId
              screeningDate
              schoolId
              physicalScreeningDate
              physicalScreeningAssigned
              physicalScreening
              package
              vitalSignsAssessment {
                BMI
                SpO2
                bloodPressure
                heartRate
                height
                hemoglobin
                respiratoryRate
                temperature
                weight
              }
            }
          }`,
    authToken: token
  })
}

function uploadReport(payload) {
  const token = Cookies.get("tokenId");
  const params = payload.payload

  console.log(params)

  const init = {
    method: 'POST',
    body: {
      screeningId: params?.screeningId,
      schoolId: params?.schoolId
    },
    headers: {
      "Authorization": token
    }
  }
  const config = {
    method: "PUT",
    // headers: { Authorization: token }
  };

  // console.log(init2)
  API.post('reportUpload', '', init).then((res) => {
    console.log(res?.data?.url)
    axios.put(`${res?.data?.url}`, params.logo, config).then((x) => {
      return x
    }).catch((e) => { return e })
  }).catch((err) => {
    return err
  })
}

function getWelchAllynUploadHistory(payload) {
  const token = Cookies.get('tokenId');
  // const params = payload.payload

  return API.graphql({
    query: `query MyQuery {
      getWelchUploadList(userId: "${payload}") {
        createdAt
        fileKey
        schoolId
        schoolName
        totalStudents
        status
        updatedAt
        userId
        failed
        failedLogs
        fromDate
        success
        successLogs
        toDate
      }
    }`,
    authToken: token
  })
}

function uploadWelchAllynZip(payload) {
  const token = Cookies.get('tokenId');
  // const params = payload.payload

  return API.graphql({
    query: `query MyQuery {
      getWelchallynUpload(schoolId: "${payload.schoolId}", fromDate: "${payload.fromDate}", toDate: "${payload.toDate}")
    }`,
    authToken: token
  })
}

function uploadEntZip(payload) {
  const token = Cookies.get('tokenId');
  // const params = payload.payload

  return API.graphql({
    query: `query MyQuery {
      uploadEntData(schoolId: "${payload.schoolId}")
    }`,
    authToken: token
  })
}


function markScreeningDoneOnMap(payload, callBack) {
  const token = Cookies.get('tokenId');
  API.graphql({
    query: `mutation MyMutation {
      markScreeningTrue(eventId: "${payload.eventId}", mapId: "${payload.mapId}", screeningId: "${payload.screeningId}")
    }
    `,
    authToken: token
  })
    .then((data) => {
      callBack('success', data);
    })
    .catch((err) => {
      callBack('failure', err);
    })
}
function getScreeningsBySchool(params, callBack) {
  const token = Cookies.get('tokenId');
  let x;
  if (params?.nextToken) {
    x = `schoolId: "${params.schoolId}", status: "PATIENT_ABSENT" , nextToken: "${params?.nextToken}"`
  }
  else {
    x = `schoolId: "${params.schoolId}", status: "PATIENT_ABSENT"`
  }
  return API.graphql(graphqlOperation(`query MyQuery {
  getScreeningsBySchool(${x}) {
    items {
      firstName
      behavioralId  
      studentId
      status
      class
      section
      screeningDate
      screeningId
      DOB
    }
    nextToken
  }
}`, {}, token)).then(data => {
    callBack('success', data)
  }).catch(error => {
    callBack('failure', error)
  })
}

function defectUpload(payload) {
  const token = Cookies.get('tokenId');
  return API.graphql({
    query: `query MyQuery {
      defectUpload(defectOrgan: "${payload.organ}", screeningId: "${payload.screeningId}") {
        signedURL
        URL
      }
    }`,
    authToken: token
  })
}

function hearingUpload(payload) {
  const token = Cookies.get('tokenId');
  return API.graphql({
    query: `query MyQuery {
      hearingUpload(screeningId: "${payload.screeningId}") {
        signedURL
        URL
      }
    }`,
    authToken: token
  })
}


function immediateAssign(payload) {
  const token = Cookies.get('tokenId');
  console.log("YEEEEAAAAAHHHHHH BUDDDDDDYYYYYYYY")
  return API.graphql({
    query: `mutation MyMutation {
      imidiateAssignDoctor(bhvDoctor: "${payload.bhv}", dentalDoctor: "${payload.dental}", phyDoctor: "${payload.phy}", screeningId: "${payload.screeningId}")
    }`,
    authToken: token
  })
}

function getReExam(payload, callBack) {
  const token = Cookies.get('tokenId');
  const customQuery = payload?.screeningId
    ? `userId: "${payload?.userId}", screeningId: "${payload?.screeningId}"`
    : payload?.nextToken
      ? `userId: "${payload?.userId}", nextToken: "${payload?.nextToken}"`
      : `userId: "${payload?.userId}"`;

  return API.graphql({
    query: `query MyQuery {
    getReExam(${customQuery}) {
      items {
        class
        completed
        completedOn
        createdAt
        doctorId
        doctorName
        eventId
        mappingId
        schoolId
        name
        organ {
          organName
          reason
        }
        screeningDate
        userId
        updatedAt
        status
        section
        screeningId
      }
      nextToken
    }
  }
  `,
    authToken: token
  }).then((res) => {
    callBack('success', res.data?.getReExam);
  }).catch((err) => {
    if (err?.data?.getReExam?.items?.length) {
      callBack('success', err?.data?.getReExam);
    }
    else {
      callBack('failure', err);
    }
  })
}

const screening = {
  getOpsDashboard,
  getScreeningById,
  getBehavioralScreeningById,
  getOpsScreening,
  updateScreeningPub,
  updateScreeningSub,
  getScreeningByStudentId,
  getManagerStudentsScreeningOps,
  getReport,
  postMasimoPhoto,
  toggleDevice,
  getBhvReport,
  getOperator,
  integrateHigo,
  welchallynIntegrate,
  updateSuperStatus,
  uploadReport,
  getWelchAllynUploadHistory,
  uploadWelchAllynZip,
  uploadEntZip,
  markScreeningDoneOnMap,
  defectUpload,
  hearingUpload,
  getScreeningsBySchool,
  defectUpload,
  immediateAssign,
  getReExam
}

export default screening
