import { API, graphqlOperation } from "aws-amplify";
import Cookies from "js-cookie";

function createBlog(payload) {
  const token = Cookies.get("tokenId");

  const createBlogMutation = `
    mutation CreateBlog($userId: String!, $title: String!, $description: String!, $author: String!, $thumbnail: String, $category: String!, $status: String!) {
      createBlog(
        userId: $userId
        title: $title
        description: $description
        author: $author
        thumbnail: $thumbnail
        category: $category
        status: $status
      ) {
        statusCode
        data
        message
      }
    }
  `;

  return API.graphql(
    graphqlOperation(createBlogMutation, {
      userId: payload.payload.userId,
      title: payload.payload.title,
      description: payload.payload.description,
      author: payload.payload.author,
      thumbnail: payload.payload.thumbnail,
      category: payload.payload.category,
      status: payload.payload.status
    }, token)
  );
}

function getBlogs() {
  const token = Cookies.get("tokenId");

  return API.graphql(
    graphqlOperation(
      `
      query MyQuery {
        getBlogs(count: 10, nextToken: "") {
            blogId
            userId
            title
            description
            thumbnail
            author
            category
            status
            createdAt
            updatedAt
        }
      }
    `,
      {},
      token
    )
  );
}


function updateBlogStatus(payload) {
  const token = Cookies.get("tokenId");
  const params = payload.payload;
  return API.graphql(
    graphqlOperation(
      `
      mutation MyMutation {
        updateBlogStatus(blogId: "${params.blogId}", status: "${params.status}") {
            blogId
            userId
            title
            description
            thumbnail
            author
            category
            status
            createdAt
            updatedAt
        }
      }
    `,
      {},
      token
    )
  );
}


function updateBlog(payload) {
  const token = Cookies.get("tokenId");
  const params = payload.payload;

  return API.graphql(
    graphqlOperation(`
      mutation UpdateBlog($blogId: String!, $userId: String!, $title: String!, $description: String!, $thumbnail: String, $category: String, $author: String!) {
        updateBlog(
          blogId: $blogId
          userId: $userId
          title: $title
          description: $description
          thumbnail: $thumbnail
          category: $category
          author: $author
        ) {
          statusCode
          data
          message
        }
      }
    `, {
      blogId: params.blogId,
      userId: params.userId,
      title: params.title,
      description: params.description,
      thumbnail: params.thumbnail,
      category: params.category,
      author: params.author
    }, token)
  );
}


const blog = {
  createBlog,
  getBlogs,
  updateBlogStatus,
  updateBlog
};

export default blog;
