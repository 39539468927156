import { createSlice } from "@reduxjs/toolkit";
import InitialStore from "../store/InitialStore";


export const createBlogRequest = (state, action) => {
    state.tickets = []
    state.loading = true
    state.error = false
    state.errorMessage = ''
}

export const createBlogSuccess = (state, action) => {
    state.blogs = action.payload
    state.loading = false
}

export const createBlogFail = (state, action) => {
    state.error = true
    state.loading = false
}

export const getBlogsRequest = (state, action) => {
    state.loading = true
    state.error = false
    state.errorMessage = ''
}

export const getBlogsSuccess = (state, action) => {
    state.blogs = action.payload
    state.loading = false
}

export const getBlogsFail = (state, action) => {
    state.error = true
    state.loading = false
}

export const updateBlogStatusRequest = (state, action) => {
    state.currentBlog = {}
    state.currentBlogLoading = true
    state.error = false
    state.errorMessage = ''
}
export const updateBlogStatusSuccess = (state, action) => {
    state.currentBlog = action.payload
    state.currentBlogLoading = false
}
export const updateBlogStatusFail = (state, action) => {
    state.currentBlogLoading = false
    state.error = true
}

export const updateBlogRequest = (state, action) => {
    state.currentBlog = {}
    state.currentBlogLoading = true
    state.error = false
    state.errorMessage = ''
}
export const updateBlogSuccess = (state, action) => {
    state.currentBlog = action.payload
    state.currentBlogLoading = false
}
export const updateBlogFail = (state, action) => {
    state.currentBlogLoading = false
    state.error = true
}

export default createSlice({
    name: "blogReducer",
    initialState: InitialStore.blog,
    reducers: {
        createBlogRequest,
        createBlogSuccess,
        createBlogFail,
        getBlogsRequest,
        getBlogsSuccess,
        getBlogsFail,
        updateBlogStatusRequest,
        updateBlogStatusSuccess,
        updateBlogStatusFail,
        updateBlogRequest,
        updateBlogSuccess,
        updateBlogFail
    }
})