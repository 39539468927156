import { Scale } from "@mui/icons-material";
import * as React from "react";
import "../Main.css";
const Hair = (props) => {

    const { active, issue, onClick, disabled, top, right, scale } = props;

    return (
        disabled
            ?
            <div
                className={`mainCon`}
                style={{
                    top: top,
                    right: right,
                    transform: `scale(${scale ?? 1})`
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    {...props}
                >
                    <circle cx={12} cy={12} r={12}
                        fill="#B2B7BE"
                        opacity="0.16" />
                    <path
                        fill="#B2B7BE"
                        d="M11.555 17.381a2.14 2.14 0 0 1-1.525-.632 2.148 2.148 0 0 1-.631-1.527c0-.241.021-.473.04-.677.034-.38.064-.708-.062-.846-.083-.09-.264-.136-.537-.136H7v-.5h1.84c.431 0 .72.095.906.299.274.3.236.73.19 1.228-.018.203-.037.413-.037.632 0 .915.743 1.66 1.656 1.66.78 0 1.444-.532 1.615-1.292l.488.11a2.16 2.16 0 0 1-2.103 1.681ZM13.71 15.222h-.5c0-.171-.012-.34-.023-.467l.499-.042c.011.137.024.32.024.51ZM13.152 14.344c-.025-.339-.03-.723.216-.987.187-.2.473-.294.901-.294h1.84v.5h-1.84c-.272 0-.452.045-.535.134-.098.106-.104.321-.084.611l-.498.036ZM10.73 8.37a.377.377 0 1 1 .755.002.377.377 0 0 1-.755-.001Z"
                    />
                    <path
                        fill="#B2B7BE"
                        d="M14.33 7.557c-1.73 2.49-1.613 5.28-1.55 6.777.016.346.027.62.014.819-.023.356-.17.668-.395.892a1.216 1.216 0 0 1-.863.358c-.33 0-.638-.132-.869-.372a1.471 1.471 0 0 1-.389-.874c-.036-.364-.024-1.636.46-3.137a9.212 9.212 0 0 1 1.165-2.395 8.602 8.602 0 0 1 2.255-2.242l.56-.383-.387.557ZM15.18 10.681a.377.377 0 1 1-.755 0 .377.377 0 0 1 .754 0ZM9.424 11.317a.535.535 0 1 1-1.07-.001.535.535 0 0 1 1.07.001Z"
                    />
                </svg>
            </div>
            :
            <div
                onClick={onClick}
                className={`mainCon  ${active && (issue ? "activeRed" : "activeGreen")}`}
                style={{
                    top: top,
                    right: right,
                    transform: `scale(${scale ?? 1})`
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    {...props}

                >
                    <circle cx={12} cy={12} r={12}
                        fill={
                            active
                                ? "white"
                                : issue
                                    ? "#F80D38"
                                    : "#0BB024"
                        }
                        opacity={
                            active ? "1" : "0.16"
                        } />
                    <path
                        fill={issue ? "#FF001D" : "#00B817"}
                        d="M11.555 17.381a2.14 2.14 0 0 1-1.525-.632 2.148 2.148 0 0 1-.631-1.527c0-.241.021-.473.04-.677.034-.38.064-.708-.062-.846-.083-.09-.264-.136-.537-.136H7v-.5h1.84c.431 0 .72.095.906.299.274.3.236.73.19 1.228-.018.203-.037.413-.037.632 0 .915.743 1.66 1.656 1.66.78 0 1.444-.532 1.615-1.292l.488.11a2.16 2.16 0 0 1-2.103 1.681ZM13.71 15.222h-.5c0-.171-.012-.34-.023-.467l.499-.042c.011.137.024.32.024.51ZM13.152 14.344c-.025-.339-.03-.723.216-.987.187-.2.473-.294.901-.294h1.84v.5h-1.84c-.272 0-.452.045-.535.134-.098.106-.104.321-.084.611l-.498.036ZM10.73 8.37a.377.377 0 1 1 .755.002.377.377 0 0 1-.755-.001Z"
                    />
                    <path
                        fill={issue ? "#FF001D" : "#00B817"}
                        d="M14.33 7.557c-1.73 2.49-1.613 5.28-1.55 6.777.016.346.027.62.014.819-.023.356-.17.668-.395.892a1.216 1.216 0 0 1-.863.358c-.33 0-.638-.132-.869-.372a1.471 1.471 0 0 1-.389-.874c-.036-.364-.024-1.636.46-3.137a9.212 9.212 0 0 1 1.165-2.395 8.602 8.602 0 0 1 2.255-2.242l.56-.383-.387.557ZM15.18 10.681a.377.377 0 1 1-.755 0 .377.377 0 0 1 .754 0ZM9.424 11.317a.535.535 0 1 1-1.07-.001.535.535 0 0 1 1.07.001Z"
                    />
                </svg>
            </div>
    )
}
export default Hair
