import * as React from "react";
import "../Main.css";

const Stomach = (props) => {
    const { active, issue, onClick, disabled, top, right, scale } = props;
    return (
        disabled
            ?
            <div
                className={`mainCon`}
                style={{
                    top: top,
                    right: right,
                    transform: `scale(${scale})`
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    {...props}
                >
                    <circle cx={12} cy={12} r={12}
                        fill="#B2B7BE"
                        opacity="0.16" />
                    <path
                        fill="#B2B7BE"
                        fillRule="evenodd"
                        d="M9.572 9.025c.393.428 1.058.612 2.275.638.07-.92.424-1.644.948-2.152a3.042 3.042 0 0 1 2.122-.844c.767 0 1.54.279 2.123.844.586.569.96 1.408.96 2.489v3.667c0 1.027-.171 2.1-.689 2.927-.533.85-1.413 1.406-2.73 1.406-1.347 0-2.285-.8-3.142-1.54l-.038-.032c-.863-.745-1.668-1.44-2.812-1.595-.561.005-.939.176-1.202.41-.274.244-.45.579-.56.938-.11.359-.148.723-.158 1.002a4.327 4.327 0 0 0 .008.445v.005l-.33.034-.332.033v-.004l-.002-.009a2.223 2.223 0 0 1-.009-.142 4.99 4.99 0 0 1-.001-.385c.01-.314.054-.741.186-1.174.133-.433.36-.89.754-1.24.4-.356.946-.58 1.667-.58.168 0 .326.005.478.008.282.007.542.013.804-.006.385-.03.702-.113.964-.31.502-.376.938-1.274.976-3.528-1.24-.026-2.16-.21-2.751-.855-.322-.35-.52-.808-.64-1.375-.119-.565-.164-1.258-.164-2.1h.667c0 .825.045 1.465.15 1.962.104.496.264.83.478 1.063Zm7.761 3.722V10c0-.92-.313-1.58-.758-2.01a2.375 2.375 0 0 0-1.658-.657c-.607 0-1.21.221-1.658.656-.444.431-.758 1.092-.758 2.011 0 .923-.059 1.68-.174 2.298 1.112-.601 1.956-.173 2.75.23.736.374 1.43.727 2.256.219Z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
            :
            <div
                className={`mainCon  ${active && (issue ? "activeRed" : "activeGreen")}`}
                onClick={onClick}
                style={{
                    top: top,
                    right: right,
                    transform: `scale(${scale})`
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    {...props}
                >
                    <circle cx={12} cy={12} r={12}
                        fill={
                            active
                                ? "white"
                                : issue
                                    ? "#F80D38"
                                    : "#0BB024"
                        }
                        opacity={
                            active ? "1" : "0.16"
                        } />
                    <path
                        fill={issue ? "#FF001D" : "#00B817"}
                        fillRule="evenodd"
                        d="M9.572 9.025c.393.428 1.058.612 2.275.638.07-.92.424-1.644.948-2.152a3.042 3.042 0 0 1 2.122-.844c.767 0 1.54.279 2.123.844.586.569.96 1.408.96 2.489v3.667c0 1.027-.171 2.1-.689 2.927-.533.85-1.413 1.406-2.73 1.406-1.347 0-2.285-.8-3.142-1.54l-.038-.032c-.863-.745-1.668-1.44-2.812-1.595-.561.005-.939.176-1.202.41-.274.244-.45.579-.56.938-.11.359-.148.723-.158 1.002a4.327 4.327 0 0 0 .008.445v.005l-.33.034-.332.033v-.004l-.002-.009a2.223 2.223 0 0 1-.009-.142 4.99 4.99 0 0 1-.001-.385c.01-.314.054-.741.186-1.174.133-.433.36-.89.754-1.24.4-.356.946-.58 1.667-.58.168 0 .326.005.478.008.282.007.542.013.804-.006.385-.03.702-.113.964-.31.502-.376.938-1.274.976-3.528-1.24-.026-2.16-.21-2.751-.855-.322-.35-.52-.808-.64-1.375-.119-.565-.164-1.258-.164-2.1h.667c0 .825.045 1.465.15 1.962.104.496.264.83.478 1.063Zm7.761 3.722V10c0-.92-.313-1.58-.758-2.01a2.375 2.375 0 0 0-1.658-.657c-.607 0-1.21.221-1.658.656-.444.431-.758 1.092-.758 2.011 0 .923-.059 1.68-.174 2.298 1.112-.601 1.956-.173 2.75.23.736.374 1.43.727 2.256.219Z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
    )
}
export default Stomach
