import * as React from "react";
import "../Main.css";

const Lungs = (props) => {

    const { active, issue, onClick, disabled, right, top, scale } = props;

    return (

        disabled
            ?
            <div
                className={`mainCon`}
                style={{
                    top: top,
                    right: right,
                    transform: `scale(${scale})`
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    {...props}
                >
                    <circle cx={12} cy={12} r={12}
                        fill="#B2B7BE"
                        opacity="0.16" />
                    <path
                        fill="#B2B7BE"
                        fillRule="evenodd"
                        d="M11.999 6.666c.184 0 .333.15.333.333h-.667c0-.184.15-.333.334-.333Zm.333 3.71V6.998h-.667v3.376c-.184.05-.343.142-.476.265-.198.184-.325.424-.416.652-.082.205-.145.427-.2.623l-.02.066a3.286 3.286 0 0 1-.186.536.333.333 0 0 0 .597.298c.099-.199.169-.437.23-.651l.019-.065a5.8 5.8 0 0 1 .179-.56c.075-.188.157-.323.25-.41a.488.488 0 0 1 .357-.13c.166 0 .272.052.356.13.094.087.176.222.25.41.07.171.122.359.18.56l.018.065c.061.214.132.452.231.651a.333.333 0 1 0 .596-.298 3.287 3.287 0 0 1-.186-.536 6.396 6.396 0 0 0-.22-.689c-.09-.228-.217-.468-.415-.652a1.123 1.123 0 0 0-.477-.265Z"
                        clipRule="evenodd"
                    />
                    <path
                        fill="#B2B7BE"
                        d="M9.83 8.675c-1.006-.318-1.971.29-2.632 1.276-.67 1-1.105 2.478-1.08 4.174.02 1.323 1.127 2.367 2.436 2.366.92 0 1.912-.173 2.4-.986.259-.432.368-.81.369-1.194 0-.37-.103-.719-.214-1.074l-.037-.118c-.219-.691-.497-1.57-.447-3.078.015-.482-.156-1.164-.795-1.366ZM14.172 8.675c1.006-.318 1.971.29 2.632 1.276.67 1 1.105 2.478 1.08 4.174-.02 1.323-1.126 2.367-2.436 2.366-.92 0-1.911-.173-2.4-.986-.258-.432-.368-.81-.368-1.194 0-.37.102-.719.214-1.074l.037-.118c.218-.691.496-1.57.447-3.078-.016-.482.155-1.164.794-1.366Z"
                    />
                </svg>
            </div>
            :
            <div
                className={`mainCon  ${active && (issue ? "activeRed" : "activeGreen")}`}
                onClick={onClick}
                style={{
                    top: top,
                    right: right,
                    transform: `scale(${scale})`
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    {...props}
                >
                    <circle cx={12} cy={12} r={12}
                        fill={
                            active
                                ? "white"
                                : issue
                                    ? "#F80D38"
                                    : "#0BB024"
                        }
                        opacity={
                            active ? "1" : "0.16"
                        } />
                    <path
                        fill={issue ? "#FF001D" : "#00B817"}
                        fillRule="evenodd"
                        d="M11.999 6.666c.184 0 .333.15.333.333h-.667c0-.184.15-.333.334-.333Zm.333 3.71V6.998h-.667v3.376c-.184.05-.343.142-.476.265-.198.184-.325.424-.416.652-.082.205-.145.427-.2.623l-.02.066a3.286 3.286 0 0 1-.186.536.333.333 0 0 0 .597.298c.099-.199.169-.437.23-.651l.019-.065a5.8 5.8 0 0 1 .179-.56c.075-.188.157-.323.25-.41a.488.488 0 0 1 .357-.13c.166 0 .272.052.356.13.094.087.176.222.25.41.07.171.122.359.18.56l.018.065c.061.214.132.452.231.651a.333.333 0 1 0 .596-.298 3.287 3.287 0 0 1-.186-.536 6.396 6.396 0 0 0-.22-.689c-.09-.228-.217-.468-.415-.652a1.123 1.123 0 0 0-.477-.265Z"
                        clipRule="evenodd"
                    />
                    <path
                        fill={issue ? "#FF001D" : "#00B817"}
                        d="M9.83 8.675c-1.006-.318-1.971.29-2.632 1.276-.67 1-1.105 2.478-1.08 4.174.02 1.323 1.127 2.367 2.436 2.366.92 0 1.912-.173 2.4-.986.259-.432.368-.81.369-1.194 0-.37-.103-.719-.214-1.074l-.037-.118c-.219-.691-.497-1.57-.447-3.078.015-.482-.156-1.164-.795-1.366ZM14.172 8.675c1.006-.318 1.971.29 2.632 1.276.67 1 1.105 2.478 1.08 4.174-.02 1.323-1.126 2.367-2.436 2.366-.92 0-1.911-.173-2.4-.986-.258-.432-.368-.81-.368-1.194 0-.37.102-.719.214-1.074l.037-.118c.218-.691.496-1.57.447-3.078-.016-.482.155-1.164.794-1.366Z"
                    />
                </svg>
            </div>
    )
}
export default Lungs
