import React, { useState } from "react";
import classes from './ImageSlideShow.module.css'
import ArrowRight from "@mui/icons-material/ArrowRight";
import ArrowLeft from "@mui/icons-material/ArrowLeft"



export default function SlideShow(props) {
    const { imgArray } = props;
    const [imagePage, setImagePage] = useState(0)
    const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <div className={classes.slideshow_container} onClick={props.onclick}>
                <img className={classes.image_slides}
                    src={imgArray?.[imagePage]} />
                {
                    imgArray?.length > 1 &&
                    <>
                        <div className={classes.num_indicator}>{imagePage + 1}/{imgArray?.length}</div>
                        <button onClick={() => setImagePage(page => page - 1)}
                            className={classes.arrow_left}><ArrowLeft /></button>
                        <button onClick={() => setImagePage(page => page + 1)}
                            className={classes.arrow_right}><ArrowRight /></button>
                    </>
                }
            </div>
        </>
    )
}