import * as React from "react";
import "../Main.css";

const Skin = (props) => {

    const { active, issue, onClick, disabled, top, right, scale } = props;

    return (
        disabled
            ?
            <div
                className={`mainCon`}
                style={{
                    top: top,
                    right: right,
                    transform: `scale(${scale})`
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    {...props}
                >
                    <circle cx={12} cy={12} r={12}
                        fill="#B2B7BE"
                        opacity="0.16" />
                    <path
                        fill="#B2B7BE"
                        fillRule="evenodd"
                        d="M7.332 10a4.667 4.667 0 1 1 9.333 0v4a4.667 4.667 0 0 1-9.333 0v-4Zm4.667 0c.184 0 .333.15.333.334v3.262c0 .753.183 1.256.49 1.571.304.315.786.5 1.51.5a.333.333 0 0 1 0 .667c-.832 0-1.516-.215-1.99-.702-.47-.486-.677-1.185-.677-2.036v-3.262c0-.184.15-.333.334-.333Zm0-3c-1.662 0-3.334 1.256-3.334 3.079v4.183c0 .415.062.844.192 1.221.129.373.337.728.652.951a.333.333 0 1 0 .386-.544c-.16-.113-.304-.324-.408-.625a3.142 3.142 0 0 1-.155-1.003v-4.183c0-1.37 1.282-2.412 2.667-2.412 1.384 0 2.666 1.041 2.666 2.412v3.436c0 .22-.044.39-.11.494-.056.086-.129.136-.26.136a.388.388 0 0 1-.322-.16.774.774 0 0 1-.141-.47v-3.102c0-.494-.191-.97-.534-1.321a1.814 1.814 0 0 0-1.3-.552c-.488 0-.956.2-1.299.552a1.894 1.894 0 0 0-.534 1.321v3.872c0 1.131.586 2.234 1.886 2.696a.333.333 0 0 0 .224-.628c-.998-.355-1.443-1.178-1.443-2.068v-3.872c0-.323.125-.63.345-.856.22-.225.515-.35.822-.35.306 0 .602.125.822.35.22.226.344.533.344.856v3.102c0 .317.092.633.28.878.196.252.49.419.85.419a.94.94 0 0 0 .82-.443c.16-.25.217-.561.217-.854V10.08c0-1.823-1.671-3.078-3.333-3.078Z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
            :
            <div
                className={`mainCon  ${active && (issue ? "activeRed" : "activeGreen")}`}
                onClick={onClick}
                style={{
                    top: top,
                    right: right,
                    transform: `scale(${scale})`
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    {...props}
                >
                    <circle cx={12} cy={12} r={12}
                        fill={
                            active
                                ? "white"
                                : issue
                                    ? "#F80D38"
                                    : "#0BB024"
                        }
                        opacity={
                            active ? "1" : "0.16"
                        } />
                    <path
                        fill={issue ? "#FF001D" : "#00B817"}
                        fillRule="evenodd"
                        d="M7.332 10a4.667 4.667 0 1 1 9.333 0v4a4.667 4.667 0 0 1-9.333 0v-4Zm4.667 0c.184 0 .333.15.333.334v3.262c0 .753.183 1.256.49 1.571.304.315.786.5 1.51.5a.333.333 0 0 1 0 .667c-.832 0-1.516-.215-1.99-.702-.47-.486-.677-1.185-.677-2.036v-3.262c0-.184.15-.333.334-.333Zm0-3c-1.662 0-3.334 1.256-3.334 3.079v4.183c0 .415.062.844.192 1.221.129.373.337.728.652.951a.333.333 0 1 0 .386-.544c-.16-.113-.304-.324-.408-.625a3.142 3.142 0 0 1-.155-1.003v-4.183c0-1.37 1.282-2.412 2.667-2.412 1.384 0 2.666 1.041 2.666 2.412v3.436c0 .22-.044.39-.11.494-.056.086-.129.136-.26.136a.388.388 0 0 1-.322-.16.774.774 0 0 1-.141-.47v-3.102c0-.494-.191-.97-.534-1.321a1.814 1.814 0 0 0-1.3-.552c-.488 0-.956.2-1.299.552a1.894 1.894 0 0 0-.534 1.321v3.872c0 1.131.586 2.234 1.886 2.696a.333.333 0 0 0 .224-.628c-.998-.355-1.443-1.178-1.443-2.068v-3.872c0-.323.125-.63.345-.856.22-.225.515-.35.822-.35.306 0 .602.125.822.35.22.226.344.533.344.856v3.102c0 .317.092.633.28.878.196.252.49.419.85.419a.94.94 0 0 0 .82-.443c.16-.25.217-.561.217-.854V10.08c0-1.823-1.671-3.078-3.333-3.078Z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
    )
}
export default Skin
