import React from 'react'
import classes from './Anxiety.module.css'
import { Avatar } from '@mui/material'
import { getAge, stringAvatar } from '../../../utils/utility'
import highrisk from "../../../assets/images/highRisk.svg";
import psychologist from "../../../assets/images/psychologist.svg";
import moment from 'moment';

function BhvReportHeader({data}) {
  return (
    <div className={classes.student_details_container}>
    <div className={classes.student_avatar_container}>
      <Avatar
        {...stringAvatar(`${data?.student?.name}`)}
        sx={{
          backgroundColor: "#EC0606",
          height: "39px",
          width: "39px",
          fontSize: "24px",
          fontWeight: "600",
          fontFamily: "Inter",
          margin: "0 auto",
        }}
      />
      <div>{data?.student?.name}</div>
      <div>
        <div>
        <p>{getAge(data?.student?.DOB)} yrs</p>
        <p>.</p>
        <p>{data?.student?.gender}</p>
        <p>.</p>
        <p>{`${data?.student?.class}-${data?.student?.section}`}</p>
        </div>
      </div>

      <div className={classes.validation_container}>
        <div>
          <p>Validated by</p>
          <p>Ashwathi Prabhu</p>
        </div>

        <div>
          <p>Validation date & Time</p>
          <p>{moment(data?.output?.[0]?.responseAt).format("Do MMM, YYYY hh:mm A")}</p>
        </div>
      </div>
    </div>

    <div>
      <p>Score</p>
      <div>
        <p>{data?.output?.[0]?.outcome}</p>
        {data?.output?.[0]?.outcome?.includes("High") && <img src={highrisk} alt="highrisk" />}
      </div>

      <div className={classes.interpretation_container}>
        <div>
          <img src={psychologist} />
        </div>
        <div>
          <p>Psychologist Intrepretation</p>
          <p>
            {data?.output?.[0]?.interpretation}
          </p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default BhvReportHeader