import * as React from "react";
import "../Main.css";
const Ear = (props) => {

    const { active, issue, whichEar, onClick, disabled, right, top, scale } = props;

    return (
        disabled
            ?
            <div
                className={`mainCon`}
                style={{
                    right: right,
                    top: top,
                    transform: whichEar === "left"
                        ? `scaleX(-${scale ?? 1}) scaleY(${scale ?? 1})`
                        : `scaleX(${scale ?? 1}) scaleY(${scale ?? 1})`
                }}

            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    {...props}
                >
                    <circle cx={12} cy={12} r={12}
                        fill="#B2B7BE"
                        opacity="0.16" />
                    <path
                        fill="#B2B7BE"
                        fillRule="evenodd"
                        d="M8.05 10c0-2.026 1.768-3.667 3.949-3.667 2.18 0 3.948 1.641 3.948 3.666 0 1.27-.694 2.388-1.75 3.047-.896.558-1.84 1.438-1.84 2.59v.03c0 1.105-.964 2-2.153 2-1.19 0-2.154-.895-2.154-2v-.667h-.718v.667c0 1.473 1.286 2.667 2.872 2.667 1.586 0 2.872-1.194 2.872-2.667v-.03c0-.8.67-1.506 1.521-2.037 1.247-.777 2.068-2.1 2.068-3.6 0-2.393-2.089-4.333-4.666-4.333-2.578 0-4.667 1.94-4.667 4.333h.718Zm3.615-1.334c-.649 0-1.176.438-1.303 1.007h.009c.271.014.64.052 1.013.154.37.102.77.272 1.082.566.322.303.533.723.533 1.275 0 .01 0 .02-.002.03-.049.537-.18.947-.379 1.255a1.526 1.526 0 0 1-.734.608 2.037 2.037 0 0 1-.75.127c-.108 0-.209-.006-.292-.011l-.033-.002-.1-.006a.661.661 0 0 0-.014.025c-.033.066-.059.135-.092.225a7.468 7.468 0 0 1-.078.205c-.07.175-.17.392-.339.566a1.01 1.01 0 0 1-.75.31c-.28 0-.511-.065-.692-.189a.913.913 0 0 1-.344-.447 1.176 1.176 0 0 1-.054-.583v-.007l.329.06.328.059v-.002l-.001.013a.575.575 0 0 0 .024.231c.02.053.048.094.094.126.047.032.139.072.316.072.134 0 .21-.044.272-.107.074-.076.135-.192.198-.35.016-.038.033-.084.051-.134a3.5 3.5 0 0 1 .145-.352l.04-.071c.186-.475.473-1.305.357-1.653a2.394 2.394 0 0 0-.366-.648c-.168-.14-.296-.276-.373-.44-.095-.2-.095-.41-.095-.604V9.94c0-1.083.907-1.94 2-1.94s2 .857 2 1.94H13c0-.692-.586-1.274-1.334-1.274Zm-.935 4.973-.004.003.003-.003Z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
            :
            <div
                onClick={onClick}
                className={`mainCon  
            ${active && (issue ? "activeRed" : "activeGreen")} 
            `}
                style={{
                    right: right,
                    top: top,
                    transform: whichEar === "left"
                        ? `scaleX(-${scale ?? 1}) scaleY(${scale ?? 1})`
                        : `scaleX(${scale ?? 1}) scaleY(${scale ?? 1})`
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill={
                        active
                            ? "white"
                            : issue
                                ? "#F80D38"
                                : "#0BB024"
                    }
                    {...props}
                >
                    <circle cx={12} cy={12} r={12}
                        opacity={
                            active ? "1" : "0.16"
                        } />
                    <path
                        fill={issue ? "#FF001D" : "#00B817"}
                        fillRule="evenodd"
                        d="M8.05 10c0-2.026 1.768-3.667 3.949-3.667 2.18 0 3.948 1.641 3.948 3.666 0 1.27-.694 2.388-1.75 3.047-.896.558-1.84 1.438-1.84 2.59v.03c0 1.105-.964 2-2.153 2-1.19 0-2.154-.895-2.154-2v-.667h-.718v.667c0 1.473 1.286 2.667 2.872 2.667 1.586 0 2.872-1.194 2.872-2.667v-.03c0-.8.67-1.506 1.521-2.037 1.247-.777 2.068-2.1 2.068-3.6 0-2.393-2.089-4.333-4.666-4.333-2.578 0-4.667 1.94-4.667 4.333h.718Zm3.615-1.334c-.649 0-1.176.438-1.303 1.007h.009c.271.014.64.052 1.013.154.37.102.77.272 1.082.566.322.303.533.723.533 1.275 0 .01 0 .02-.002.03-.049.537-.18.947-.379 1.255a1.526 1.526 0 0 1-.734.608 2.037 2.037 0 0 1-.75.127c-.108 0-.209-.006-.292-.011l-.033-.002-.1-.006a.661.661 0 0 0-.014.025c-.033.066-.059.135-.092.225a7.468 7.468 0 0 1-.078.205c-.07.175-.17.392-.339.566a1.01 1.01 0 0 1-.75.31c-.28 0-.511-.065-.692-.189a.913.913 0 0 1-.344-.447 1.176 1.176 0 0 1-.054-.583v-.007l.329.06.328.059v-.002l-.001.013a.575.575 0 0 0 .024.231c.02.053.048.094.094.126.047.032.139.072.316.072.134 0 .21-.044.272-.107.074-.076.135-.192.198-.35.016-.038.033-.084.051-.134a3.5 3.5 0 0 1 .145-.352l.04-.071c.186-.475.473-1.305.357-1.653a2.394 2.394 0 0 0-.366-.648c-.168-.14-.296-.276-.373-.44-.095-.2-.095-.41-.095-.604V9.94c0-1.083.907-1.94 2-1.94s2 .857 2 1.94H13c0-.692-.586-1.274-1.334-1.274Zm-.935 4.973-.004.003.003-.003Z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
    )
}
export default Ear
