import React, { useState, useEffect, useRef } from "react";
import classes from "./StudentName.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, TextField } from "@mui/material";
import blooddrop from "../../../assets/images/blooddrop.png";
import Bmi from "../../../assets/images/bmi.png";
import heartrate from "../../../assets/images/heartrate.png";
import stethoscope from "../../../assets/images/stethoscope.png";
import Thermometer from "../../../assets/images/Thermometer.png";
import Oxygen from "../../../assets/images/Oxygen.png";
import Bpmachine from "../../../assets/images/Bpmachine.png";
import { height } from "@mui/system";
import ear from "../../../assets/images/ear.png";
import PreScreening from "../../../assets/images/ScreeningNotScheduled.png";
import YetToScreen from "../../../assets/images/YetToScreen.png";
import ent from "../../../assets/images/ent.svg";
import heartmonitor from "../../../assets/images/heartmonitor.svg";
import pulmonology from "../../../assets/images/pulmonology.svg";
import hair from "../../../assets/images/hair.png";
import visilbilty from "../../../assets/images/visibility.svg";
import stethoscopeCheck from "../../../assets/images/stethoscopeCheck.svg";
import wind from "../../../assets/images/wind.png";
import checkcircle from "../../../assets/images/check-circle.svg";
import gastroenterology from "../../../assets/images/gastroenterology.svg";
import fingerprint from "../../../assets/images/fingerprint.svg";
import playArrow from "../../../assets/images/play-arrow.svg";
import ImageSlideshow from "./ImageSlideshow/ImageSlideshow";
import VideoSlideShow from "./VideoSlideshow/VideoPlayer";
import VideoComp from "./VideoSlideshow/VideoComp";
import VideoPlayer from "./VideoSlideshow/VideoPlayer";
import VideoSlideModal from "./VideoSlideshow/VideoSlideModal";
import ImageSlideModal from "./ImageSlideshow/ImageSlideModal";
import screeningReducer from "../../../redux/reducers/screening.reducer";
import { useDispatch, useSelector } from "react-redux";
import Audioplayer from "../../Audioplayer/Audioplayer";
import BreadCrumbs from "../TableComponents/BreadCrumbs";
import {
  calcBmi,
  calculateAge,
  checkDentalCondition,
  getDMFTIndex,
  numberTh,
  textFormat,
  toDateString,
} from "../../../utils/utility";
import DentalJaw from "../../Dentaljaw/Dentaljaw";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import InfoIcon from "@mui/icons-material/Info";
import { Configuration, OpenAIApi } from "openai";
import SkidsGPT from "../../SKIDSGPT/SkidsGPT";
import BoySvg from "../../../assets/images/Kids/BoySvg";
import GirlSvg from "../../../assets/images/Kids/GirlSvg";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import jsPDF from "jspdf";
import BmiScale from "../../../assets/images/BmiScale/BmiScale";
import Height from "../../../assets/images/height.svg";
import WeightScale from "../../../assets/images/weight-scale.svg";
import Spiderchart from "../../SpiderChart/Spiderchart";
import Hair from "../../../assets/Bodypoints/Hair/Hair";
import Eye from "../../../assets/Bodypoints/Eye/Eye";
import Ear from "../../../assets/Bodypoints/Ear/Ear";
import Cough from "../../../assets/Bodypoints/Cough/Cough";
import Dental from "../../../assets/Bodypoints/Dental/Dental";
import Throat from "../../../assets/Bodypoints/Throat/Throat";
import Lungs from "../../../assets/Bodypoints/Lungs/Lungs";
import Heart from "../../../assets/Bodypoints/Heart/Heart";
import Stomach from "../../../assets/Bodypoints/Stomach/Stomach";
import Skin from "../../../assets/Bodypoints/Skin/Skin";
import Heartpoint from "../../../assets/Bodypoints/Heart/Heartpoints";
import Lungspoints from "../../../assets/Bodypoints/Lungs/LungsPoints";
import ImageToVideo from "./ImageToVideo/ImageToVideo";
import schoolReducer from "../../../redux/reducers/school.reducer";
import GetAdvancePhysical from "../PackageBasedScreening/GetAdvancePhysical";
import GetAdvanceBehavioral from "../PackageBasedScreening/GetAdvanceBehavioral";
import { json } from "d3";
import { BottomCertification } from "../../BehavioralReport/DynamicContent/DynamicContent";
import BhvCertification from "../BhvCertification/BhvCertification";
import moment from "moment";
import Blankspider from "../../../assets/images/blankSpider";
import BlankDataScreen from "../../BlankDataScreen/BlankDataScreen";
import BPModal from "./BPModal";
import screening from "../../../graphqlApiServices/screening";
import studentReducer from "../../../redux/reducers/student.reducer";
import eventReducer from "../../../redux/reducers/event.reducer";
import saveAs from "file-saver";
import VitalsGaugeChart from "./GaugeChart/VitalsGaugeChart";

function StudentNamePage() {
  const studentScreening =
    screeningReducer.actions.getScreeningByStudentIdRequest;
  const studentBhvScreening =
    screeningReducer.actions.getBhvScreeningByIdRequest;
  const getSchoolById = schoolReducer.actions.getSchoolbyIdRequest;
  const getEventMaps = eventReducer.actions.getEventMapsRequest;

  const screenData = useSelector((state) => state.screeningReducer.data);
  const bhvScreenData = useSelector((state) => state.screeningReducer.bhvData);
  const loading = useSelector((state) => state.screeningReducer.loading);
  const user = useSelector((state) => state.userReducer.currentUser);
  const school = useSelector((state) => state.schoolReducer.data);
  const eventMapData = useSelector((state) => state.eventReducer.eventMapData);
  // const [isPhysicalView, setIsPhysicalView] = useState(true);
  // const [isBehavioralView, setIsBehavioralView] = useState(false);
  // const [isLearningDisView, setIsLearningDisView] = useState(false);
  const [activeScreeningView, setActiveScreeningView] = useState("physical");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [displayData, setDisplayData] = useState({});
  const [organ, setOrgan] = useState("Hair");
  const [behaviour, setBehaviour] = useState("adhd");
  const [learningDis, setLearningDis] = useState("dyslexia");
  const [response, setResponse] = useState("");
  const [askGPT, setAskGPT] = useState(false);

  let estimationTitle;
  let componentEstimation;
  let behaviourComponent;
  let learningDisComponent;
  const hemoglobin = 13.3;
  //
  const {
    firstName,
    lastName,
    DOB,
    section,
    gender,
    studentId,
    screeningDate,
  } = location.state;
  const keys = {
    DOB: "20/08/1989",
    Gender: "Male",
    Height: "170cm",
    Weight: "78Kg",
    SPO2: "99",
    SPGHb: "13.3",
    "Heart Rate": "88",
    "Respiratory Rate": "81.5",
    BMI: "18",
    "Skin Allergy": "No",
    Cough: "Yes",
    Dental: "Okay",
    Eyes: "Astigmatism and Myopia",
    Lungs: "Okay",
    Hearing: "Okay",
    Ears: "Minor Wax on Right Ear",
    Hair: "Dandruff",
    Throat: "Okay",
    Abdominal: "bowel Sound looks okay",
  };

  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_GPT_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const fetchResponse = async (e) => {
    e.preventDefault();
    setResponse("");

    const completion = await openai.createCompletion({
      model: "text-davinci-003",
      prompt: `Generate a health summary considering the kid is from India and a student also specify if there is any issue in the health and tell if height, weight and BMI is as per WHO standards as well \n based on the following data:\n\nDOB: ${keys.DOB}\nGender: ${keys.Gender}\nHeight: ${keys.Height}\nWeight: ${keys.Weight}\nSPO2: ${keys.SPO2}\nSPGHb: ${keys.SPGHb}\nHeart Rate: ${keys["Heart Rate"]}\nRespiratory Rate: ${keys["Respiratory Rate"]}\nBMI: ${keys.BMI}\nSkin Allergy: ${keys["Skin Allergy"]}\nCough: ${keys.Cough}\nDental: ${keys.Dental}\nEyes: ${keys.Eyes} \nLungs: ${keys.Lungs} \nHearing: ${keys.Hearing} \nEars: ${keys.Ears} \nHair: ${keys.Hair} \nThroat: ${keys.Throat} \nAbdominal: ${keys.Abdominal}`,
      max_tokens: 2048,
    });
    setResponse(completion.data.choices[0].text);
  };
  //

  const updateStudent = studentReducer.actions.updateStudentStatusRequest;
  // const studentScreening = screeningReducer.actions.getScreeningByStudentIdRequest;

  const { updateSuperStatus, markScreeningDoneOnMap } = screening;

  useEffect(() => {
    dispatch(studentScreening(studentId));
    dispatch(getSchoolById({ schoolId: location.state.schoolId }));
    dispatch(getEventMaps(location.state?.schoolId));
  }, []);

  useEffect(() => {
    if (screenData) {
      // dispatch(studentBhvScreening(screenData?.behavioralId));
      localStorage.setItem("sId", screenData?.screeningId);
      localStorage.setItem("name", `${firstName} ${lastName}`);
    }
    return () => {
      localStorage.removeItem("sId");
    };
  }, [screenData]);

  //

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}`,
    };
  }

  const downloadReport = async (x) => {
    const z = eventMapData.filter(
      (x) =>
        x.class === location.state.class && x.section === location.state.section
    );

    const url = `${process.env.REACT_APP_DOWNLOAD_REPORT}/${location.state.schoolId}/${z[0].eventId}/${z[0].mapId}/${screenData.screeningId}.pdf`;
    const res = await fetch(url);
    const pdfBlob = await res.blob();
    saveAs(pdfBlob, `${firstName} ${lastName} Report.pdf`);
    console.log(pdfBlob);
  };

  //
  //

  const userRole = useSelector(
    (state) => state.userReducer?.currentUser?.groups
  );

  const getSchoolReportStatus = (z) => {
    if (!bhvScreenData[z]?.score === null) {
      return false;
    } else {
      if (user?.groups?.includes("skids-sa")) {
        return true;
      } else {
        if (school?.isReport) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const combinedReportStatus = () => {
    if (screenData?.physicalValidatedOn === null) {
      return true;
    } else {
      if (school?.isReport) {
        return false;
      } else {
        return true;
      }
    }
  };

  // ---------------------------------------------------------------------------
  //------------------- Dynamic content for physical screening------------------
  // ---------------------------------------------------------------------------
  switch (organ) {
    case "Hair":
      estimationTitle = "Hair Stuff";
      componentEstimation = (
        <>
          {screenData?.screeningPackageDetails?.hair.length === 0 ? (
            <>
              <GetAdvancePhysical
                titleText={"Hair Screening is part of Advanced Package."}
                susbTitleText={"List of Parameter in Hair Screening"}
                tests={[
                  "Color of Hair",
                  "Dandruff",
                  "Pediculosis (louse/lice)",
                  "Loss of Hair",
                ]}
              />
            </>
          ) : (
            <>
              <div className={classes.headingWithCheck}>
                <div className={classes.headingText}>Color of hair</div>
                <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
              </div>
              <div className={classes.headingBold}>
                <div>{screenData?.hairAssessment?.color}</div>
              </div>
              {screenData?.hairAssessment?.colorInterpretation && (
                <div className={classes.docInterpretation}>
                  <img src={stethoscopeCheck} alt="" />
                  <div>
                    <div>Doctor Interpretation</div>
                    <div>{screenData?.hairAssessment?.colorInterpretation}</div>
                  </div>
                </div>
              )}
              <br />
              <div className={classes.hrLine} />
              <br />

              <div className={classes.headingWithCheck}>
                <div className={classes.headingText}>Dandruff</div>
                <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
              </div>
              <div className={classes.headingBold}>
                <div>
                  {screenData?.hairAssessment?.dandruff === true
                    ? "Present"
                    : "Absent"}
                </div>
              </div>
              {screenData?.hairAssessment?.dandruffInterpretation && (
                <div className={classes.docInterpretation}>
                  <img src={stethoscopeCheck} alt="" />
                  <div>
                    <div>Doctor Interpretation</div>
                    <div>
                      {screenData?.hairAssessment?.dandruffInterpretation}
                    </div>
                  </div>
                </div>
              )}
              <br />
              <div className={classes.hrLine} />
              <br />
              <div className={classes.headingWithCheck}>
                <div className={classes.headingText}>
                  Pediculosis (louse/lice)
                </div>
                <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
              </div>
              <div className={classes.headingBold}>
                <div>
                  {screenData?.hairAssessment?.pediculosis === true
                    ? "Present"
                    : "Absent"}
                </div>
              </div>
              {screenData?.hairAssessment?.pediculosisInterpretation && (
                <div className={classes.docInterpretation}>
                  <img src={stethoscopeCheck} alt="" />
                  <div>
                    <div>Doctor Interpretation</div>
                    <div>
                      {screenData?.hairAssessment?.pediculosisInterpretation}
                    </div>
                  </div>
                </div>
              )}
              <br />
              <div className={classes.hrLine} />
              <br />

              <div className={classes.headingWithCheck}>
                <div className={classes.headingText}>Loss of hair</div>
                <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
              </div>
              <div className={classes.headingBold}>
                <div>
                  {screenData?.hairAssessment?.lossOfHair === true
                    ? "Yes"
                    : "No"}
                </div>
              </div>
              {screenData?.hairAssessment?.lossOfHairInterpretation && (
                <div className={classes.docInterpretation}>
                  <img src={stethoscopeCheck} alt="" />
                  <div>
                    <div>Doctor Interpretation</div>
                    <div>
                      {screenData?.hairAssessment?.lossOfHairInterpretation}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <div className={classes.knowMoreSection}>
            <div>Click here to know more about Hair conditions.</div>
            <div>
              <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
              <div
                onClick={() => setAskGPT("Hair")}
                style={{ cursor: "pointer" }}
              >
                Ask SkidsGPT
              </div>
            </div>
          </div>
        </>
      );
      break;

    case "Eye":
      estimationTitle = "Eye screening images";
      componentEstimation = (
        <>
          {askGPT !== "Eyes" ? (
            <>
              {screenData?.screeningPackageDetails?.eye.length === 0 ? (
                <>
                  <GetAdvancePhysical
                    titleText={"Eye Screening is part of Advanced Package."}
                    susbTitleText={"List of Parameter in Eye Screening"}
                    tests={[
                      "Mayopia",
                      "Hyperopia",
                      "Astigmatism",
                      "Amblyopia",
                      "Strabismus",
                      "Anisometropia",
                      "Aniscoria",
                    ]}
                  />
                </>
              ) : (
                <>
                  <div className={classes.headingWithCheck}>
                    <div className={classes.headingText}>Eyes</div>
                    <CheckCircleIcon
                      sx={{ fill: "#149457", fontSize: "17px" }}
                    />
                  </div>
                  <div className={classes.slides_player_container}>
                    <div className={classes.slides_player_inner_container}>
                      <ImageSlideModal
                        imgArray={[
                          screenData?.welchAllyn?.visionScreeningImage,
                        ]}
                      />
                      <ImageSlideModal
                        imgArray={[
                          screenData?.welchAllyn?.potentialConditionImage,
                        ]}
                      />
                    </div>
                    <div className={classes.slides_player_inner_container}>
                      {screenData?.welchAllyn?.otherVisionDetails?.map((x) => {
                        return <ImageSlideModal imgArray={[x]} />;
                      })}
                    </div>
                  </div>
                  {screenData?.eyeAssessment?.validation.length > 0 &&
                    screenData?.eyeAssessment?.validation.map(
                      (validationObj) => {
                        return (
                          <div className={classes.docInterpretation}>
                            <img src={stethoscopeCheck} alt="" />
                            <div>
                              <div>Doctor Interpretation</div>
                              <div>{validationObj.interpretation}</div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </>
              )}
              <div className={classes.knowMoreSection}>
                <div>Click here to know more about Eyes conditions.</div>
                <div>
                  <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                  <div
                    onClick={() => setAskGPT("Eyes")}
                    style={{ cursor: "pointer" }}
                  >
                    Ask SkidsGPT
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={classes.close}
                onClick={() => setAskGPT("")}
                style={{ color: "#222CC9" }}
              >
                Close Chat
              </div>
              <SkidsGPT organ={"Eyes"} />
            </>
          )}
        </>
      );
      break;

    case "Ear":
      estimationTitle = "Ear images";
      componentEstimation = (
        <>
          {askGPT !== "Ear" ? (
            <>
              {screenData?.screeningPackageDetails?.ear.length === 0 ? (
                <>
                  <GetAdvancePhysical
                    titleText={"Ear Screening is part of Advanced Package."}
                    susbTitleText={"List of Parameter in Ear Screening"}
                    tests={[
                      "Otisis Media",
                      "Otisis Externa",
                      "Ear Wax",
                      "Foreign Body in Ear canal",
                    ]}
                  />
                </>
              ) : (
                <>
                  <div className={classes.headingWithCheck}>
                    <div className={classes.headingText}>Left Ear</div>
                    <CheckCircleIcon
                      sx={{ fill: "#149457", fontSize: "17px" }}
                    />
                  </div>
                  {screenData?.higo?.leftEar.length > 0 ? (
                    <div className={classes.slides_player_container}>
                      <VideoSlideModal
                        videoUrl={screenData?.higo?.leftEarVideo}
                        imgs={screenData?.higo?.leftEar}
                      />
                    </div>
                  ) : screenData?.ent?.entLeftEarVideo ? (
                    <div className={classes.slides_player_container}>
                      <VideoSlideModal
                        videoUrl={screenData?.ent?.entLeftEarVideo}
                      />
                    </div>
                  ) : (
                    <h3>No data available at the time!</h3>
                  )}
                  {screenData?.earAssessment?.leftEarValidation.length > 0 &&
                    screenData?.earAssessment?.leftEarValidation.map(
                      (validationObj) => {
                        return (
                          <div className={classes.docInterpretation}>
                            <img src={stethoscopeCheck} alt="" />
                            <div>
                              <div>Doctor Interpretation</div>
                              <div>{validationObj.interpretation}</div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  <br />
                  <div className={classes.hrLine} />
                  <br />
                  <div className={classes.headingWithCheck}>
                    <div className={classes.headingText}>Right Ear</div>
                    <CheckCircleIcon
                      sx={{ fill: "#149457", fontSize: "17px" }}
                    />
                  </div>
                  <br />
                  {screenData?.higo?.rightEar.length > 0 ? (
                    <div className={classes.slides_player_container}>
                      <VideoSlideModal
                        videoUrl={screenData?.higo?.rightEarVideo}
                        imgs={screenData?.higo?.rightEar}
                      />
                    </div>
                  ) : screenData?.ent?.entRightEarVideo ? (
                    <div className={classes.slides_player_container}>
                      <VideoSlideModal
                        videoUrl={screenData?.ent?.entRightEarVideo}
                      />
                    </div>
                  ) : (
                    <h3>No data available at the time!</h3>
                  )}
                  {screenData?.earAssessment?.rightEarValidation.length > 0 &&
                    screenData?.earAssessment?.rightEarValidation.map(
                      (validationObj) => {
                        return (
                          <div className={classes.docInterpretation}>
                            <img src={stethoscopeCheck} alt="" />
                            <div>
                              <div>Doctor Interpretation</div>
                              <div>{validationObj.interpretation}</div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </>
              )}
              <div className={classes.knowMoreSection}>
                <div>Click here to know more about Ear conditions.</div>
                <div>
                  <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                  <div
                    onClick={() => setAskGPT("Ear")}
                    style={{ cursor: "pointer" }}
                  >
                    Ask SkidsGPT
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={classes.close}
                onClick={() => setAskGPT("")}
                style={{ color: "#222CC9" }}
              >
                Close Chat
              </div>
              <SkidsGPT organ={"Ear"} />
            </>
          )}
        </>
      );
      break;

    case "Hearing":
      estimationTitle = "Ear images";
      componentEstimation = (
        <>
          {!screenData?.screeningPackageDetails?.ear.includes("HEARING") ? (
            <>
              <GetAdvancePhysical
                titleText={"Hearing Screening is part of Advanced Package."}
                susbTitleText={"List of Parameter in Hearing Screening"}
                tests={["Hearing Proficiency"]}
              />
            </>
          ) : (
            <>
              <div className={classes.headingWithCheck}>
                <div className={classes.headingText}>Hearing</div>
                <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
              </div>
              <div className={classes.hearingImageSection}>
                {screenData?.hearingAssessment?.reportUrl != "undefined" &&
                  screenData?.hearingAssessment?.hearingReport && (
                    <div>
                      <ImageSlideModal
                        imgArray={[
                          screenData?.hearingAssessment?.hearingReport,
                        ]}
                      />
                    </div>
                  )}
                <div>
                  <ImageSlideModal
                    imgArray={[screenData?.amplivox?.leftEarImage]}
                  />
                  <ImageSlideModal
                    imgArray={[screenData?.amplivox?.leftEarGraph]}
                  />
                </div>
                <div>
                  <ImageSlideModal
                    imgArray={[screenData?.amplivox?.rightEarImage]}
                  />
                  <ImageSlideModal
                    imgArray={[screenData?.amplivox?.rightEarGraph]}
                  />
                </div>
              </div>
              {screenData?.hearingAssessment?.validation?.length > 0 &&
                screenData?.hearingAssessment?.validation.map(
                  (validationObj) => {
                    return (
                      <div className={classes.docInterpretation}>
                        <img src={stethoscopeCheck} alt="" />
                        <div>
                          <div>Doctor Interpretation</div>
                          <div>{validationObj.interpretation}</div>
                        </div>
                      </div>
                    );
                  }
                )}
            </>
          )}
        </>
      );
      break;

    case "Cough":
      estimationTitle = "Cough exam";
      componentEstimation = (
        <>
          {askGPT !== "Cough" ? (
            <>
              {!screenData?.screeningPackageDetails?.lungs.includes("COUGH") ? (
                <>
                  <GetAdvancePhysical
                    titleText={"Cough Screening is part of Advanced Package."}
                    susbTitleText={"List of Parameter in Cough Screening"}
                    tests={["Cough"]}
                  />
                </>
              ) : (
                <>
                  <div className={classes.headingWithCheck}>
                    <div className={classes.headingText}>Cough</div>
                    <CheckCircleIcon
                      sx={{ fill: "#149457", fontSize: "17px" }}
                    />
                  </div>
                  {screenData?.higo?.cough?.map((audioUrl, index) => (
                    <div className={classes.audioPlayerWrapper}>
                      <p style={{ fontSize: "10px" }}>No. {index + 1}</p>
                      <Audioplayer url={audioUrl} />
                    </div>
                  ))}
                  {screenData?.coughAssessment?.validation.length > 0 &&
                    screenData?.coughAssessment?.validation.map(
                      (validationObj) => {
                        return (
                          <div className={classes.docInterpretation}>
                            <img src={stethoscopeCheck} alt="" />
                            <div>
                              <div>Doctor Interpretation</div>
                              <div>{validationObj.interpretation}</div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  <div className={classes.knowMoreSection}>
                    <div>Click here to know more about Cough conditions.</div>
                    <div>
                      <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                      <div
                        onClick={() => setAskGPT("Cough")}
                        style={{ cursor: "pointer" }}
                      >
                        Ask SkidsGPT
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div
                className={classes.close}
                onClick={() => setAskGPT("")}
                style={{ color: "#222CC9" }}
              >
                Close Chat
              </div>
              <SkidsGPT organ={"Cough"} />
            </>
          )}
        </>
      );
      break;

    case "Throat":
      estimationTitle = "Throat videos";
      componentEstimation = (
        <>
          {askGPT !== "Throat" ? (
            <>
              {screenData?.screeningPackageDetails?.throat.length === 0 ? (
                <>
                  <GetAdvancePhysical
                    titleText={"Throat Screening is part of Advanced Package."}
                    susbTitleText={"List of Parameter in Throat Screening"}
                    tests={[
                      "Stomatitis",
                      "Glossitis",
                      "Pharyngitis",
                      "Tonsilitis",
                      "White Patches",
                      "Throat Congestion",
                    ]}
                  />
                </>
              ) : (
                <>
                  <div className={classes.headingWithCheck}>
                    <div className={classes.headingText}>Throat</div>
                    <CheckCircleIcon
                      sx={{ fill: "#149457", fontSize: "17px" }}
                    />
                  </div>
                  {screenData?.higo?.throat.length > 0 ? (
                    <div className={classes.slides_player_container}>
                      <VideoSlideModal
                        videoUrl={screenData?.higo?.throatVideo}
                        imgs={screenData?.higo?.throat}
                      />
                    </div>
                  ) : screenData?.ent?.entThroatVideo ? (
                    <div className={classes.slides_player_container}>
                      <VideoSlideModal
                        videoUrl={screenData?.ent?.entThroatVideo}
                      />
                    </div>
                  ) : (
                    <h3>No data available at th time!</h3>
                  )}
                  {screenData?.throatAssessment?.validation.length > 0 &&
                    screenData?.throatAssessment?.validation.map(
                      (validationObj) => {
                        return (
                          <div className={classes.docInterpretation}>
                            <img src={stethoscopeCheck} alt="" />
                            <div>
                              <div>Doctor Interpretation</div>
                              <div>{validationObj.interpretation}</div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </>
              )}
              <div className={classes.knowMoreSection}>
                <div>Click here to know more about Throat conditions.</div>
                <div>
                  <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                  <div
                    onClick={() => setAskGPT("Throat")}
                    style={{ cursor: "pointer" }}
                  >
                    Ask SkidsGPT
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={classes.close}
                onClick={() => setAskGPT("")}
                style={{ color: "#222CC9" }}
              >
                Close Chat
              </div>
              <SkidsGPT organ={"Throat"} />
            </>
          )}
        </>
      );
      break;

    case "Heart":
      estimationTitle = "Heart rate";
      componentEstimation =
        askGPT !== "HeartGPT" ? (
          <>
            {/* {(screenData?.screeningPackageDetails?.heart.length === 0)
              ?
              <>
                <GetAdvancePhysical
                  titleText={"Heart Screening is part of Advanced Package."}
                  susbTitleText={"List of Parameter in Heart Screening"}
                  tests={[
                    "Abnormal Heart Sound", "Arrhythmia", "Rhythm Disorder"
                  ]}
                />
              </>
              : */}
            <>
              <div>
                <p className={classes.ausculation_heading}>Heart ausculation</p>
                {/* <div style={{ margin: '3rem 0' }}>
                    {screenData?.ent?.entHeartAudio ?
                      <Audioplayer url={screenData?.ent?.entHeartAudio} />
                      : <h3>No data available at th time!</h3>}
                  </div> */}
                <div style={{ margin: "3rem 0" }}>
                  {screenData?.heartAssessment?.heartReportUrl ? (
                    <iframe
                      src={screenData?.heartAssessment?.heartReportUrl}
                      style={{ width: "100%" }}
                      height="1000"
                      frameBorder="0"
                    ></iframe>
                  ) : screenData?.entAssessment?.heartReportUrl ? (
                    <iframe
                      src={screenData?.entAssessment?.heartReportUrl}
                      style={{ width: "100%" }}
                      height="1000"
                      frameBorder="0"
                    ></iframe>
                  ) : (
                    <h3>No data available at th time!</h3>
                  )}
                  {screenData?.higo?.heart?.map((audioUrl, index) => (
                    <div className={classes.audioPlayerWrapper}>
                      <p style={{ fontSize: "10px" }}>No. {index + 1}</p>
                      <Audioplayer url={audioUrl} />
                    </div>
                  ))}
                </div>
              </div>
              {screenData?.higo?.heartWav?.length > 0 && (
                <div>
                  <p className={classes.ausculation_heading}>Heart Wav</p>
                  {screenData?.higo?.heartWav?.map((audioUrl, index) => (
                    <div className={classes.audioPlayerWrapper}>
                      <p style={{ fontSize: "10px" }}>No. {index + 1}</p>
                      <Audioplayer url={audioUrl} />
                    </div>
                  ))}
                </div>
              )}
              {screenData?.heartAssessment?.auscultationValidation?.length >
                0 &&
                screenData?.heartAssessment?.auscultationValidation?.map(
                  (validationObj) => {
                    return (
                      <div className={classes.docInterpretation}>
                        <img src={stethoscopeCheck} alt="" />
                        <div>
                          <div>Doctor Interpretation</div>
                          <div>{validationObj.interpretation}</div>
                        </div>
                      </div>
                    );
                  }
                )}
              <br />
              <div className={classes.hrLine} />
            </>
            {/* } */}
            <div className={classes.knowMoreSection}>
              <div>Click here to know more about Heart conditions.</div>
              <div>
                <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                <div
                  onClick={() => setAskGPT("HeartGPT")}
                  style={{ cursor: "pointer" }}
                >
                  Ask SkidsGPT
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={classes.close}
              onClick={() => setAskGPT("")}
              style={{ color: "#222CC9" }}
            >
              Close Chat
            </div>
            <SkidsGPT organ={"heart"} />
          </>
        );

      break;

    case "Lung":
      estimationTitle = "Lungs auscultaion";
      componentEstimation = (
        <>
          {askGPT !== "Lung" ? (
            <>
              {
                // (screenData?.screeningPackageDetails?.lungs.length === 0)
                //   ?
                //   <>
                //     <GetAdvancePhysical
                //       titleText={"Lungs Screening is part of Advanced Package."}
                //       susbTitleText={"List of Parameter in Lungs Screening"}
                //       tests={[
                //         "Respiratory Sounds", "Dyspnea", "Wheezing", "Rattling", "Cough"
                //       ]}
                //     />
                //   </>
                //   :
                <>
                  <div className={classes.headingWithCheck}>
                    <div className={classes.headingText}>Left Point</div>
                    <CheckCircleIcon
                      sx={{ fill: "#149457", fontSize: "17px" }}
                    />
                  </div>
                  {/* <div style={{ margin: '3rem 0' }}>
                    {screenData?.ent?.entLungsAudio_1 ?
                      <Audioplayer url={screenData?.ent?.entLungsAudio_1} />
                      : <h3>No data available at th time!</h3>}
                  </div> */}
                  <div style={{ margin: "3rem 0" }}>
                    {screenData?.lungsAssessment?.lungReportUrl_1 ? (
                      <iframe
                        src={screenData?.lungsAssessment?.lungReportUrl_1}
                        style={{ width: "100%" }}
                        height="1000"
                        frameBorder="0"
                      ></iframe>
                    ) : screenData?.entAssessment?.lungsReportUrl_1 ? (
                      <iframe
                        src={screenData?.entAssessment?.lungsReportUrl_1}
                        style={{ width: "100%", marginBottom: "3rem" }}
                        height="1000"
                        frameBorder="0"
                      ></iframe>
                    ) : (
                      <h3>No data available at th time!</h3>
                    )}
                  </div>
                  <div className={classes.headingWithCheck}>
                    <div className={classes.headingText}>Right Point</div>
                    <CheckCircleIcon
                      sx={{ fill: "#149457", fontSize: "17px" }}
                    />
                  </div>
                  {/* <div style={{ margin: '3rem 0' }}>
                    {screenData?.ent?.entLungsAudio_2 ?
                      <Audioplayer url={screenData?.ent?.entLungsAudio_2} />
                      : <h3>No data available at th time!</h3>}
                  </div> */}
                  <div style={{ margin: "3rem 0" }}>
                    {screenData?.lungsAssessment?.lungReportUrl_2 ? (
                      <iframe
                        src={screenData?.lungsAssessment?.lungReportUrl_2}
                        style={{ width: "100%" }}
                        height="1000"
                        frameBorder="0"
                      ></iframe>
                    ) : screenData?.entAssessment?.lungsReportUrl_2 ? (
                      <iframe
                        src={screenData?.entAssessment?.lungsReportUrl_2}
                        style={{ width: "100%" }}
                        height="1000"
                        frameBorder="0"
                      ></iframe>
                    ) : (
                      <h3>No data available at th time!</h3>
                    )}

                    {screenData?.higo?.lungs?.map((audioUrl, index) => (
                      <div className={classes.audioPlayerWrapper}>
                        <p style={{ fontSize: "10px" }}>No. {index + 1}</p>
                        <Audioplayer url={audioUrl} />
                      </div>
                    ))}
                    {screenData?.lungsAssessment?.auscultationValidation
                      ?.length > 0 &&
                      screenData?.lungsAssessment?.auscultationValidation?.map(
                        (validationObj) => {
                          return (
                            <div className={classes.docInterpretation}>
                              <img src={stethoscopeCheck} alt="" />
                              <div>
                                <div>Doctor Interpretation</div>
                                <div>{validationObj.interpretation}</div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                </>
              }
              <div className={classes.knowMoreSection}>
                <div>Click here to know more about Lung conditions.</div>
                <div>
                  <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                  <div
                    onClick={() => setAskGPT("Lungs")}
                    style={{ cursor: "pointer" }}
                  >
                    Ask SkidsGPT
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={classes.close}
                onClick={() => setAskGPT("")}
                style={{ color: "#222CC9" }}
              >
                Close Chat
              </div>
              <SkidsGPT organ={"Lung"} />
            </>
          )}
        </>
      );
      break;

    case "Abdominal":
      estimationTitle = "Abdominal auscultation";
      componentEstimation = (
        <>
          {askGPT !== "Abdominal" ? (
            <>
              {screenData?.screeningPackageDetails?.abdomen.length === 0 ? (
                <>
                  <GetAdvancePhysical
                    titleText={"Abdomen Screening is part of Advanced Package."}
                    susbTitleText={"List of Parameter in Abdomen Screening"}
                    tests={[
                      "Bowel Obstruction",
                      "Abdominal Bruit",
                      "Intestinal Sounds",
                      "Paralytic Ileus",
                    ]}
                  />
                </>
              ) : (
                <>
                  <div className={classes.headingWithCheck}>
                    <div className={classes.headingText}>Abdomen</div>
                    <CheckCircleIcon
                      sx={{ fill: "#149457", fontSize: "17px" }}
                    />
                  </div>
                  {screenData?.higo?.abdominal?.map((audioUrl, index) => (
                    <div className={classes.audioPlayerWrapper}>
                      <p style={{ fontSize: "10px" }}>No. {index + 1}</p>
                      <Audioplayer url={audioUrl} />
                    </div>
                  ))}
                  {screenData?.abdomenAssessment?.validation.length > 0 &&
                    screenData?.abdomenAssessment?.validation.map(
                      (validationObj) => {
                        return (
                          <div className={classes.docInterpretation}>
                            <img src={stethoscopeCheck} alt="" />
                            <div>
                              <div>Doctor Interpretation</div>
                              <div>{validationObj.interpretation}</div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </>
              )}
              <div className={classes.knowMoreSection}>
                <div>Click here to know more about Abdominal conditions.</div>
                <div>
                  <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                  <div
                    onClick={() => setAskGPT("Abdominal")}
                    style={{ cursor: "pointer" }}
                  >
                    Ask SkidsGPT
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={classes.close}
                onClick={() => setAskGPT("")}
                style={{ color: "#222CC9" }}
              >
                Close Chat
              </div>
              <SkidsGPT organ={"Abdominal"} />
            </>
          )}
        </>
      );
      break;

    case "Skin":
      estimationTitle = "Skin images & videos";
      componentEstimation = (
        <>
          {askGPT !== "Skin" ? (
            <>
              {screenData?.screeningPackageDetails?.skin.length === 0 ? (
                <>
                  <GetAdvancePhysical
                    titleText={"Skin Screening is part of Advanced Package."}
                    susbTitleText={"List of Parameter in Skin Screening"}
                    tests={["Scabies", "Rashes", "Blisters", "Urticaria"]}
                  />
                </>
              ) : (
                <>
                  <div className={classes.headingWithCheck}>
                    <div className={classes.headingText}>Skin</div>
                    <CheckCircleIcon
                      sx={{ fill: "#149457", fontSize: "17px" }}
                    />
                  </div>
                  <div className={classes.slides_player_container}>
                    <VideoSlideModal
                      videoUrl={screenData?.higo?.skinVideo}
                      imgs={screenData?.higo?.skin}
                    />
                  </div>
                  {screenData?.skinAssessment?.validation.length > 0 &&
                    screenData?.skinAssessment?.validation.map(
                      (validationObj) => {
                        return (
                          <div className={classes.docInterpretation}>
                            <img src={stethoscopeCheck} alt="" />
                            <div>
                              <div>Doctor Interpretation</div>
                              <div>{validationObj.interpretation}</div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </>
              )}
              <div className={classes.knowMoreSection}>
                <div>Click here to know more about Skin conditions.</div>
                <div>
                  <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                  <div
                    onClick={() => setAskGPT("Skin")}
                    style={{ cursor: "pointer" }}
                  >
                    Ask SkidsGPT
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={classes.close}
                onClick={() => setAskGPT("")}
                style={{ color: "#222CC9" }}
              >
                Close Chat
              </div>
              <SkidsGPT organ={"Skin"} />
            </>
          )}
        </>
      );
      break;

    case "Dental":
      estimationTitle = <span className={classes.dentalTitle}>Dental</span>;
      componentEstimation = (
        <>
          {askGPT !== "Dental" ? (
            <>
              {screenData?.screeningPackageDetails?.dental.length === 0 ? (
                <>
                  <GetAdvancePhysical
                    titleText={"Dental Screening is part of Advanced Package."}
                    susbTitleText={"List of Parameter in Dental Screening"}
                    tests={[
                      "DMFT",
                      "CAVITIES",
                      "GINGIVITIS",
                      "ORTHODONTIC_PROBLEM",
                      "DECOLORATION",
                    ]}
                  />
                </>
              ) : (
                <>
                  {!screenData?.dentalAssessment?.tooth ? (
                    <>
                      <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>
                          Dental (Upper Jaw)
                        </div>
                        <CheckCircleIcon
                          sx={{ fill: "#149457", fontSize: "17px" }}
                        />
                      </div>
                      <div style={{ margin: "3rem 0" }}>
                        {screenData?.ent?.entDentalVideo_1 ? (
                          <div className={classes.slides_player_container}>
                            <VideoSlideModal
                              videoUrl={screenData?.ent?.entDentalVideo_1}
                            />
                          </div>
                        ) : (
                          <h3>No data available at th time!</h3>
                        )}
                      </div>
                      <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>
                          Dental (Lower Jaw)
                        </div>
                        <CheckCircleIcon
                          sx={{ fill: "#149457", fontSize: "17px" }}
                        />
                      </div>
                      <div style={{ margin: "3rem 0" }}>
                        {screenData?.ent?.entDentalVideo_2 ? (
                          <div className={classes.slides_player_container}>
                            <VideoSlideModal
                              videoUrl={screenData?.ent?.entDentalVideo_2}
                            />
                          </div>
                        ) : (
                          <h3>No data available at th time!</h3>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={classes.dentalConMain}>
                        <div>
                          <div>
                            <div
                              className={
                                checkDentalCondition(
                                  screenData?.dentalAssessment?.tooth,
                                  "Q1"
                                )
                                  ? classes.reviewsNormal
                                  : classes.reviewsNeedAttention
                              }
                            >
                              {checkDentalCondition(
                                screenData?.dentalAssessment?.tooth,
                                "Q1"
                              )
                                ? "Normal"
                                : "NEED ATTENTION"}
                            </div>
                            <div
                              className={
                                checkDentalCondition(
                                  screenData?.dentalAssessment?.tooth,
                                  "Q3"
                                )
                                  ? classes.reviewsNormal
                                  : classes.reviewsNeedAttention
                              }
                            >
                              {checkDentalCondition(
                                screenData?.dentalAssessment?.tooth,
                                "Q3"
                              )
                                ? "Normal"
                                : "NEED ATTENTION"}
                            </div>
                          </div>
                          <div>
                            <DentalJaw
                              tooths={screenData?.dentalAssessment?.tooth}
                              disabled
                            />
                          </div>
                          <div>
                            <div
                              className={
                                checkDentalCondition(
                                  screenData?.dentalAssessment?.tooth,
                                  "Q2"
                                )
                                  ? classes.reviewsNormal
                                  : classes.reviewsNeedAttention
                              }
                            >
                              {checkDentalCondition(
                                screenData?.dentalAssessment?.tooth,
                                "Q2"
                              )
                                ? "Normal"
                                : "NEED ATTENTION"}
                            </div>
                            <div
                              className={
                                checkDentalCondition(
                                  screenData?.dentalAssessment?.tooth,
                                  "Q4"
                                )
                                  ? classes.reviewsNormal
                                  : classes.reviewsNeedAttention
                              }
                            >
                              {checkDentalCondition(
                                screenData?.dentalAssessment?.tooth,
                                "Q4"
                              )
                                ? "Normal"
                                : "NEED ATTENTION"}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <div>
                              <div className={classes.decayed} /> <p>Decayed</p>
                            </div>
                            <div>
                              <div className={classes.missing} /> <p>Missing</p>
                            </div>
                            <div>
                              <div className={classes.filled} /> <p>Filled</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.hrLine} />
                      <br />
                      <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>DMFT Index</div>
                        <CheckCircleIcon
                          sx={{ fill: "#149457", fontSize: "17px" }}
                        />
                      </div>
                      <div className={classes.headingBold}>
                        <div>
                          {getDMFTIndex(
                            screenData?.dentalAssessment?.tooth?.[0].split(",")
                          )}
                        </div>
                      </div>
                      {screenData?.dentalAssessment
                        ?.DMFTIndexInterpretation && (
                        <div className={classes.docInterpretation}>
                          <img src={stethoscopeCheck} alt="" />
                          <div>
                            <div>Doctor Interpretation</div>
                            <div>
                              {
                                screenData?.dentalAssessment
                                  ?.DMFTIndexInterpretation
                              }
                            </div>
                          </div>
                        </div>
                      )}
                      <br />
                      <div className={classes.hrLine} />
                      <br />

                      <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Gingivitis</div>
                        <CheckCircleIcon
                          sx={{ fill: "#149457", fontSize: "17px" }}
                        />
                      </div>
                      <div className={classes.headingBold}>
                        <div>
                          {screenData?.dentalAssessment?.gingivitis === true
                            ? "Present"
                            : "Absent"}
                        </div>
                      </div>
                      {screenData?.dentalAssessment
                        ?.gingivitisInterpretation && (
                        <div className={classes.docInterpretation}>
                          <img src={stethoscopeCheck} alt="" />
                          <div>
                            <div>Doctor Interpretation</div>
                            <div>
                              {
                                screenData?.dentalAssessment
                                  ?.gingivitisInterpretation
                              }
                            </div>
                          </div>
                        </div>
                      )}
                      <br />
                      <div className={classes.hrLine} />
                      <br />

                      <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>
                          Orthodontic Problem
                        </div>
                        <CheckCircleIcon
                          sx={{ fill: "#149457", fontSize: "17px" }}
                        />
                      </div>
                      <div className={classes.headingBold}>
                        <div>
                          {screenData?.dentalAssessment?.orthodontic === true
                            ? "Present"
                            : "Absent"}
                        </div>
                      </div>
                      {screenData?.dentalAssessment
                        ?.orthodonticInterpretation && (
                        <div className={classes.docInterpretation}>
                          <img src={stethoscopeCheck} alt="" />
                          <div>
                            <div>Doctor Interpretation</div>
                            <div>
                              {
                                screenData?.dentalAssessment
                                  ?.orthodonticInterpretation
                              }
                            </div>
                          </div>
                        </div>
                      )}
                      <br />
                      <div className={classes.hrLine} />
                      <br />

                      <div className={classes.headingWithCheck}>
                        <div className={classes.headingText}>Decoloration</div>
                        <CheckCircleIcon
                          sx={{ fill: "#149457", fontSize: "17px" }}
                        />
                      </div>
                      <div className={classes.headingBold}>
                        <div>
                          {screenData?.dentalAssessment?.decoloration === true
                            ? "Present"
                            : "Absent"}
                        </div>
                      </div>
                      {screenData?.dentalAssessment
                        ?.decolorationInterpretation && (
                        <div className={classes.docInterpretation}>
                          <img src={stethoscopeCheck} alt="" />
                          <div>
                            <div>Doctor Interpretation</div>
                            <div>
                              {
                                screenData?.dentalAssessment
                                  ?.decolorationInterpretation
                              }
                            </div>
                          </div>
                        </div>
                      )}
                      <br />
                      <div className={classes.hrLine} />
                      <div className={classes.hrLine} />
                    </>
                  )}
                </>
              )}
              <div className={classes.knowMoreSection}>
                <div>Click here to know more about Dental conditions.</div>
                <div>
                  <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                  <div
                    onClick={() => setAskGPT("Dental")}
                    style={{ cursor: "pointer" }}
                  >
                    Ask SkidsGPT
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={classes.close}
                onClick={() => setAskGPT("")}
                style={{ color: "#222CC9" }}
              >
                Close Chat
              </div>
              <SkidsGPT organ={"Dental"} />
            </>
          )}
        </>
      );
      break;
    case "VHR":
      const vitals = screenData?.entAssessment?.vitals;
      componentEstimation = (
        <>
          <div className={classes.headingWithCheck}>
            <div className={classes.headingText}>Vitals Report</div>
            <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
          </div>
          <div className={classes.headingBold}>
            {vitals?.faceAge && (
              <VitalsGaugeChart
                label="Face Age"
                value={parseFloat(vitals?.faceAge)}
                maxValue={100}
                suffix="years"
                colors={["#8eff8e"]}
              />
            )}
            {vitals?.BMI && (
              <VitalsGaugeChart
                label="BMI"
                value={parseFloat(vitals?.BMI)?.toFixed(1)}
                maxValue={40}
                colors={["#ffff8e", "#8eff8e", "#ffff8e", "#ff8e8e", "#ff444f"]}
                nrOfLevels={5}
              />
            )}
            {vitals?.bodyShapeIndex && (
              <VitalsGaugeChart
                label="Body Shape Index"
                value={parseFloat(vitals?.bodyShapeIndex)?.toFixed(1)}
                maxValue={10}
                colors={["#00de93", "#8eff8e", "#ffff8e", "#ff8e8e", "#ff444f"]}
                nrOfLevels={5}
              />
            )}
            {vitals?.waist2HeightRatio && (
              <VitalsGaugeChart
                label="Waist to Height Ratio"
                value={parseFloat(vitals?.waist2HeightRatio)?.toFixed(1)}
                maxValue={100}
                colors={["#ffff8e", "#8eff8e", "#ffff8e", "#ff8e8e", "#ff444f"]}
                // colors={["#ff0", "#0f0", "#ff0", "#ff8000", "#f00"]}
                nrOfLevels={5}
              />
            )}
            {vitals?.heartRateVariability && (
              <VitalsGaugeChart
                label="Heart Rate Variability"
                value={parseFloat(vitals?.heartRateVariability)?.toFixed(1)}
                maxValue={100}
                colors={["#ff8e8e", "#ffff8e", "#8eff8e"]}
              />
            )}
            {vitals?.mindStressIndex && (
              <VitalsGaugeChart
                label="Mind Stress Index"
                value={parseFloat(vitals?.mindStressIndex)?.toFixed(1)}
                maxValue={100}
                colors={["#00de93", "#8eff8e", "#ffff8e", "#ff8e8e", "#ff444f"]}
              />
            )}
            {vitals?.heartRate && (
              <VitalsGaugeChart
                label="Heart Rate"
                value={parseFloat(vitals?.heartRate)}
                maxValue={220}
                suffix="BPM"
                unit="BPM"
                colors={["#ffff8e", "#8eff8e", "#ffff8e"]}
                nrOfLevels={3}
              />
            )}
            {vitals?.respirationRate && (
              <VitalsGaugeChart
                label="Respiration Rate"
                value={parseFloat(vitals?.respirationRate)?.toFixed(1)}
                maxValue={80}
                suffix="RPM"
                unit="RPM"
                colors={["#ffff8e", "#8eff8e", "#ffff8e"]}
                nrOfLevels={3}
              />
            )}
            {vitals?.wellnessScore && (
              <VitalsGaugeChart
                label="Wellness Score"
                value={parseFloat(vitals?.wellnessScore)}
                maxValue={150}
                nrOfLevels={5}
                // colors={["#f00", "#ff8000", "#ff0", "#80ff00", "#0f0"]}
                colors={["#ff444f", "#ff8e8e", "#ffff8e", "#8eff8e", "#00de93"]}
              />
            )}
          </div>
        </>
      );
      break;
    case "Cumulative Report":
      estimationTitle = "Cumulative Report";
      // componentEstimation =
      // <>
      //   <>
      //     <div className={classes.chatScreen} style={{ fontWeight: 500 }}>
      //       <Avatar {...stringAvatar(`${user?.given_name}`)} style={{ marginRight: 20 }} />
      //       <p>Hey SkidsGPT, Please generate cumulative health report for this screening.</p>
      //     </div>
      //     <div className={classes.reportArea}>
      //       <div className={classes.avatar}>
      //         <img src={logo} width={15} />
      //       </div>
      //       <div style={{ whiteSpace: 'pre-line', fontSize: 14, fontWeight: 500 }}>
      //         <p>Patient Information</p>
      //         <ul style={{ marginLeft: 24, marginBottom: 15 }}>
      //           <li>Name: {firstName + ' ' + lastName}</li>
      //           <li>DOB: {keys['DOB']}</li>
      //           <li>Height: {keys['Height']}</li>
      //           <li>Weight: {keys['Weight']}</li>
      //         </ul>
      //         <p>Vital Signs</p>
      //         <ul style={{ marginLeft: 24, marginBottom: 15 }}>
      //           <li>SPO2: {keys['SPO2']}</li>
      //           <li>SPGHb: {keys['SPGHb']}</li>
      //           <li>Heart Rate: {keys['Heart Rate']}</li>
      //           <li>Respiratory Rate: {keys['Respiratory Rate']}</li>
      //           <li>BMI: {keys['BMI']}</li>
      //         </ul>
      //         <p>Medical History</p>
      //         <ul style={{ marginLeft: 24, marginBottom: 15 }}>
      //           <li>Skin Allergy: {keys['Skin Allergy']}</li>
      //           <li>Cough: {keys['Cough']}</li>
      //         </ul>
      //         <p>Dental</p>
      //         <ul style={{ marginLeft: 24, marginBottom: 15 }}>
      //           <li>{keys['Dental']}</li>
      //         </ul>
      //         <p>Vision</p>
      //         <ul style={{ marginLeft: 24 }}>
      //           <li>{keys['Eyes']}</li>
      //         </ul>
      //         <div style={{ lineHeight: 1.3 }}>
      //           {response}
      //         </div>
      //       </div>
      //     </div>
      //   </>
      //   <TextField
      //     hiddenLabel
      //     id="filled-hidden-label-small"
      //     variant="outlined"
      //     size="small"
      //     placeholder="Type your prompt here"
      //     disabled
      //     fullWidth

      //   />
      // </>
      break;
  }

  // ---------------------------------------------------------------------------
  //------------------- Dynamic content for behavioural screening------------------
  // ---------------------------------------------------------------------------
  switch (behaviour) {
    case "adhd":
      behaviourComponent = (
        <>
          {screenData?.screeningPackageDetails?.behavioralScreening?.includes(
            "ADHD"
          ) ? (
            Math.abs(moment(DOB).diff(moment(), "months")) >= 72 ? (
              <div>
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Total questions</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <div>{bhvScreenData?.ADHD?.response.length}</div>
                </div>
                <br />
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Score</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <div>{bhvScreenData?.ADHD?.outcome}</div>
                  <br />
                  <div className={classes.ClickHereBlueBlack}>
                    <span>
                      <a
                        href="/behavioral-report/ADHD"
                        target="blank"
                        style={
                          !getSchoolReportStatus("ADHD")
                            ? { color: "#1740A1", pointerEvents: "none" }
                            : { color: "#1740A1" }
                        }
                      >
                        Click here
                      </a>
                    </span>
                    <p>to view detailed report for ADHD assessment</p>
                  </div>
                </div>
                {bhvScreenData?.ADHD?.interpretation && (
                  <div className={classes.docInterpretation}>
                    <img src={stethoscopeCheck} alt="" />
                    <div>
                      <div>Doctor Interpretation</div>
                      <div>{bhvScreenData?.ADHD?.interpretation}</div>
                    </div>
                  </div>
                )}
                <br />
                <div className={classes.hrLine} />
                <br />
                <BhvCertification img3={true} />
                <div className={classes.knowMoreSection}>
                  <div>Click here to know more about ADHD.</div>
                  <div>
                    <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                    <div>Ask SkidsGPT</div>
                  </div>
                </div>
              </div>
            ) : (
              <GetAdvanceBehavioral
                titleText={"ADHD form is not applicable for this age group"}
              />
            )
          ) : (
            <GetAdvanceBehavioral
              titleText={"ADHD is part of Advanced Package."}
            />
          )}
        </>
      );
      break;
    case "autism":
      behaviourComponent = (
        <>
          {screenData?.screeningPackageDetails?.behavioralScreening?.includes(
            "AUTISM"
          ) ? (
            Math.abs(moment(DOB).diff(moment(), "months")) >= 16 &&
            Math.abs(moment(DOB).diff(moment(), "months")) <= 204 ? (
              <div>
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Total questions</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <div>{bhvScreenData?.AUTISM?.response.length}</div>
                </div>
                <br />
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Score</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <div>{bhvScreenData?.AUTISM?.outcome}</div>
                  <br />
                  <div className={classes.ClickHereBlueBlack}>
                    <span>
                      <a
                        href="/behavioral-report/AUTISM"
                        target="blank"
                        style={
                          !getSchoolReportStatus("AUTISM")
                            ? { color: "#1740A1", pointerEvents: "none" }
                            : { color: "#1740A1" }
                        }
                      >
                        Click here
                      </a>
                    </span>
                    <p>to view detailed report for Autism assessment</p>
                  </div>
                </div>
                {bhvScreenData?.AUTISM?.interpretation && (
                  <div className={classes.docInterpretation}>
                    <img src={stethoscopeCheck} alt="" />
                    <div>
                      <div>Doctor Interpretation</div>
                      <div> {bhvScreenData?.AUTISM?.interpretation}</div>
                    </div>
                  </div>
                )}
                <br />
                <div className={classes.hrLine} />
                <br />
                <BhvCertification copyRight={true} adopted={true} />
                <div className={classes.knowMoreSection}>
                  <div>Click here to know more about Autism.</div>
                  <div>
                    <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                    <div>Ask SkidsGPT</div>
                  </div>
                </div>
              </div>
            ) : (
              <GetAdvanceBehavioral
                titleText={"Autism form is not applicable for this age group"}
              />
            )
          ) : (
            <GetAdvanceBehavioral
              titleText={"Autism is part of Advanced Package."}
            />
          )}
        </>
      );
      break;
    case "digitalDependency":
      behaviourComponent = (
        <>
          {screenData?.screeningPackageDetails?.behavioralScreening?.includes(
            "DIGITAL_DEPENDENCY"
          ) ? (
            Math.abs(moment(DOB).diff(moment(), "months")) >= 24 &&
            Math.abs(moment(DOB).diff(moment(), "months")) <= 204 ? (
              <div>
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Total questions</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <div>
                    {bhvScreenData?.INTERNET_ADDICTION?.response.length}
                  </div>
                </div>
                <br />
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Score</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <div>{bhvScreenData?.INTERNET_ADDICTION?.outcome}</div>
                  <br />
                  <div className={classes.ClickHereBlueBlack}>
                    <span>
                      <a
                        href="/behavioral-report/DIGITAL_DEPENDENCY"
                        target="blank"
                        style={
                          !getSchoolReportStatus("INTERNET_ADDICTION")
                            ? { color: "#1740A1", pointerEvents: "none" }
                            : { color: "#1740A1" }
                        }
                      >
                        Click here
                      </a>
                    </span>
                    <p>
                      to view detailed report for Digital Dependency assessment
                    </p>
                  </div>
                </div>
                {bhvScreenData?.INTERNET_ADDICTION?.interpretation && (
                  <div className={classes.docInterpretation}>
                    <img src={stethoscopeCheck} alt="" />
                    <div>
                      <div>Doctor Interpretation</div>
                      <div>
                        {bhvScreenData?.INTERNET_ADDICTION?.interpretation}
                      </div>
                    </div>
                  </div>
                )}
                <br />
                <div className={classes.hrLine} />
                <br />
                <BhvCertification copyRight={true} DASC={true} />
                <div className={classes.knowMoreSection}>
                  <div>Click here to know more about Digital Dependency.</div>
                  <div>
                    <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                    <div>Ask SkidsGPT</div>
                  </div>
                </div>
              </div>
            ) : (
              <GetAdvanceBehavioral
                titleText={
                  "Digital Dependency form is not applicable for this age group"
                }
              />
            )
          ) : (
            <GetAdvanceBehavioral
              titleText={"Digital Dependency is part of Advanced Package."}
            />
          )}
        </>
      );
      break;
    case "anxiety":
      behaviourComponent = (
        <>
          {screenData?.screeningPackageDetails?.behavioralScreening?.includes(
            "ANXIETY"
          ) ? (
            Math.abs(moment(DOB).diff(moment(), "months")) >= 96 &&
            Math.abs(moment(DOB).diff(moment(), "months")) <= 216 ? (
              <div>
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Total questions</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <div>{bhvScreenData?.ANXIETY.response.length}</div>
                </div>
                <br />
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Score</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <ul style={{ marginLeft: 15 }}>
                    {bhvScreenData?.ANXIETY?.outcome?.map((x) => {
                      return <li>{x}</li>;
                    })}
                  </ul>
                  <br />
                  <div className={classes.ClickHereBlueBlack}>
                    <span>
                      <a
                        href="/behavioral-report/ANXIETY"
                        target="blank"
                        style={
                          !getSchoolReportStatus("ANXIETY")
                            ? { color: "#1740A1", pointerEvents: "none" }
                            : { color: "#1740A1" }
                        }
                      >
                        Click here
                      </a>
                    </span>
                    <p>to view detailed report for anxiety assessment</p>
                  </div>
                </div>
                {bhvScreenData?.ANXIETY?.interpretation && (
                  <div className={classes.docInterpretation}>
                    <img src={stethoscopeCheck} alt="" />
                    <div>
                      <div>Doctor Interpretation</div>
                      <div>{bhvScreenData?.ANXIETY?.interpretation}</div>
                    </div>
                  </div>
                )}
                <br />
                <div className={classes.hrLine} />
                <br />
                <BhvCertification copyRight={true} scared={true} />
                <div className={classes.knowMoreSection}>
                  <div>Click here to know more about anxiety.</div>
                  <div>
                    <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                    <div>Ask SkidsGPT</div>
                  </div>
                </div>
              </div>
            ) : (
              <GetAdvanceBehavioral
                titleText={"Anxiety form is not applicable for this age group"}
              />
            )
          ) : (
            <GetAdvanceBehavioral
              titleText={"Anxiety is part of Advanced Package."}
            />
          )}
        </>
      );
      break;
    case "conductDisorder":
      behaviourComponent = (
        <>
          {screenData?.screeningPackageDetails?.behavioralScreening?.includes(
            "CONDUCT_DISORDER"
          ) ? (
            Math.abs(moment(DOB).diff(moment(), "months")) >= 72 ? (
              <div>
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Total questions</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <div>{bhvScreenData?.CONDUCT_DISORDER.response.length}</div>
                </div>
                <br />
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Score</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <div>{bhvScreenData?.CONDUCT_DISORDER?.outcome}</div>
                  <br />
                  <div className={classes.ClickHereBlueBlack}>
                    <span>
                      <a
                        href="/behavioral-report/CONDUCT_DISORDER"
                        target="blank"
                        style={
                          !getSchoolReportStatus("CONDUCT_DISORDER")
                            ? { color: "#1740A1", pointerEvents: "none" }
                            : { color: "#1740A1" }
                        }
                      >
                        Click here
                      </a>
                    </span>
                    <p>
                      to view detailed report for conduct disorder assessment
                    </p>
                  </div>
                </div>
                {bhvScreenData?.CONDUCT_DISORDER?.interpretation && (
                  <div className={classes.docInterpretation}>
                    <img src={stethoscopeCheck} alt="" />
                    <div>
                      <div>Doctor Interpretation</div>
                      <div>
                        {bhvScreenData?.CONDUCT_DISORDER?.interpretation}
                      </div>
                    </div>
                  </div>
                )}
                <br />
                <div className={classes.hrLine} />
                <BhvCertification img3={true} />
                <br />
                <div className={classes.knowMoreSection}>
                  <div>Click here to know more about conduct disorder.</div>
                  <div>
                    <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                    <div>Ask SkidsGPT</div>
                  </div>
                </div>
              </div>
            ) : (
              <GetAdvanceBehavioral
                titleText={
                  "Conduct disorder form is not applicable for this age group"
                }
              />
            )
          ) : (
            <GetAdvanceBehavioral
              titleText={"Conduct disorder is part of Advanced Package."}
            />
          )}
        </>
      );
      break;
    case "odd":
      behaviourComponent = (
        <>
          {screenData?.screeningPackageDetails?.behavioralScreening?.includes(
            "OPPOSITIONAL_DEFIANT_DISORDER"
          ) ? (
            Math.abs(moment(DOB).diff(moment(), "months")) >= 72 ? (
              <div>
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Total questions</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <div>{bhvScreenData?.ODD.response.length}</div>
                </div>
                <br />
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Score</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <div>{bhvScreenData?.ODD?.outcome}</div>
                  <br />
                  <div className={classes.ClickHereBlueBlack}>
                    <span>
                      <a
                        href="/behavioral-report/OPPOSITIONAL_DEFIANT_DISORDER"
                        target="blank"
                        style={
                          !getSchoolReportStatus("ODD")
                            ? { color: "#1740A1", pointerEvents: "none" }
                            : { color: "#1740A1" }
                        }
                      >
                        Click here
                      </a>
                    </span>
                    <p>to view detailed report for odd assessment</p>
                  </div>
                </div>
                {bhvScreenData?.ODD?.interpretation && (
                  <div className={classes.docInterpretation}>
                    <img src={stethoscopeCheck} alt="" />
                    <div>
                      <div>Doctor Interpretation</div>
                      <div>{bhvScreenData?.ODD?.interpretation}</div>
                    </div>
                  </div>
                )}
                <br />
                <div className={classes.hrLine} />
                <BhvCertification img3={true} />
                <br />
                <div className={classes.knowMoreSection}>
                  <div>Click here to know more about ODD.</div>
                  <div>
                    <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                    <div>Ask SkidsGPT</div>
                  </div>
                </div>
              </div>
            ) : (
              <GetAdvanceBehavioral
                titleText={
                  "Oppositional Defiant Disorder form is not applicable for this age group"
                }
              />
            )
          ) : (
            <GetAdvanceBehavioral
              titleText={
                "Oppositional Defiant Disorder is part of Advanced Package."
              }
            />
          )}
        </>
      );
      break;
    case "dipression":
      behaviourComponent = (
        <>
          {screenData?.screeningPackageDetails?.behavioralScreening?.includes(
            "DEPRESSION"
          ) ? (
            Math.abs(moment(DOB).diff(moment(), "months")) >= 132 &&
            Math.abs(moment(DOB).diff(moment(), "months")) <= 216 ? (
              <div>
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Total questions</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <div>{bhvScreenData?.DEPRESSION.response.length}</div>
                </div>
                <br />
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Score</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <div>{bhvScreenData?.DEPRESSION?.outcome}</div>
                  <br />
                  <div className={classes.ClickHereBlueBlack}>
                    <span>
                      <a
                        href="/behavioral-report/DEPRESSION"
                        target="blank"
                        style={
                          !getSchoolReportStatus("DEPRESSION")
                            ? { color: "#1740A1", pointerEvents: "none" }
                            : { color: "#1740A1" }
                        }
                      >
                        Click here
                      </a>
                    </span>
                    <p>to view detailed report for depression assessment</p>
                  </div>
                </div>
                {bhvScreenData?.DEPRESSION?.interpretation && (
                  <div className={classes.docInterpretation}>
                    <img src={stethoscopeCheck} alt="" />
                    <div>
                      <div>Doctor Interpretation</div>
                      <div>{bhvScreenData?.DEPRESSION?.interpretation}</div>
                    </div>
                  </div>
                )}
                <br />
                <div className={classes.hrLine} />
                <br />
                <BhvCertification copyRight={true} PHQ={true} />
                <div className={classes.knowMoreSection}>
                  <div>Click here to know more about depression.</div>
                  <div>
                    <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                    <div>Ask SkidsGPT</div>
                  </div>
                </div>
              </div>
            ) : (
              <GetAdvanceBehavioral
                titleText={"Depression is not applicable for this age group"}
              />
            )
          ) : (
            <GetAdvanceBehavioral
              titleText={"Depression is part of Advanced Package."}
            />
          )}
        </>
      );
      break;
    case "developmentMilestone":
      behaviourComponent = (
        <>
          {screenData?.screeningPackageDetails?.behavioralScreening?.includes(
            "DEVELOPMENT_MILESTONES"
          ) ? (
            Math.abs(moment(DOB).diff(moment(), "months")) >= 1 &&
            Math.abs(moment(DOB).diff(moment(), "months")) <= 65 ? (
              <div>
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Total questions</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <div>
                    {bhvScreenData?.DEVELOPMENTAL_DELAY.response.length}
                  </div>
                </div>
                <br />
                <div className={classes.headingWithCheck}>
                  <div className={classes.headingText}>Score</div>
                  <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
                </div>
                <div className={classes.headingBold}>
                  <div>
                    <ul style={{ marginLeft: 15 }}>
                      <li>
                        Develpoment Milestone -{" "}
                        {bhvScreenData?.DEVELOPMENTAL_DELAY?.outcome}
                      </li>
                      <li>
                        Emotional and Behavioral -{" "}
                        {bhvScreenData?.EMOTIONAL_AND_BEHAVIORAL?.outcome}
                      </li>
                    </ul>
                    {/* {bhvScreenData?.DEVELOPMENTAL_DELAY?.outcome} */}
                  </div>
                  <br />
                  <div className={classes.ClickHereBlueBlack}>
                    <span>
                      <a
                        href="/behavioral-report/DEVELOPMENTAL_DELAY"
                        target="blank"
                        style={
                          !getSchoolReportStatus("DEVELOPMENTAL_DELAY")
                            ? { color: "#1740A1", pointerEvents: "none" }
                            : { color: "#1740A1" }
                        }
                      >
                        Click here
                      </a>
                    </span>
                    <p>
                      to view detailed report for development milestone
                      assessment
                    </p>
                  </div>
                </div>
                {bhvScreenData?.DEVELOPMENTAL_DELAY?.interpretation && (
                  <div className={classes.docInterpretation}>
                    <img src={stethoscopeCheck} alt="" />
                    <div>
                      <div>Doctor Interpretation</div>
                      <div>
                        {bhvScreenData?.DEVELOPMENTAL_DELAY?.interpretation}
                      </div>
                    </div>
                  </div>
                )}
                <br />
                <div className={classes.hrLine} />
                <br />
                <BhvCertification swyc={true} tufts={true} />
                <div className={classes.knowMoreSection}>
                  <div>
                    Click here to know more about development milestone.
                  </div>
                  <div>
                    <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                    <div>Ask SkidsGPT</div>
                  </div>
                </div>
              </div>
            ) : (
              <GetAdvanceBehavioral
                titleText={
                  "Development Delay form is not applicable for this age group"
                }
              />
            )
          ) : (
            <GetAdvanceBehavioral
              titleText={"Development Milestone is part of Advanced Package."}
            />
          )}
        </>
      );
      break;
  }

  const issuePresent = (x) => {
    switch (x) {
      case "temperature":
        if (screenData?.vitalSignsAssessment?.temperature > 38) {
          return true;
        } else {
          return false;
        }
        break;
      case "heartRate":
        if (
          screenData?.student?.age >= 1 &&
          screenData?.student?.age <= 3 &&
          (screenData?.vitalSignsAssessment?.heartRate < 90 ||
            screenData?.vitalSignsAssessment?.heartRate > 150)
        ) {
          return true;
        } else if (
          screenData?.student?.age >= 3 &&
          screenData?.student?.age <= 5 &&
          (screenData?.vitalSignsAssessment?.heartRate < 80 ||
            screenData?.vitalSignsAssessment?.heartRate > 140)
        ) {
          return true;
        } else if (
          screenData?.student?.age >= 5 &&
          screenData?.student?.age <= 12 &&
          (screenData?.vitalSignsAssessment?.heartRate < 70 ||
            screenData?.vitalSignsAssessment?.heartRate > 120)
        ) {
          return true;
        } else if (
          screenData?.student?.age >= 12 &&
          (screenData?.vitalSignsAssessment?.heartRate < 60 ||
            screenData?.vitalSignsAssessment?.heartRate > 100)
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case "hemoglobin":
        if (
          screenData?.student?.gender === "Male" &&
          (screenData?.vitalSignsAssessment?.hemoglobin < 12.7 ||
            screenData?.vitalSignsAssessment?.hemoglobin > 17.1)
        ) {
          return true;
        } else if (
          (screenData?.student?.gender === "Female" &&
            screenData?.vitalSignsAssessment?.hemoglobin < 12) ||
          screenData?.vitalSignsAssessment?.hemoglobin > 16
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case "bmi":
        if (
          screenData?.vitalSignsAssessment?.height &&
          screenData?.vitalSignsAssessment?.weight
        ) {
          let bmi = calcBmi(
            screenData?.vitalSignsAssessment?.height,
            screenData?.vitalSignsAssessment?.weight
          );
          if (bmi < 18 || bmi > 24) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
        break;
      case "spo2":
        if (screenData?.vitalSignsAssessment?.SpO2 < 95) {
          return true;
        } else {
          return false;
        }
        break;
      case "bp":
        if (screenData?.student?.gender === "Male") {
          if (
            screenData?.student?.age === 12 &&
            (screenData?.vitalSignsAssessment?.bloodPressure?.split("/")[0] >
              113 ||
              screenData?.vitalSignsAssessment?.bloodPressure?.split("/")[1] <
                75)
          ) {
            return true;
          } else if (
            screenData?.student?.age >= 13 &&
            (screenData?.vitalSignsAssessment?.bloodPressure?.split("/")[0] >
              120 ||
              screenData?.vitalSignsAssessment?.bloodPressure?.split("/")[1] <
                80)
          ) {
            return true;
          }
        } else if (screenData?.student?.gender === "Female") {
          if (
            screenData?.student?.age === 12 &&
            (screenData?.vitalSignsAssessment?.bloodPressure?.split("/")[0] >
              114 ||
              screenData?.vitalSignsAssessment?.bloodPressure?.split("/")[1] <
                75)
          ) {
            return true;
          } else if (
            screenData?.student?.age >= 13 &&
            (screenData?.vitalSignsAssessment?.bloodPressure?.split("/")[0] >
              120 ||
              screenData?.vitalSignsAssessment?.bloodPressure?.split("/")[1] <
                80)
          ) {
            return true;
          }
        } else {
          return false;
        }
        break;

      default:
        break;
    }
  };

  // -------------------------------------------------------------------------------------
  // ---------------------Dynamic content for learning disablities------------------------
  // -------------------------------------------------------------------------------------
  switch (learningDis) {
    case "dyslexia":
      learningDisComponent = (
        <>
          {screenData?.screeningPackageDetails?.learningScreening?.includes(
            "DYSLEXIA"
          ) ? (
            <div>
              <div className={classes.headingWithCheck}>
                <div className={classes.headingText}>Total questions</div>
                <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
              </div>
              <div className={classes.headingBold}>
                <div>
                  {bhvScreenData?.DYSLEXIA_1?.response &&
                    bhvScreenData?.DYSLEXIA_2?.response &&
                    bhvScreenData?.DYSLEXIA_1?.response.length +
                      bhvScreenData?.DYSLEXIA_2?.response.length}
                </div>
              </div>
              <br />
              <div className={classes.headingWithCheck}>
                <div className={classes.headingText}>Score</div>
                <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
              </div>
              <div className={classes.headingBold}>
                <div>
                  {bhvScreenData?.DYSLEXIA_2?.outcome
                    ? bhvScreenData?.DYSLEXIA_2?.outcome
                    : bhvScreenData?.DYSLEXIA_1?.outcome}
                </div>
                <br />
                <div className={classes.ClickHereBlueBlack}>
                  <span>
                    <a
                      href="/behavioral-report/DYSLEXIA"
                      target="blank"
                      style={
                        !getSchoolReportStatus("DYSLEXIA_1")
                          ? { color: "#1740A1", pointerEvents: "none" }
                          : { color: "#1740A1" }
                      }
                    >
                      Click here
                    </a>
                  </span>
                  <p>to view detailed report for Dyslexia assessment</p>
                </div>
              </div>
              {(bhvScreenData?.DYSLEXIA_1?.interpretation ||
                bhvScreenData?.DYSLEXIA_2?.interpretation) && (
                <div className={classes.docInterpretation}>
                  <img src={stethoscopeCheck} alt="" />
                  <div>
                    <div>Doctor Interpretation</div>
                    <div>
                      {bhvScreenData?.DYSLEXIA_2?.interpretation
                        ? bhvScreenData?.DYSLEXIA_2?.interpretation
                        : bhvScreenData?.DYSLEXIA_1?.interpretation}
                    </div>
                  </div>
                </div>
              )}
              <br />
              <div className={classes.hrLine} />
              <br />
              <BhvCertification copyRight={true} DSM={true} />
              <div className={classes.knowMoreSection}>
                <div>Click here to know more about Dyslexia.</div>
                <div>
                  <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                  <div>Ask SkidsGPT</div>
                </div>
              </div>
            </div>
          ) : (
            <GetAdvanceBehavioral
              titleText={"Dyslexia is part of Advanced Package."}
            />
          )}
        </>
      );
      break;
    case "dyscalculia":
      learningDisComponent = (
        <>
          {screenData?.screeningPackageDetails?.learningScreening?.includes(
            "DYSCALCULIA"
          ) ? (
            <div>
              <div className={classes.headingWithCheck}>
                <div className={classes.headingText}>Total questions</div>
                <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
              </div>
              <div className={classes.headingBold}>
                <div>
                  {bhvScreenData?.DYSCALCULIA_1?.response &&
                    bhvScreenData?.DYSCALCULIA_2?.response &&
                    bhvScreenData?.DYSCALCULIA_1?.response.length +
                      bhvScreenData?.DYSCALCULIA_2?.response.length}
                </div>
              </div>
              <br />
              <div className={classes.headingWithCheck}>
                <div className={classes.headingText}>Score</div>
                <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
              </div>
              <div className={classes.headingBold}>
                <div>
                  {bhvScreenData?.DYSCALCULIA_2.outcome
                    ? bhvScreenData?.DYSCALCULIA_2?.outcome
                    : bhvScreenData?.DYSCALCULIA_1?.outcome}
                </div>
                <br />
                <div className={classes.ClickHereBlueBlack}>
                  <span>
                    <a
                      href="/behavioral-report/DYSCALCULIA"
                      target="blank"
                      style={
                        !getSchoolReportStatus("DYSCALCULIA_1")
                          ? { color: "#1740A1", pointerEvents: "none" }
                          : { color: "#1740A1" }
                      }
                    >
                      Click here
                    </a>
                  </span>
                  <p>to view detailed report for Dyscalculia assessment</p>
                </div>
              </div>
              {(bhvScreenData?.DYSCALCULIA_1?.interpretation ||
                bhvScreenData?.DYSCALCULIA_2?.interpretation) && (
                <div className={classes.docInterpretation}>
                  <img src={stethoscopeCheck} alt="" />
                  <div>
                    <div>Doctor Interpretation</div>
                    <div>
                      {bhvScreenData?.DYSCALCULIA_2?.interpretation
                        ? bhvScreenData?.DYSCALCULIA_2?.interpretation
                        : bhvScreenData?.DYSCALCULIA_1?.interpretation}
                    </div>
                  </div>
                </div>
              )}
              <br />
              <div className={classes.hrLine} />
              <br />
              <BhvCertification copyRight={true} DSM={true} />
              <div className={classes.knowMoreSection}>
                <div>Click here to know more about Dyscalculia.</div>
                <div>
                  <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                  <div>Ask SkidsGPT</div>
                </div>
              </div>
            </div>
          ) : (
            <GetAdvanceBehavioral
              titleText={"Dyscalculia is part of Advanced Package."}
            />
          )}
        </>
      );
      break;
    case "dysgraphia":
      learningDisComponent = (
        <>
          {screenData?.screeningPackageDetails?.learningScreening?.includes(
            "DYSGRAPHIA"
          ) ? (
            <div>
              <div className={classes.headingWithCheck}>
                <div className={classes.headingText}>Total questions</div>
                <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
              </div>
              <div className={classes.headingBold}>
                <div>
                  {bhvScreenData?.DYSGRAPHIA_1?.response &&
                    bhvScreenData?.DYSGRAPHIA_2?.response &&
                    bhvScreenData?.DYSGRAPHIA_1?.response.length +
                      bhvScreenData?.DYSGRAPHIA_2?.response.length}
                </div>
              </div>
              <br />
              <div className={classes.headingWithCheck}>
                <div className={classes.headingText}>Score</div>
                <CheckCircleIcon sx={{ fill: "#149457", fontSize: "17px" }} />
              </div>
              <div className={classes.headingBold}>
                <div>
                  {bhvScreenData?.DYSGRAPHIA_2?.outcome
                    ? bhvScreenData?.DYSGRAPHIA_2?.outcome
                    : bhvScreenData?.DYSGRAPHIA_1?.outcome}
                </div>
                <br />
                <div className={classes.ClickHereBlueBlack}>
                  <span>
                    <a
                      href="/behavioral-report/DYSGRAPHIA"
                      target="blank"
                      style={
                        !getSchoolReportStatus("DYSGRAPHIA_1")
                          ? { color: "#1740A1", pointerEvents: "none" }
                          : { color: "#1740A1" }
                      }
                    >
                      Click here
                    </a>
                  </span>
                  <p>to view detailed report for dysgraphia assessment</p>
                </div>
              </div>
              {(bhvScreenData?.DYSGRAPHIA_1?.interpretation ||
                bhvScreenData?.DYSGRAPHIA_2?.interpretation) && (
                <div className={classes.docInterpretation}>
                  <img src={stethoscopeCheck} alt="" />
                  <div>
                    <div>Doctor Interpretation</div>
                    <div>
                      {bhvScreenData?.DYSGRAPHIA_2?.interpretation
                        ? bhvScreenData?.DYSGRAPHIA_2?.interpretation
                        : bhvScreenData?.DYSGRAPHIA_1?.interpretation}
                    </div>
                  </div>
                </div>
              )}
              <br />
              <div className={classes.hrLine} />
              <br />
              <BhvCertification copyRight={true} DSM={true} />
              <div className={classes.knowMoreSection}>
                <div>Click here to know more about dysgraphia.</div>
                <div>
                  <ChatBubbleOutlineIcon sx={{ fontSize: "18px" }} />
                  <div>Ask SkidsGPT</div>
                </div>
              </div>
            </div>
          ) : (
            <GetAdvanceBehavioral
              titleText={"Dysgraphia is part of Advanced Package."}
            />
          )}
        </>
      );
  }

  const PhysicalScreeningView = () => {
    return (
      <div className={classes.fullbody_physical_report_container}>
        <div>
          <div className={classes.leftBodyFit}>
            <div className={classes.fullbody_physical_outer_container}>
              <div className={classes.fullbody_physical_image_container}>
                {organ === "Lung" ? (
                  <Lungspoints
                    disabled={
                      screenData?.status === "PATIENT_ADDED" ||
                      !!!location.state.screeningDate ||
                      screenData?.screeningPackageDetails?.lungs.length === 0 ||
                      ((user?.groups?.includes("school-principal") ||
                        user?.groups?.includes("school-teacher") ||
                        user?.groups?.includes("school-admin")) &&
                        !school.isReport)
                    }
                    issue={
                      !!screenData?.lungsAssessment?.auscultationValidation?.[0]
                    }
                  />
                ) : organ === "Heart" ? (
                  <Heartpoint
                    disabled={
                      screenData?.status === "PATIENT_ADDED" ||
                      !!!location.state.screeningDate ||
                      screenData?.screeningPackageDetails?.heart.length === 0 ||
                      ((user?.groups?.includes("school-principal") ||
                        user?.groups?.includes("school-teacher") ||
                        user?.groups?.includes("school-admin")) &&
                        !school.isReport)
                    }
                    issue={
                      screenData?.heartAssessment
                        ?.auscultationValidation?.[0] ||
                      screenData?.heartAssessment?.estimationValidation?.[0]
                    }
                  />
                ) : (
                  <>
                    <Hair
                      top={"0px"}
                      right={"77px"}
                      disabled={
                        screenData?.status === "PATIENT_ADDED" ||
                        !!!location.state.screeningDate ||
                        screenData?.screeningPackageDetails?.hair.length ===
                          0 ||
                        ((user?.groups?.includes("school-principal") ||
                          user?.groups?.includes("school-teacher") ||
                          user?.groups?.includes("school-admin")) &&
                          !school.isReport)
                      }
                      onClick={() => {
                        setOrgan("Hair");
                      }}
                      active={organ === "Hair" ? true : false}
                      issue={
                        screenData?.hairAssessment?.dandruff ||
                        screenData?.hairAssessment?.pediculosis ||
                        screenData?.hairAssessment?.lossOfHair
                      }
                    />
                    <Eye
                      disabled={
                        screenData?.status === "PATIENT_ADDED" ||
                        !!!location.state.screeningDate ||
                        screenData?.screeningPackageDetails?.eye.length === 0 ||
                        ((user?.groups?.includes("school-principal") ||
                          user?.groups?.includes("school-teacher") ||
                          user?.groups?.includes("school-admin")) &&
                          !school.isReport)
                      }
                      top={"41px"}
                      right={"93px"}
                      onClick={() => {
                        setOrgan("Eye");
                      }}
                      active={organ === "Eye" ? true : false}
                      issue={!!screenData?.eyeAssessment?.validation?.[0]}
                      whichEye={"left"}
                    />
                    <Eye
                      top={"41px"}
                      right={"62px"}
                      disabled={
                        screenData?.status === "PATIENT_ADDED" ||
                        !!!location.state.screeningDate ||
                        screenData?.screeningPackageDetails?.eye.length === 0 ||
                        ((user?.groups?.includes("school-principal") ||
                          user?.groups?.includes("school-teacher") ||
                          user?.groups?.includes("school-admin")) &&
                          !school.isReport)
                      }
                      onClick={() => {
                        setOrgan("Eye");
                      }}
                      active={organ === "Eye" ? true : false}
                      issue={!!screenData?.eyeAssessment?.validation?.[0]}
                    />
                    <Ear
                      top={"50px"}
                      right={"115px"}
                      onClick={() => {
                        setOrgan("Ear");
                      }}
                      active={organ === "Ear" ? true : false}
                      issue={
                        !!screenData?.earAssessment?.leftEarValidation?.[0] ||
                        screenData?.hearingAssessment?.validation?.length > 0
                      }
                      whichEar={"left"}
                      disabled={
                        screenData?.status === "PATIENT_ADDED" ||
                        !!!location.state.screeningDate ||
                        screenData?.screeningPackageDetails?.ear.length === 0 ||
                        ((user?.groups?.includes("school-principal") ||
                          user?.groups?.includes("school-teacher") ||
                          user?.groups?.includes("school-admin")) &&
                          !school.isReport)
                      }
                    />
                    <Ear
                      top={"50px"}
                      right={"40px"}
                      onClick={() => {
                        setOrgan("Ear");
                      }}
                      active={organ === "Ear" ? true : false}
                      issue={
                        !!screenData?.earAssessment?.rightEarValidation?.[0] ||
                        screenData?.hearingAssessment?.validation?.length > 0
                      }
                      whichEar={"right"}
                      disabled={
                        screenData?.status === "PATIENT_ADDED" ||
                        !!!location.state.screeningDate ||
                        screenData?.screeningPackageDetails?.ear.length === 0 ||
                        ((user?.groups?.includes("school-principal") ||
                          user?.groups?.includes("school-teacher") ||
                          user?.groups?.includes("school-admin")) &&
                          !school.isReport)
                      }
                    />
                    <Dental
                      top={"92px"}
                      right={"76px"}
                      onClick={() => {
                        setOrgan("Dental");
                      }}
                      active={organ === "Dental" ? true : false}
                      issue={
                        getDMFTIndex(
                          screenData?.dentalAssessment?.tooth?.[0].split(",")
                        ) > 0 ||
                        screenData?.dentalAssessment?.decoloration ||
                        screenData?.dentalAssessment?.gingivitis ||
                        screenData?.dentalAssessment?.orthodontic
                      }
                      disabled={
                        screenData?.status === "PATIENT_ADDED" ||
                        !!!location.state.screeningDate ||
                        screenData?.screeningPackageDetails?.dental.length ===
                          0 ||
                        ((user?.groups?.includes("school-principal") ||
                          user?.groups?.includes("school-teacher") ||
                          user?.groups?.includes("school-admin")) &&
                          !school.isReport)
                      }
                    />
                    <Cough
                      top={"70px"}
                      right={"76px"}
                      onClick={() => {
                        setOrgan("Cough");
                      }}
                      active={organ === "Cough" ? true : false}
                      issue={!!screenData?.coughAssessment?.validation?.[0]}
                      disabled={
                        screenData?.status === "PATIENT_ADDED" ||
                        !!!location.state.screeningDate ||
                        !screenData?.screeningPackageDetails?.lungs.includes(
                          "COUGH"
                        ) ||
                        ((user?.groups?.includes("school-principal") ||
                          user?.groups?.includes("school-teacher") ||
                          user?.groups?.includes("school-admin")) &&
                          !school.isReport)
                      }
                    />
                    <Throat
                      top={"115px"}
                      right={"76px"}
                      disabled={
                        screenData?.status === "PATIENT_ADDED" ||
                        !!!location.state.screeningDate ||
                        screenData?.screeningPackageDetails?.throat.length ===
                          0 ||
                        ((user?.groups?.includes("school-principal") ||
                          user?.groups?.includes("school-teacher") ||
                          user?.groups?.includes("school-admin")) &&
                          !school.isReport)
                      }
                      onClick={() => {
                        setOrgan("Throat");
                      }}
                      active={organ === "Throat" ? true : false}
                      issue={!!screenData?.throatAssessment?.validation?.[0]}
                    />
                    <Lungs
                      top={"150px"}
                      right={"76px"}
                      disabled={
                        screenData?.status === "PATIENT_ADDED" ||
                        !!!location.state.screeningDate ||
                        screenData?.screeningPackageDetails?.lungs.length ===
                          0 ||
                        ((user?.groups?.includes("school-principal") ||
                          user?.groups?.includes("school-teacher") ||
                          user?.groups?.includes("school-admin")) &&
                          !school.isReport)
                      }
                      onClick={() => {
                        setOrgan("Lung");
                      }}
                      active={organ === "Lung" ? true : false}
                      issue={
                        !!screenData?.lungsAssessment
                          ?.auscultationValidation?.[0]
                      }
                    />
                    <Heart
                      top={"170px"}
                      right={"55px"}
                      disabled={
                        screenData?.status === "PATIENT_ADDED" ||
                        !!!location.state.screeningDate ||
                        screenData?.screeningPackageDetails?.heart.length ===
                          0 ||
                        ((user?.groups?.includes("school-principal") ||
                          user?.groups?.includes("school-teacher") ||
                          user?.groups?.includes("school-admin")) &&
                          !school.isReport)
                      }
                      onClick={() => {
                        setOrgan("Heart");
                      }}
                      active={organ === "Heart" ? true : false}
                      issue={
                        screenData?.heartAssessment
                          ?.auscultationValidation?.[0] ||
                        screenData?.heartAssessment?.estimationValidation?.[0]
                      }
                    />
                    <Stomach
                      top={"210px"}
                      right={"76px"}
                      disabled={
                        screenData?.status === "PATIENT_ADDED" ||
                        !!!location.state.screeningDate ||
                        screenData?.screeningPackageDetails?.abdomen.length ===
                          0 ||
                        ((user?.groups?.includes("school-principal") ||
                          user?.groups?.includes("school-teacher") ||
                          user?.groups?.includes("school-admin")) &&
                          !school.isReport)
                      }
                      onClick={() => {
                        setOrgan("Abdominal");
                      }}
                      active={organ === "Abdominal" ? true : false}
                      issue={screenData?.abdomenAssessment?.validation?.[0]}
                    />
                    <Skin
                      top={"250px"}
                      right={"145px"}
                      disabled={
                        screenData?.status === "PATIENT_ADDED" ||
                        !!!location.state.screeningDate ||
                        screenData?.screeningPackageDetails?.skin.length ===
                          0 ||
                        ((user?.groups?.includes("school-principal") ||
                          user?.groups?.includes("school-teacher") ||
                          user?.groups?.includes("school-admin")) &&
                          !school.isReport)
                      }
                      onClick={() => {
                        setOrgan("Skin");
                      }}
                      active={organ === "Skin" ? true : false}
                      issue={screenData?.skinAssessment?.validation?.[0]}
                    />
                  </>
                )}
                {location?.state?.gender === "Male" ? (
                  <BoySvg age={calculateAge(location?.state?.DOB)} />
                ) : (
                  <GirlSvg age={calculateAge(location?.state?.DOB)} />
                )}
              </div>
            </div>
            {screenData?.status === "SCREENING_DONE" && (
              <div className={classes.belowBody}>
                <div>
                  <img src={Height} alt="" />
                  <div>
                    {screenData?.vitalSignsAssessment?.height
                      ? screenData?.vitalSignsAssessment?.height
                      : screenData?.entAssessment?.vitals?.height
                      ? screenData?.entAssessment?.vitals?.height
                      : ""}
                    <span>cms</span>
                  </div>
                  <div>Height</div>
                </div>
                <div>
                  <BmiScale
                    BMI={calcBmi(
                      screenData?.vitalSignsAssessment?.height,
                      screenData?.vitalSignsAssessment?.weight
                    )}
                  />
                  <div>
                    {screenData?.vitalSignsAssessment?.height &&
                    screenData?.vitalSignsAssessment?.weight
                      ? calcBmi(
                          screenData?.vitalSignsAssessment?.height,
                          screenData?.vitalSignsAssessment?.weight
                        )
                      : screenData?.entAssessment?.vitals?.height &&
                        screenData?.entAssessment?.vitals?.weight
                      ? calcBmi(
                          screenData?.entAssessment?.vitals?.height,
                          screenData?.entAssessment?.vitals?.weight
                        )
                      : "N/A"}
                    <span>kg/m²</span>
                  </div>
                  <div>BMI</div>
                </div>
                <div>
                  <img src={WeightScale} alt="" />
                  <div>
                    {screenData?.vitalSignsAssessment?.weight
                      ? screenData?.vitalSignsAssessment?.weight
                      : screenData?.entAssessment?.vitals?.weight
                      ? screenData?.entAssessment?.vitals?.weight
                      : ""}
                    <span>kgs</span>
                  </div>
                  <div>Weight</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={classes.physicalRightCon}>
          {((screenData && Object.keys(screenData).length > 0) ||
            location.state.screeningDate === null) && (
            <>
              {
                // (screenData?.status === "PATIENT_ADDED" || screenData?.status === null || location.state.screeningDate === null)
                //   ?
                //   <div className={classes.noScreening}>
                //     <img src={location.state.screeningDate === null ? PreScreening : YetToScreen} alt="Date not scheduled" />
                //     <div>
                //       {
                //         location.state.screeningDate === null
                //           ? "Date not scheduled"
                //           : "Screening scheduled"
                //       }
                //     </div>
                //     <p>
                //       {
                //         location.state.screeningDate === null
                //           ? "Screening Date not scheduled yet. Please go to school page and schedule screening date."
                //           : `Students are yet to be screened as screening date is scheduled for ${toDateString(screenData?.student?.screeningDate)}.`
                //       }
                //     </p>
                //     <div onClick={() => { navigate(-2) }}>Go to School</div>
                //   </div>
                // :
                (user?.groups?.includes("school-principal") ||
                  user?.groups?.includes("school-teacher") ||
                  user?.groups?.includes("school-admin")) &&
                !school.isReport ? (
                  <BlankDataScreen
                    handleClick={() => {
                      navigate(-2);
                    }}
                    buttonText={"Go back"}
                    description={
                      "Please contact your SKIDS relationship manager to get access to reports."
                    }
                    title={"Report not accessible"}
                  />
                ) : (
                  <>
                    <div>
                      <div className={classes.exam_data_header}>
                        Vital Signs
                      </div>
                      <div className={classes.exam_data_info}>
                        <div>
                          <img src={Thermometer} alt="thermometer" />
                          <p
                            style={
                              issuePresent("temperature")
                                ? { color: "red" }
                                : {}
                            }
                          >
                            {screenData?.vitalSignsAssessment?.temperature !==
                            "null"
                              ? (
                                  screenData?.vitalSignsAssessment
                                    ?.temperature *
                                    (9 / 5) +
                                  32
                                ).toFixed(2)
                              : "N/A"}
                          </p>
                          <div>
                            <p>Temperature</p>
                            <div className={classes.infoHover}>
                              <InfoIcon
                                sx={{ fontSize: "17px", fill: "#66707E" }}
                              />
                              <div className={classes.popOnHover}>
                                <p>Temperature</p>
                                <p>Normal Range Of Temperature</p>
                                <p>5-12yrs</p>
                                <p>37°C (98.6°F)</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <img src={heartrate} alt="heartrate" />
                          <p
                            style={
                              issuePresent("heartRate") ? { color: "red" } : {}
                            }
                          >
                            {screenData?.vitalSignsAssessment?.heartRate !==
                            "null"
                              ? screenData?.vitalSignsAssessment?.heartRate +
                                "BPM"
                              : "N/A"}
                          </p>
                          <div>
                            <p>Heart Rate</p>
                            <div className={classes.infoHover}>
                              <InfoIcon
                                sx={{ fontSize: "17px", fill: "#66707E" }}
                              />
                              <div className={classes.popOnHover}>
                                <p>Heart Rate</p>
                                <p>Normal Range Of Heart Rate</p>
                                <p>5-12yrs</p>
                                <p>70-120 bpm</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <img src={blooddrop} alt="blooddrop" />
                          <p
                            style={
                              issuePresent("hemoglobin") ? { color: "red" } : {}
                            }
                            className={`${
                              screenData?.vitalSignsAssessment?.hemoglobin <
                              13.4
                                ? `${classes.exam_data_low_hemoglobin}`
                                : ``
                            }`}
                          >
                            {screenData?.vitalSignsAssessment?.hemoglobin !==
                            "null"
                              ? screenData?.vitalSignsAssessment?.hemoglobin +
                                "g/dl"
                              : "N/A"}
                          </p>
                          <div>
                            <p>Hemoglobin</p>
                            <div className={classes.infoHover}>
                              <InfoIcon
                                sx={{ fontSize: "17px", fill: "#66707E" }}
                              />
                              <div className={classes.popOnHover}>
                                <p>Hemoglobin</p>
                                <p>Normal Range Of Hemoglobin</p>
                                <p>6-12yrs</p>
                                <p>11.2 to 14.5 g/dL</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <img src={Bmi} alt="bmi" />
                          <p
                            style={issuePresent("bmi") ? { color: "red" } : {}}
                          >
                            {screenData?.vitalSignsAssessment?.height &&
                            screenData?.vitalSignsAssessment?.weight
                              ? calcBmi(
                                  screenData?.vitalSignsAssessment?.height,
                                  screenData?.vitalSignsAssessment?.weight
                                ) + "kg/m"
                              : screenData?.entAssessment?.vitals?.height &&
                                screenData?.entAssessment?.vitals?.weight
                              ? calcBmi(
                                  screenData?.entAssessment?.vitals?.height,
                                  screenData?.entAssessment?.vitals?.weight
                                ) + "kg/m"
                              : "N/A"}
                          </p>
                          <div>
                            <p>BMI</p>
                            <div className={classes.infoHover}>
                              <InfoIcon
                                sx={{ fontSize: "17px", fill: "#66707E" }}
                              />
                              <div className={classes.popOnHover}>
                                <p>BMI</p>
                                <p>Normal Range Of BMI</p>
                                <p>5-12yrs</p>

                                <p></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <img src={Oxygen} alt="oxygen" />
                          <p
                            style={issuePresent("spo2") ? { color: "red" } : {}}
                          >
                            {screenData?.vitalSignsAssessment?.SpO2 !== "null"
                              ? screenData?.vitalSignsAssessment?.SpO2 + "%"
                              : "N/A"}
                          </p>
                          <div>
                            <p>Spo2</p>
                            <div className={classes.infoHover}>
                              <InfoIcon
                                sx={{ fontSize: "17px", fill: "#66707E" }}
                              />
                              <div className={classes.popOnHover}>
                                <p>Spo2</p>
                                <p>Normal Range Of Spo2</p>
                                <p>5-12yrs</p>
                                <p>95-100</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <img src={Bpmachine} alt="bp" />
                          <p
                            style={
                              issuePresent("temperature")
                                ? { color: "red" }
                                : {}
                            }
                          >
                            {screenData?.vitalSignsAssessment?.bloodPressure !==
                            "null"
                              ? screenData?.vitalSignsAssessment
                                  ?.bloodPressure + "mmHg"
                              : "N/A"}
                          </p>
                          <div>
                            <p>Blood Presure</p>
                            <div className={classes.infoHover}>
                              <InfoIcon
                                sx={{ fontSize: "17px", fill: "#66707E" }}
                              />
                              <div className={classes.popOnHover}>
                                <p>Blood Presure</p>
                                <p>Normal Range Of Blood Presure</p>
                                <p>5-12yrs</p>
                                <p>96-131 & 55-62</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={classes.exam_data_header}>Exam Data</div>
                      <div className={classes.organs_container}>
                        <div
                          onClick={() => setOrgan("Hair")}
                          className={
                            organ === "Hair"
                              ? `${classes.organs_container_elements_active}`
                              : ``
                          }
                        >
                          Hair
                        </div>
                        <div
                          onClick={() => setOrgan("Eye")}
                          className={
                            organ === "Eye"
                              ? `${classes.organs_container_elements_active}`
                              : ``
                          }
                        >
                          Eye
                        </div>
                        <div
                          onClick={() => setOrgan("Ear")}
                          className={
                            organ === "Ear"
                              ? `${classes.organs_container_elements_active}`
                              : ``
                          }
                        >
                          Ear
                        </div>
                        <div
                          onClick={() => setOrgan("Hearing")}
                          className={
                            organ === "Hearing"
                              ? `${classes.organs_container_elements_active}`
                              : ``
                          }
                        >
                          Hearing
                        </div>
                        <div
                          onClick={() => setOrgan("Dental")}
                          className={
                            organ === "Dental"
                              ? `${classes.organs_container_elements_active}`
                              : ``
                          }
                        >
                          Dental
                        </div>
                        <div
                          onClick={() => setOrgan("Cough")}
                          className={
                            organ === "Cough"
                              ? `${classes.organs_container_elements_active}`
                              : ``
                          }
                        >
                          Cough
                        </div>
                        <div
                          onClick={() => setOrgan("Throat")}
                          className={
                            organ === "Throat"
                              ? `${classes.organs_container_elements_active}`
                              : ``
                          }
                        >
                          Throat
                        </div>
                        <div
                          onClick={() => setOrgan("Heart")}
                          className={
                            organ === "Heart"
                              ? `${classes.organs_container_elements_active}`
                              : ``
                          }
                        >
                          Heart
                        </div>
                        <div
                          onClick={() => setOrgan("Lung")}
                          className={
                            organ === "Lung"
                              ? `${classes.organs_container_elements_active}`
                              : ``
                          }
                        >
                          Lungs
                        </div>
                        <div
                          onClick={() => setOrgan("Abdominal")}
                          className={
                            organ === "Abdominal"
                              ? `${classes.organs_container_elements_active}`
                              : ``
                          }
                        >
                          Abdominal
                        </div>
                        <div
                          onClick={() => setOrgan("Skin")}
                          className={
                            organ === "Skin"
                              ? `${classes.organs_container_elements_active}`
                              : ``
                          }
                        >
                          Skin
                        </div>
                        {screenData?.screeningPackageDetails?.hair?.length >
                        0 ? (
                          <div
                            onClick={() => setOrgan("VHR")}
                            className={
                              organ === "VHR"
                                ? `${classes.organs_container_elements_active}`
                                : ``
                            }
                          >
                            VHR
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className={classes.heartrate_estimation}>
                        {componentEstimation}
                      </div>
                    </div>
                  </>
                )
              }
            </>
          )}
        </div>
      </div>
    );
  };

  const BehavioralScreeningView = () => {
    return (
      <div className={classes.behavioralMainCon}>
        <div>
          {screenData?.status === "PATIENT_ADDED" ||
          screenData?.status === null ||
          location.state.screeningDate === null ||
          ((user?.groups?.includes("school-principal") ||
            user?.groups?.includes("school-teacher") ||
            user?.groups?.includes("school-admin")) &&
            !school.isReport) ? (
            <Blankspider margin="15px 10px 0px 10px" />
          ) : (
            <Spiderchart
              packageDetails={screenData?.screeningPackageDetails}
              screen={"behavioral"}
              data={bhvScreenData}
            />
          )}
        </div>
        <div>
          {((screenData && Object.keys(screenData).length > 0) ||
            location.state.screeningDate === null) && (
            <>
              {screenData?.status === "PATIENT_ADDED" ||
              location.state.screeningDate === null ? (
                <div className={classes.noScreening}>
                  <img
                    src={
                      location.state.screeningDate === null
                        ? PreScreening
                        : YetToScreen
                    }
                    alt="Date not scheduled"
                  />
                  <div>
                    {location.state.screeningDate === null
                      ? "Date not scheduled"
                      : "Screening scheduled"}
                  </div>
                  <p>
                    {location.state.screeningDate === null
                      ? "Screening Date not scheduled yet. Please go to school page and schedule screening date."
                      : `Students are yet to be screened as screening date is scheduled for ${toDateString(
                          screenData?.student?.screeningDate
                        )}.`}
                  </p>
                  <div
                    onClick={() => {
                      navigate(-2);
                    }}
                  >
                    Go to School
                  </div>
                  <br />
                  <br />
                </div>
              ) : (user?.groups?.includes("school-principal") ||
                  user?.groups?.includes("school-teacher") ||
                  user?.groups?.includes("school-admin")) &&
                !school.isReport ? (
                <BlankDataScreen
                  handleClick={() => {
                    navigate(-2);
                  }}
                  buttonText={"Go back"}
                  description={
                    "Please contact your SKIDS relationship manager to get access to reports."
                  }
                  title={"Report not accessible"}
                />
              ) : (
                <>
                  <p>Questionnaire Data</p>
                  <div className={classes.organs_container}>
                    <div
                      onClick={() => setBehaviour("adhd")}
                      className={
                        behaviour === "adhd"
                          ? `${classes.organs_container_elements_active}`
                          : ``
                      }
                    >
                      ADHD
                    </div>
                    <div
                      onClick={() => setBehaviour("autism")}
                      className={
                        behaviour === "autism"
                          ? `${classes.organs_container_elements_active}`
                          : ``
                      }
                    >
                      Autism
                    </div>
                    <div
                      onClick={() => setBehaviour("digitalDependency")}
                      className={
                        behaviour === "digitalDependency"
                          ? `${classes.organs_container_elements_active}`
                          : ``
                      }
                    >
                      Digital Dependency
                    </div>
                    <div
                      onClick={() => setBehaviour("anxiety")}
                      className={
                        behaviour === "anxiety"
                          ? `${classes.organs_container_elements_active}`
                          : ``
                      }
                    >
                      Anxiety
                    </div>
                    <div
                      onClick={() => setBehaviour("conductDisorder")}
                      className={
                        behaviour === "conductDisorder"
                          ? `${classes.organs_container_elements_active}`
                          : ``
                      }
                    >
                      Conduct Disorder
                    </div>
                    <div
                      onClick={() => setBehaviour("odd")}
                      className={
                        behaviour === "odd"
                          ? `${classes.organs_container_elements_active}`
                          : ``
                      }
                    >
                      ODD
                    </div>
                    <div
                      onClick={() => setBehaviour("dipression")}
                      className={
                        behaviour === "dipression"
                          ? `${classes.organs_container_elements_active}`
                          : ``
                      }
                    >
                      Depression
                    </div>
                    <div
                      onClick={() => setBehaviour("developmentMilestone")}
                      className={
                        behaviour === "developmentMilestone"
                          ? `${classes.organs_container_elements_active}`
                          : ``
                      }
                    >
                      Development Delay
                    </div>
                  </div>
                  <div>{behaviourComponent}</div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const LearningDisabilities = () => {
    return (
      <div className={classes.behavioralMainCon}>
        <div>
          {screenData?.status === "PATIENT_ADDED" ||
          screenData?.status === null ||
          location.state.screeningDate === null ||
          ((user?.groups?.includes("school-principal") ||
            user?.groups?.includes("school-teacher") ||
            user?.groups?.includes("school-admin")) &&
            !school.isReport) ? (
            <Blankspider margin="15px 10px 0px 10px" />
          ) : (
            <Spiderchart
              packageDetails={screenData?.screeningPackageDetails}
              screen={"learning"}
              data={bhvScreenData}
            />
          )}
        </div>
        <div>
          {((screenData && Object.keys(screenData).length > 0) ||
            location.state.screeningDate === null) && (
            <>
              {screenData?.status === "PATIENT_ADDED" ||
              location.state.screeningDate === null ? (
                <div className={classes.noScreening}>
                  <img
                    src={
                      location.state.screeningDate === null
                        ? PreScreening
                        : YetToScreen
                    }
                    alt="Date not scheduled"
                  />
                  <div>
                    {location.state.screeningDate === null
                      ? "Date not scheduled"
                      : "Screening scheduled"}
                  </div>
                  <p>
                    {location.state.screeningDate === null
                      ? "Screening Date not scheduled yet. Please go to school page and schedule screening date."
                      : `Students are yet to be screened as screening date is scheduled for ${toDateString(
                          screenData?.student?.screeningDate
                        )}.`}
                  </p>
                  <div
                    onClick={() => {
                      navigate(-2);
                    }}
                  >
                    Go to School
                  </div>
                  <br />
                  <br />
                </div>
              ) : (user?.groups?.includes("school-principal") ||
                  user?.groups?.includes("school-teacher") ||
                  user?.groups?.includes("school-admin")) &&
                !school.isReport ? (
                <BlankDataScreen
                  handleClick={() => {
                    navigate(-2);
                  }}
                  buttonText={"Go back"}
                  description={
                    "Please contact your SKIDS relationship manager to get access to reports."
                  }
                  title={"Report not accessible"}
                />
              ) : (
                <>
                  <p>Questionnaire Data</p>
                  <div className={classes.organs_container}>
                    <div
                      onClick={() => setLearningDis("dyslexia")}
                      className={
                        learningDis === "dyslexia"
                          ? `${classes.organs_container_elements_active}`
                          : ``
                      }
                    >
                      Dyslexia
                    </div>
                    <div
                      onClick={() => setLearningDis("dyscalculia")}
                      className={
                        learningDis === "dyscalculia"
                          ? `${classes.organs_container_elements_active}`
                          : ``
                      }
                    >
                      Dyscalculia
                    </div>
                    <div
                      onClick={() => setLearningDis("dysgraphia")}
                      className={
                        learningDis === "dysgraphia"
                          ? `${classes.organs_container_elements_active}`
                          : ``
                      }
                    >
                      Dysgraphia
                    </div>
                  </div>
                  <div>{learningDisComponent}</div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleUpdateStatus = () => {
    const params = {
      screeningId: screenData?.screeningId,
      eventId: screenData?.eventId,
      status: "SCREENING_DONE",
    };

    const param1 = {
      screeningId: screenData?.screeningId,
      mapId: screenData?.mappingId,
      eventId: screenData?.eventId,
    };

    markScreeningDoneOnMap(param1, (type, res) => {
      if (type === "success") {
        console.log(res);
      } else if (type === "failure") {
        console.log(res);
      }
    });

    updateSuperStatus(params)
      .then((res) => {
        console.log(res);
        dispatch(
          updateStudent({
            studentId: location?.state?.studentId,
            schoolId: location?.state?.schoolId,
            status: "SCREENING_DONE",
          })
        );
        dispatch(studentScreening(location.state?.studentId));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.main_div}>
      {/* <BPModal open={open} handleClose={handleClose}/> */}
      <div>
        <BreadCrumbs />
        <div style={{ display: "flex", gap: 15 }}>
          {userRole.includes("skids-sa") && (
            <button
              style={{
                cursor:
                  screenData?.status !== "PATIENT_ADDED"
                    ? "not-allowed"
                    : "pointer",
                opacity: screenData?.status !== "PATIENT_ADDED" ? 0.5 : 1,
                backgroundColor: "#1740A1",
                color: "white",
                display: "flex",
                alignItems: "center",
                padding: "8px 10px",
                borderRadius: 8,
                border: "none",
                fontSize: 15,
              }}
              disabled={screenData?.status !== "PATIENT_ADDED"}
              onClick={handleUpdateStatus}
            >
              Complete Screening
            </button>
          )}
          {
            <button
              onClick={downloadReport}
              disabled={combinedReportStatus()}
              style={{
                width: 200,
                backgroundColor: "#1740A1",
                color: "white",
                display: "flex",
                alignItems: "center",
                padding: "8px 10px",
                borderRadius: 8,
                border: "none",
                opacity: combinedReportStatus() ? 0.5 : 1,
              }}
            >
              <span style={{ marginTop: 4 }}>
                <DownloadForOfflineRoundedIcon
                  sx={{ fill: "white", marginRight: 1 }}
                />
              </span>{" "}
              Download Report
            </button>
          }
          {/* {combinedReportStatus() && <button onClick={downloadReport} style={{ width: 200, backgroundColor: "#1740A1", color: "white", display: "flex", alignItems: "center", padding: "8px 10px", borderRadius: 8, border: "none", pointerEvents: "none", opacity: 0.5 }}>
            <span style={{ marginTop: 4 }}><DownloadForOfflineRoundedIcon sx={{ fill: "white", marginRight: 1 }} /></span> Download Report
          </button>} */}
        </div>
      </div>
      <div className={classes.studentDetails}>
        <div className={classes.avtarSection}>
          <Avatar
            {...stringAvatar(firstName)}
            sx={{ width: 64, height: 64, fontSize: 48, fontWeight: "bold" }}
          />
          <div className={classes.student_details_information_name}>
            {firstName + " " + lastName}
          </div>
        </div>
        <div>
          <div>
            <p>Age</p>
            <p>{DOB && calculateAge(DOB)} Yrs</p>
          </div>
          <div>
            <p>Gender</p>
            <p>{gender}</p>
          </div>
          <div>
            <p>Class</p>
            <p>
              {numberTh(location.state.class)} {section}
            </p>
          </div>
          <div>
            <p>Screening Date {"&"} Time</p>
            <p>
              {screeningDate
                ? toDateString(screenData?.student?.screeningDate)
                : "Not scheduled"}
            </p>
          </div>
          {/* <div>
            <p>Medical Operator</p>
            <p>4 Operator</p>
          </div> */}
          <div>
            <p>Validated by</p>
            {screenData?.physicalDoctor &&
            screenData?.dentalDoctor &&
            screenData?.behavioralDoctor ? (
              <div>
                <p className={classes.validatedByText}>
                  {screenData?.validatedBy}
                </p>
                <div className={classes.infoHoverValidated}>
                  <InfoIcon sx={{ fontSize: "17px", fill: "#66707E" }} />
                  <div className={classes.popOnHoverValidated}>
                    <p>REPORTS VALIDATED BY</p>
                    <p>
                      This following report is validated by our doctors for
                      different assessments.
                    </p>
                    <div>
                      <div>
                        <div>
                          <p>Physical Screening</p>
                          <p>{"Dr. " + screenData?.physicalDoctor}</p>
                        </div>
                        <div>
                          <p>Validated on</p>
                          <p>
                            {screenData?.physicalValidatedOn
                              ? moment(screenData?.physicalValidatedOn).format(
                                  "DD-MM-YYYY"
                                )
                              : "Yet to be validated"}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p>Dental Screening</p>
                          <p>Dr. {screenData?.dentalDoctor}</p>
                        </div>
                        <div>
                          <p>Validated on</p>
                          <p>
                            {screenData?.dentalValidatedOn
                              ? moment(screenData?.dentalValidatedOn).format(
                                  "DD-MM-YYYY"
                                )
                              : "Yet to be validated"}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p>Behavioral Screening</p>
                          <p>Dr. {screenData?.behavioralDoctor}</p>
                        </div>
                        <div>
                          <p>Validated on</p>
                          <p>
                            {screenData?.behavioralValidatedOn
                              ? moment(
                                  screenData?.behavioralValidatedOn
                                ).format("DD-MM-YYYY")
                              : "Yet to be validated"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>-</div>
            )}
          </div>
        </div>
      </div>

      {/* <div className={classes.screeningType}> */}
      <div className={classes.screening_container}>
        <div
          className={
            activeScreeningView === "physical"
              ? classes.screening_container_active
              : classes.screening_container_inactive
          }
          onClick={() => {
            setActiveScreeningView("physical");
          }}
        >
          <p
            className={
              activeScreeningView === "physical" ? `` : classes.typeText
            }
          >
            Physical Screening
          </p>
        </div>
        <div
          className={
            activeScreeningView === "behavioral"
              ? classes.screening_container_active
              : classes.screening_container_inactive
          }
          onClick={() => {
            setActiveScreeningView("behavioral");
          }}
        >
          <p
            className={
              activeScreeningView === "behavioral" ? `` : classes.typeText
            }
          >
            Behavioral Screening
          </p>
        </div>
        <div
          className={
            activeScreeningView === "learningDis"
              ? classes.screening_container_active
              : classes.screening_container_inactive
          }
          onClick={() => {
            setActiveScreeningView("learningDis");
          }}
        >
          <p
            className={
              activeScreeningView === "learningDis" ? `` : classes.typeText
            }
          >
            Learning Disabilities
          </p>
        </div>
      </div>
      {/* </div> */}
      <div className={classes.container}>
        <div>
          {activeScreeningView === "physical"
            ? PhysicalScreeningView()
            : activeScreeningView === "behavioral"
            ? BehavioralScreeningView()
            : LearningDisabilities()}
        </div>
      </div>
    </div>
  );
}

function Studentscreening() {
  return <StudentNamePage />;
}

export default Studentscreening;

// {organ !== 'Lung' ? (
//   <div>
//     <div className={classes.stethoscope_check_container}>
//       <div className={classes.stethoscope_check}>
//         {/* <img src={checkcircle} alt="checkedCircle" /> */}
//         <div></div>
//         <img src={hair} alt="stethoscopegreen" />
//       </div>
//     </div>
//     {
//       (!screenData?.eyeAssessment?.validation?.[0] === "")
//         ?

//         <>
//           <div className={organ === 'Eye' ? `${classes.visibility_container} ${classes.visibility_container_active_red}` :
//             `${classes.visibility_container}`} >
//             <div className={organ === 'Eye' ? `${classes.visibility_container_main_red} ${classes.visibility_container_main_active_red}`
//               : `${classes.visibility_container_main_red}`}>
//               <div onClick={() => setOrgan('Eye')}
//                 className={organ === 'Eye' ? `${classes.visibility_container_inner_red} ${classes.visibility_container_inner_active_red}` : `${classes.visibility_container_inner_red}`}>
//                 <img src={visilbilty} alt="visibility" />
//               </div>
//             </div>
//           </div>
//           <div className={organ === 'Eye' ? `${classes.visibility_right_container_red} ${classes.visibility_right_container_active_red}` :
//             `${classes.visibility_right_container_red}`} >
//             <div className={organ === 'Eye' ? `${classes.visibility_right_container_main_red} ${classes.visibility_right_container_main_active_red}`
//               : `${classes.visibility_right_container_main_red}`}>
//               <div onClick={() => setOrgan('Eye')}
//                 className={organ === 'Eye' ? `${classes.visibility_right_container_inner_red} ${classes.visibility_right_container_inner_active_red}` : `${classes.visibility_right_container_inner_red}`}>
//                 <img src={visilbilty} alt="visibility" />
//               </div>
//             </div>
//           </div>
//         </>
//         :
//         <>
//           <div className={organ === 'Eye' ? `${classes.visibility_container} ${classes.visibility_container_active}` :
//             `${classes.visibility_container}`} >
//             <div className={organ === 'Eye' ? `${classes.visibility_container_main} ${classes.visibility_container_main_active}`
//               : `${classes.visibility_container_main}`}>
//               <div onClick={() => setOrgan('Eye')}
//                 className={organ === 'Eye' ? `${classes.visibility_container_inner} ${classes.visibility_container_inner_active}` : `${classes.visibility_container_inner}`}>
//                 <img src={visilbilty} alt="visibility" />
//               </div>
//             </div>
//           </div>
//           <div className={organ === 'Eye' ? `${classes.visibility_right_container} ${classes.visibility_right_container_active}` :
//             `${classes.visibility_right_container}`} >
//             <div className={organ === 'Eye' ? `${classes.visibility_right_container_main} ${classes.visibility_right_container_main_active}`
//               : `${classes.visibility_right_container_main}`}>
//               <div onClick={() => setOrgan('Eye')}
//                 className={organ === 'Eye' ? `${classes.visibility_right_container_inner} ${classes.visibility_right_container_inner_active}` : `${classes.visibility_container_inner}`}>
//                 <img src={visilbilty} alt="visibility" />
//               </div>
//             </div>
//           </div>
//         </>
//     }

//     <div className={organ === 'Cough' ? `${classes.wind_container} ${classes.wind_container_active}` : `${classes.wind_container}`}>
//       <div className={organ === 'Cough' ? `${classes.wind_container_main} ${classes.wind_container_main_active}` : `${classes.wind_container_main}`}>
//         <div onClick={() => setOrgan('Cough')} className={organ === 'Cough' ? `${classes.wind_container_inner} ${classes.wind_container_inner_active}` :
//           `${classes.wind_container_inner}`}>
//           <img src={wind} alt="wind" />
//         </div>
//       </div>
//     </div>

//     <div className={organ === 'Throat' ? `${classes.ent_container} ${classes.ent_container_active}` : `${classes.ent_container}`}>
//       <div className={organ === 'Throat' ? `${classes.ent_container_main} ${classes.ent_container_main_active} ` : `${classes.ent_container_main}`}>
//         <div onClick={() => setOrgan('Throat')} className={organ === 'Throat' ? `${classes.ent_container_inner} ${classes.ent_container_inner_active}` : `${classes.ent_container_inner}`}>
//           <img src={ent} alt="ent" />
//         </div>
//       </div>
//     </div>

//     <div className={organ === 'Ear' ? `${classes.ear_container} ${classes.ear_container_active}` :
//       `${classes.ear_container}`}>
//       <div className={organ === 'Ear' ? `${classes.ear_container_main} ${classes.ear_container_main_active}` :
//         `${classes.ear_container_main}`}>
//         <div onClick={() => setOrgan('Ear')} className={organ === 'Ear' ? `${classes.ear_container_inner} ${classes.ear_container_inner_active}` :
//           `${classes.ear_container_inner}`}>
//           <img src={ear} alt="ear" />
//         </div>
//       </div>
//     </div>

//     <div className={organ === 'Ear' ? `${classes.ear_left_container} ${classes.ear_left_container_active}` :
//       `${classes.ear_left_container}`}>
//       <div className={organ === 'Ear' ? `${classes.ear_left_container_main} ${classes.ear_left_container_main_active}` :
//         `${classes.ear_left_container_main}`}>
//         <div onClick={() => setOrgan('Ear')} className={organ === 'Ear' ? `${classes.ear_left_container_inner} ${classes.ear_left_container_inner_active}` :
//           `${classes.ear_container_inner}`}>
//           <img src={ear} alt="ear" />
//         </div>
//       </div>
//     </div>

//     <div className={organ === 'Heart' ? `${classes.heartmonitor_container} ${classes.heartmonitor_container_active}` : `${classes.heartmonitor_container}`}>
//       <div className={organ === 'Heart' ? `${classes.heartmonitor_container_main} ${classes.heartmonitor_container_main_active}` : `${classes.heartmonitor_container_main}`}>
//         <div onClick={() => setOrgan('Heart')} className={organ === 'Heart' ? `${classes.heartmonitor_container_inner} ${classes.heartmonitor_container_inner_active}` : `${classes.heartmonitor_container_inner}`}>
//           <img src={checkcircle} alt="checkedCircle" />
//           <img src={heartmonitor} alt="heartmonitor" />
//         </div>
//       </div>
//     </div>

//     <div className={classes.pulmonology_container_main}>
//       <div onClick={() => setOrgan('Lung')}
//         className={classes.pulmonology_container_inner}>
//         <img src={pulmonology} alt="pulmonology" />
//       </div>
//     </div>

//     <div className={organ === 'Abdominal' ? `${classes.gastroenterology_container} ${classes.gastroenterology_container_active} ` : `${classes.gastroenterology_container}`}>
//       <div className={organ === 'Abdominal' ? `${classes.gastroenterology_container_main} ${classes.gastroenterology_container_main_active}` : `${classes.gastroenterology_container_main}`}>
//         <div onClick={() => setOrgan('Abdominal')} className={organ === 'Abdominal' ? `${classes.gastroenterology_container_inner} ${classes.gastroenterology_container_inner_active}` : `${classes.gastroenterology_container_inner}`}>
//           <img src={gastroenterology} alt="gastroenterology" />
//         </div>
//       </div>
//     </div>

//     <div className={organ === 'Skin' ? `${classes.fingerprint_container} ${classes.fingerprint_container_active}`
//       : `${classes.fingerprint_container}`}>
//       <div className={organ === 'Skin' ? `${classes.fingerprint_container_main} ${classes.fingerprint_container_main_active}` : `${classes.fingerprint_container_main}`}>
//         <div onClick={() => setOrgan('Skin')} className={organ === 'Skin' ? `${classes.fingerprint_container_inner} ${classes.fingerprint_container_inner_active}` : `${classes.fingerprint_container_inner}`}>
//           <img src={fingerprint} alt="fingerprint" />
//         </div>
//       </div>
//     </div>
//   </div>
// ) : (
//   <div>
//     <div className={classes.lungs_container_main} >
//       <div className={classes.lungs_container}>
//         <img src={checkcircle} alt="lungs-check1" />
//         <img src={checkcircle} alt="lungs-check2" />
//         <img src={checkcircle} alt="lungs-check3" />
//         <img src={checkcircle} alt="lungs-check4" />
//         <img src={checkcircle} alt="lungs-check5" />
//         <img src={checkcircle} alt="lungs-check6" />
//       </div>
//     </div>
//   </div>
// )}
