import React from 'react'
import classes from './BlogDetail.module.css';
import BreadCrumbs from '../common/TableComponents/BreadCrumbs'
import { useLocation } from 'react-router-dom';

const BlogDetail = () => {
    const location = useLocation();
    return (
        <div className={classes.blog_info}>
            <div className={classes.blog_header}>
                <div className={classes.header}>
                    <BreadCrumbs />
                </div>
            </div>
            <div className={classes.blog_container}>
                <div className={classes.blog_header}>
                    <div>
                        <div>
                            <h4>Title: <span>
                                {location?.state?.title}
                            </span></h4>
                        </div>
                        <div className={classes.blog_metadata}>
                            <div>
                                <h6>
                                    Author Name:{" "}
                                    <span>
                                        {location?.state?.author}
                                    </span>
                                </h6>
                            </div>
                            <div>
                                <h6>
                                    Category: <span>{location?.state?.category}</span>
                                </h6>
                            </div>
                            <div>
                                <h6>
                                    Status: <span>{location?.state?.status}</span>
                                </h6>
                            </div>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: location?.state?.description }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogDetail